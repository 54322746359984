import React, { useState, useEffect, Fragment } from 'react';
import PropTypes from 'prop-types';
import { Container, Row, Col, Image, Spinner } from 'react-bootstrap';
import { Swiper, SwiperSlide } from 'swiper/react';
import SingleCourseModal from '../modals/SingleCourseModal';
import {
  loadCourses,
  getRecentlyAddedCourses,
} from '../../actions/courseActions';
import { withRouter } from 'react-router';
import { Link } from 'react-router-dom';

import 'swiper/swiper-bundle.css';

import SwiperCore, { Parallax, Pagination, Navigation, Autoplay } from 'swiper';
import { connect } from 'react-redux';

import loopLogo from '../../assets/images/loop_logo.png';
import StreamingModal from '../modals/StreamingModal';

SwiperCore.use([Parallax, Pagination, Navigation, Autoplay]);

function Home({
  loadCourses,
  course: { courses, recentlyAddedCourses },
  modal: { streamingModalState },
  getRecentlyAddedCourses,
}) {
  const [showCourseModal, setShowCourseModal] = useState(false);
  const LineSeperator = () => (
    <hr
      style={{
        height: 1,
        borderTop: '2px dashed #1d3246',
      }}
    />
  );

  const [slideChange, setSlideChange] = useState(null);

  useEffect(() => {
    loadCourses();
    getRecentlyAddedCourses();
  }, [loadCourses, getRecentlyAddedCourses]);
  return (
    <Fragment>
      <div className='customSwiper'>
        <div
          className=' text-white shadow d-flex'
          style={{ height: '70vh', minHeight: '600px' }}>
          <div slot='container-start' className='parallax-bg bg-Img'>
            {recentlyAddedCourses?.courses.length !== 0 &&
            recentlyAddedCourses !== null ? (
              <Swiper
                style={{
                  '--swiper-navigation-color': '#fff',
                  '--swiper-pagination-color': '#fff',
                  height: '70vh',
                  minHeight: '600px',
                }}
                speed={600}
                // parallax={true}
                pagination={{
                  clickable: true,
                }}
                // autoplay={{
                //   delay: 2500,
                //   disableOnInteraction: false,
                // }}
                navigation
                loop={true}
                className='mySwiper text-white shadow'>
                {/* <div
              slot='container-start'
              className='parallax-bg bg-Img'
              data-swiper-parallax='-23%'></div> */}
                {recentlyAddedCourses?.courses.map((i, index) => (
                  <SwiperSlide
                    className='justify-content-center d-flex flex-column'
                    key={index}>
                    <Container>
                      <Row>
                        <Col xl={6} md={5} sm={12} className=''>
                          <Link to={`course-detail/${i._id}`}>
                            <Image
                              src={i.image.replace(
                                'getloopstream',
                                'getloopstream'
                              )}
                              rounded
                              className='thumbnailShadow'
                              data-swiper-parallax='-200'
                              width='50%'
                            />
                          </Link>
                        </Col>
                        <Col
                          xl={6}
                          md={7}
                          sm={12}
                          className='justify-content-center d-flex flex-column'>
                          <div className='title' data-swiper-parallax='-300'>
                            <p className='textShadow'>{i.name}</p>
                          </div>
                          <div className='text' data-swiper-parallax='-200'>
                            <p className='textShadow'>{i.description}</p>
                          </div>
                        </Col>
                      </Row>
                    </Container>
                  </SwiperSlide>
                ))}
              </Swiper>
            ) : (
              // <img src={loopLogo} alt='Loop Logo' />

              // <div
              //   className=' text-white shadow d-flex'
              //   style={{ height: '70vh', minHeight: '600px' }}>
              // <div slot='container-start' className='simple-bg bg-Img'>
              <div className='d-flex align-items-center justify-content-center h-100'>
                <img src={loopLogo} alt='Loop Logo' />
              </div>
              //   </div>
              // </div>
              // <section
              //   className='courseDetailBanner pt-5 pb-5 mt-0 align-items-center d-flex bg-dark'
              //   style={{
              //     backgroundColor: '#212529',
              //   }}>
              //   <div className='container'>
              //     <div className='row align-items-center d-flex justify-content-between'>
              //       <div className='col-12 col-md-6 pb-5  '>
              //         <div
              //           className='animate__animated animate__fadeIn animate__infinite'
              //           style={{
              //             backgroundColor: '#212529',
              //             height: '2rem',
              //             borderRadius: '5px',
              //           }}></div>
              //       </div>
              //       <div className='col-12 col-md-6 pb-5'>
              //         <div
              //           className='animate__animated animate__fadeIn animate__infinite'
              //           style={{
              //             backgroundColor: '#212529',
              //             height: '2rem',
              //             borderRadius: '5px',
              //           }}></div>
              //       </div>
              //     </div>
              //   </div>
              // </section>
            )}
          </div>
        </div>
      </div>

      <section className='stream'>
        <Container fluid>
          <Row>
            <div
              className='col-xl-12'
              style={{
                backgroundColor: '#1d3246',
                color: 'white',
                marginBottom: '50px',
              }}>
              <h1
                className='text-center m-0'
                style={{ fontSize: '24px', padding: '6px 0px' }}>
                Sports - Entertainment - Education
              </h1>
            </div>
          </Row>
        </Container>
        {courses ? (
          courses?.map((i, index) => (
            <Fragment key={index}>
              {/* <Container fluid className='px-5'>
                <h3 className='mb-4 font-weight-bold text-uppercase'>
                  <u>{i?.Category}</u>
                </h3>
              </Container> */}
              {/* <Fragment key={index}> */}
              {/* <Container fluid className='px-5'>
                      <h5 className='mb-4'>{e.title ? e.title : '-'}</h5>
                    </Container> */}
              <Swiper
                spaceBetween={10}
                // slidesPerView={1}
                // onSliderFirstMove={() => {
                //   setSlideChange(index);
                // }}
                // onSlideChange={() => {
                //   console.log(index);
                //   setSlideChange(index);
                // }}
                slidesPerGroup={1}
                navigation
                centeredSlides={false}
                loop={slideChange === index ? true : false}
                breakpoints={{
                  576: {
                    slidesPerView: 2,
                  },
                  768: {
                    slidesPerView: 3,
                  },
                  992: {
                    slidesPerView: 4,
                  },
                  1200: {
                    slidesPerView: 5,
                  },
                  1500: {
                    slidesPerView: 6,
                  },
                }}>
                {i.data.map((e, ind) =>
                  e.data.length !== 0
                    ? e.data.map((x, inde) => (
                        <Fragment>
                          <SwiperSlide key={x._id}>
                            <Container>
                              <Link to={`course-detail/${x._id}`}>
                                <img
                                  src={x.image.replace(
                                    'getloopstream',
                                    'getloopstream'
                                  )}
                                  alt={x.name}
                                  className='img-fluid '
                                  style={{ borderRadius: '0.5rem' }}
                                />
                              </Link>
                            </Container>
                          </SwiperSlide>
                          {/* <SwiperSlide key={inde + 1}>
                            <Container>
                              <Link to={`course-detail/${x._id}`}>
                                <img
                                  src={x.image}
                                  alt={x.name}
                                  className='img-fluid '
                                />
                              </Link>
                            </Container>
                          </SwiperSlide> */}
                        </Fragment>
                      ))
                    : null
                )}
              </Swiper>
              {/* </Fragment> */}
              {index !== courses.length - 1 ? <LineSeperator /> : null}
            </Fragment>
          ))
        ) : (
          <div className='d-flex justify-content-center my-5'>
            <Spinner
              as='span'
              animation='border'
              size='lg'
              role='status'
              aria-hidden='true'
            />
          </div>
        )}
      </section>

      <SingleCourseModal
        showCourseModal={showCourseModal}
        setShowCourseModal={setShowCourseModal}
      />

      {/* <StreamingModal /> */}
    </Fragment>
  );
}

Home.propTypes = {
  loadCourses: PropTypes.func.isRequired,
  getRecentlyAddedCourses: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  course: state.course,
  modal: state.modal,
});

export default connect(mapStateToProps, {
  loadCourses,
  getRecentlyAddedCourses,
})(withRouter(Home));
