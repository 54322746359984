import React from 'react';
import PropTypes from 'prop-types';

function UserNameDetail({ changeStep, data, setData }) {
  return (
    <div className='row animated zoomIn'>
      <div className='col-md-12'>
        <div className='text-white' style={{ marginTop: '66px' }}>
          <h4 className='main-steps'>STEP 1 OF 4</h4>
        </div>
      </div>
      <div className='col-md-12'>
        <div className='text-white' style={{ marginTop: '12px' }}>
          <h4 className='main-heading'>Enter your name</h4>
        </div>
      </div>
      <form
        onSubmit={(e) => {
          data.first_name === '' && data.last_name === ''
            ? e.preventDefault()
            : localStorage.setItem('data', JSON.stringify(data));
          localStorage.setItem('steps', '2');
          changeStep(e, '2');
        }}
        style={{ width: '100%' }}>
        <div className='col-md-12'>
          <div className='text-white' style={{ marginTop: '28px' }}>
            <div className='position-relative w-100' style={{ height: '39px' }}>
              <input
                type='text'
                className='email-input'
                style={{
                  width: '100%',
                  height: '39px',
                  // boxShadow: '0 2px 4px 0 #353535',
                  border: 'solid 1px #353535',
                  backgroundColor: '#ffffff',
                  padding: '9px 15px 8px 15px',
                  borderRadius: '3px',
                  // color: 'white',
                  fontSize: '15px',
                  fontWeight: '300',
                  fontFamily: 'AvenirLight',
                }}
                value={data.first_name}
                placeholder='First Name'
                name='first_name'
                onChange={(e) =>
                  setData({ ...data, [e.target.name]: e.target.value })
                }
                required='required'
              />
            </div>
            {/* <InputField
              type='email'
              placeholder='Email Address'
              withIcon={false}
            /> */}
          </div>
        </div>
        <div className='col-md-12'>
          <div className='text-white' style={{ marginTop: '28px' }}>
            <div className='position-relative w-100' style={{ height: '39px' }}>
              <input
                type='text'
                className='email-input'
                style={{
                  width: '100%',
                  height: '39px',
                  // boxShadow: '0 2px 4px 0 #353535',
                  border: 'solid 1px #353535',
                  backgroundColor: '#ffffff',
                  padding: '9px 15px 8px 15px',
                  borderRadius: '3px',
                  // color: 'white',
                  fontSize: '15px',
                  fontWeight: '300',
                  fontFamily: 'AvenirLight',
                }}
                value={data.last_name}
                placeholder='Last Name'
                name='last_name'
                onChange={(e) =>
                  setData({ ...data, [e.target.name]: e.target.value })
                }
                required='required'
              />
            </div>
            {/* <InputField
              type='email'
              placeholder='Email Address'
              withIcon={false}
            /> */}
          </div>
        </div>
        <div className='col-md-12'>
          <button
            // onClick={(e) => changeStep(e, 2)}
            style={
              data.first_name === '' && data.last_name === ''
                ? {
                    pointerEvents: 'none',
                    backgroundColor: '#1d3246',
                    border: 'none',
                  }
                : { border: 'none' }
            }
            type='submit'
            className='next-button'>
            NEXT
          </button>
          {/* <div
          className='next-button'
          onClick={(e) => changeStep(e, 2)}
          style={
            data.first_name === '' || data.last_name === ''
              ? { pointerEvents: 'none', backgroundColor: '#f1b300b0' }
              : null
          }
        >
          <h4>NEXT</h4>
        </div> */}
        </div>
      </form>
    </div>
  );
}

UserNameDetail.propTypes = {
  changeStep: PropTypes.func.isRequired,
  data: PropTypes.object.isRequired,
  setData: PropTypes.func.isRequired,
};

export default UserNameDetail;
