import React from 'react';
import PropTypes from 'prop-types';
import { Modal } from 'react-bootstrap';

function SuccessMessageModal({
  showSuccessMessageModal,
  setSuccessMessageModal,
  data,
}) {
  return (
    <Modal
      show={showSuccessMessageModal}
      onHide={() => setSuccessMessageModal(false)}
      size='xl'
      aria-labelledby='contained-modal-title-vcenter'
      centered
      // contentclassName="resend-modal-content"
      backdrop='static'
    >
      <Modal.Header closeButton className='border-bottom-0'></Modal.Header>
      <Modal.Body>
        <div className='myaccount-modal-body'>
          <div className='acc-name-update'>
            <h5 className='mb-4 modal-heading' style={{ lineHeight: '35px' }}>
              <b>{data.successTitle}</b>
              <p className='modal-label-heavy-text'>{data.email}</p>
            </h5>
            <p className='modal-label-text'>{data.successMessage}</p>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
}

SuccessMessageModal.propTypes = {};

export default SuccessMessageModal;
