import { STREAMING_MODAL } from './types';

// Streaming Modal Display

export const streamModal = (currentState, streamURL) => async (dispatch) => {
  // console.log(currentState);
  try {
    if (currentState) {
      dispatch({
        type: STREAMING_MODAL,
        payload: { state: true, url: streamURL },
      });
    } else {
      dispatch({
        type: STREAMING_MODAL,
        payload: { state: false, url: '' },
      });
    }
  } catch (error) {
    console.log('Modal error: ', error);
  }
};
