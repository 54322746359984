import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Modal } from 'react-bootstrap';
import { connect } from 'react-redux';
import { sendEmailVerificationCode } from '../../actions/auth';
import Icofont from 'react-icofont';

function EmailChangeModal({
  showEmailChangeModal,
  setEmailChangeModal,
  setEmailCheckModal,
  data,
  setData,
  auth: { userDetail },
  sendEmailVerificationCode,
}) {
  useEffect(() => {
    if (showEmailChangeModal) {
      setData({
        ...data,
        email: userDetail === null ? '' : userDetail.email,
      });
    } else {
      setData({
        ...data,
        email: data.email,
      });
    }
  }, [showEmailChangeModal]);

  const [loader, setLoader] = useState(false);

  const emailCheck = async (e) => {
    e.preventDefault();

    setLoader(true);

    const send = await sendEmailVerificationCode(
      data.email,
      userDetail?.first_name,
      userDetail?.last_name
    );

    if (send === true) {
      setLoader(false);
      setEmailChangeModal(false);
      setEmailCheckModal(true);
    } else {
      setLoader(false);
    }
  };

  return (
    <Modal
      show={showEmailChangeModal}
      onHide={() => setEmailChangeModal(false)}
      size='xl'
      aria-labelledby='contained-modal-title-vcenter'
      centered
      backdrop='static'
    >
      <Modal.Header closeButton className='border-bottom-0'></Modal.Header>
      <Modal.Body>
        <div className='myaccount-modal-body'>
          <form>
            <h5 className='mb-4 modal-heading' style={{ lineHeight: '35px' }}>
              <b>Please enter your new email address</b>
            </h5>
            <div className='form-row'>
              <div className='form-group col-md-12'>
                <input
                  type='email'
                  className='form-control'
                  id='email'
                  value={data.email}
                  placeholder='Email Address'
                  onChange={(e) =>
                    setData({ ...data, [e.target.type]: e.target.value })
                  }
                  required='required'
                ></input>
              </div>
            </div>
            <button
              type='submit'
              className='btn btn-yellow w-100 mt-4'
              onClick={(e) => emailCheck(e)}
            >
              CHANGE
              {loader ? (
                <span style={{ marginLeft: '5px' }}>
                  <Icofont icon='spinner' size='1' spin='true' />
                </span>
              ) : null}
            </button>
          </form>
          <div className='acc-name-update d-none'>
            <h5>
              <b>Your account name has been successfully changed</b>
            </h5>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
}

EmailChangeModal.propTypes = {
  auth: PropTypes.object.isRequired,
  sendEmailVerificationCode: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps, { sendEmailVerificationCode })(
  EmailChangeModal
);
