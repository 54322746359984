import React from 'react';
import PropTypes from 'prop-types';
// import InputField from '../Elements/InputField';
import Icofont from 'react-icofont';
import { connect } from 'react-redux';
import { sendEmailVerificationCode } from '../../actions/auth';

function EmailDetail({
  changeStep,
  data,
  setData,
  loader,
  setLoader,
  sendEmailVerificationCode,
}) {
  const onClickNext = async (e) => {
    e.preventDefault();

    setLoader(true);

    const send = await sendEmailVerificationCode(
      data.email,
      data.first_name,
      data.last_name
    );

    if (send === true) {
      setLoader(false);
      localStorage.setItem('data', JSON.stringify(data));
      localStorage.setItem('steps', '2.1');
      changeStep(e, '2.1');
    } else {
      setLoader(false);
    }
  };

  return (
    <div className='row animated zoomIn'>
      <div className='col-md-12'>
        <svg
          xmlns='http://www.w3.org/2000/svg'
          width='24'
          height='24'
          viewBox='0 0 26 26'
          style={{ cursor: 'pointer' }}
          onClick={(e) => {
            localStorage.removeItem('data', JSON.stringify(data));
            localStorage.removeItem('steps', '1');
            changeStep(e, '1');
          }}>
          <g fill='none' fillRule='evenodd'>
            <g>
              <g transform='translate(-521 -139) translate(522 140)'>
                <circle cx='12' cy='12' r='12' stroke='#FFF' strokeWidth='.7' />
                <g fill='#FFF' fillRule='nonzero'>
                  <path
                    d='M6.308 13.874c.08.084.183.126.287.126.103 0 .207-.042.286-.126.159-.168.159-.44 0-.608L.978 7 6.881.734c.159-.168.159-.44 0-.608-.158-.168-.414-.168-.573 0l-6.19 6.57c-.158.168-.158.44 0 .608l6.19 6.57z'
                    transform='translate(8 5)'
                  />
                </g>
              </g>
            </g>
          </g>
        </svg>
      </div>
      <div className='col-md-12'>
        <div className='' style={{ marginTop: '66px' }}>
          <h4 className='main-steps'>STEP 2 OF 4</h4>
        </div>
      </div>
      <div className='col-md-12'>
        <div className='' style={{ marginTop: '12px' }}>
          <h4 className='main-heading'>Enter your email</h4>
        </div>
      </div>
      <form
        onSubmit={(e) => {
          /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(
            data.email
          ) === false || loader
            ? e.preventDefault()
            : onClickNext(e);
        }}
        style={{ width: '100%' }}>
        <div className='col-md-12'>
          <div className='' style={{ marginTop: '28px' }}>
            <div className='position-relative w-100' style={{ height: '39px' }}>
              <input
                type='email'
                className='email-input'
                style={{
                  width: '100%',
                  height: '39px',
                  // boxShadow: '0 2px 4px 0 #353535',
                  border: 'solid 1px #353535',
                  backgroundColor: '#ffffff',
                  padding: '9px 15px 8px 15px',
                  borderRadius: '3px',
                  // color: 'white',
                  fontSize: '15px',
                  fontWeight: '300',
                  fontFamily: 'AvenirLight',
                }}
                value={data.email}
                placeholder='Email Address'
                onChange={(e) =>
                  setData({ ...data, [e.target.type]: e.target.value })
                }
                required='required'
              />
            </div>
            {/* <InputField
            type='email'
            placeholder='Email Address'
            withIcon={false}
          /> */}
          </div>
        </div>
        <div className='col-md-12'>
          <div className=' position-relative' style={{ marginTop: '33px' }}>
            <div className='custom-control custom-checkbox'>
              <input
                type='checkbox'
                className='custom-control-input'
                id='customCheck'
                name='example1'
              />
              <label
                className='custom-control-label label-recieve-updates'
                htmlFor='customCheck'>
                Yes, I would like to recieve updates, special offers and other
                updates from LOOP.{' '}
              </label>
            </div>
          </div>
        </div>
        <div className='col-md-12'>
          <button
            // onClick={(e) => changeStep(e, 2)}
            style={
              /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(
                data.email
              ) === false || loader
                ? {
                    pointerEvents: 'none',
                    backgroundColor: '#1d3246',
                    border: 'none',
                  }
                : { border: 'none' }
            }
            type='submit'
            className='next-button'>
            NEXT
            {loader ? (
              <span style={{ marginLeft: '5px' }}>
                <Icofont icon='spinner' size='1' spin='true' />
              </span>
            ) : null}
          </button>
          {/* <div
          className='next-button'
          onClick={(e) => onClickNext(e)}
          style={
            /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(
              data.email
            ) === false || loader
              ? { pointerEvents: 'none', backgroundColor: '#f1b300b0' }
              : null
          }
        >
          <h4>
            NEXT{' '}
            {loader ? (
              <span style={{ marginLeft: '5px' }}>
                <Icofont icon='spinner' size='1' spin='true' />
              </span>
            ) : null}
          </h4>
        </div> */}
        </div>
      </form>
    </div>
  );
}

EmailDetail.propTypes = {
  changeStep: PropTypes.func.isRequired,
  data: PropTypes.object.isRequired,
  setData: PropTypes.func.isRequired,
  loader: PropTypes.bool.isRequired,
  setLoader: PropTypes.func.isRequired,
  sendEmailVerificationCode: PropTypes.func.isRequired,
};

export default connect(null, { sendEmailVerificationCode })(EmailDetail);
