import React from 'react';
import '../../assets/css/postLogin.css';
import { Container, Row, Col } from 'react-bootstrap';
import Instagram from '../../assets/images/instagram.png';
import Facebook from '../../assets/images/facebook.png';
import Youtube from '../../assets/images/youtube.png';
import loop_logo from '../../assets/images/loop_logo.png';
import Icofont from 'react-icofont';

function Footer(props) {
  return (
    <footer className='footer'>
      <Container fluid>
        <Row className='justify-content-between'>
          <Col xl={6} lg={8} md={8} sm={6}>
            <h6>Contact</h6>
            <div className='info d-flex flex-column flex-lg-row'>
              <div>
                <h6 className='heading'>Address</h6>
                <h6>
                  200 SPECTRUM, CENTER DRIVE, <br />
                  SUITE 300, IRVINE, CA 92618
                </h6>
              </div>
              <div className='ml-0 ml-lg-5'>
                <h6 className='heading'>Email</h6>
                <h6>info@getloop.us</h6>
              </div>
            </div>
          </Col>
          <Col xl={3} lg={4} md={4} sm={6}>
            <div className='float-sm-right'>
              <h6>Follow Us</h6>
              <div className='social-links'>
                <a href='https://www.instagram.com/' target='_blank'>
                  <Icofont icon='instagram' size='2' />
                  {/* <img src={Instagram} alt='instagram' width='40' /> */}
                </a>
                <a href='https://www.facebook.com/' target='_blank'>
                  <Icofont icon='facebook' size='2' />
                </a>
                <a href='https://www.youtube.com/' target='_blank'>
                  <Icofont icon='youtube' size='2' />
                </a>
              </div>
            </div>
          </Col>
          <Col xl={12}>
            <h6 className='mt-5 mt-sm-0'>
              <img src={loop_logo} className='mr-1' width='80' />
              &#169; Copyright, 2021
            </h6>
          </Col>
        </Row>
      </Container>
    </footer>
  );
}

Footer.propTypes = {};

export default Footer;
