import React, { Fragment, useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Modal } from 'react-bootstrap';
import ResendModal from './ResendModal';
import { connect } from 'react-redux';
import { verifyCode, sendEmailVerificationCode } from '../../actions/auth';
import Icofont from 'react-icofont';

function EmailCheckModal({
  showEmailCheckModal,
  setEmailCheckModal,
  data,
  setData,
  setSuccessMessageModal,
  verifyCode,
  sendEmailVerificationCode,
}) {
  const [showResendModal, setResendModal] = useState(false);

  const [loader, setLoader] = useState(false);

  const check123 = (e) => {
    if (e.target.value.length > e.target.maxLength) {
      e.target.value = e.target.value.slice(0, e.target.maxLength);
    }
  };

  const codeInput = useRef();

  const onClickContinue = async (e) => {
    e.preventDefault();

    setLoader(true);

    let sendData = new FormData();

    sendData.append('email', data.email);
    sendData.append('otp', codeInput.current.value);

    const verify = await verifyCode(sendData, 'update');

    if (verify === true) {
      setLoader(false);
      setData({
        ...data,
        successTitle: 'Your email address has been successfully updated to',
        successMessage:
          'You can now use this email address to sign in to your Loop account.',
      });
      setEmailCheckModal(false);
      setSuccessMessageModal(true);
    } else {
      setLoader(false);
    }
  };

  const resendCode = async (e) => {
    e.preventDefault();

    const send = await sendEmailVerificationCode(data.email);

    if (send === true) {
      setResendModal(true);
    }
  };

  return (
    <Fragment>
      <Modal
        show={showEmailCheckModal}
        onHide={() => setEmailCheckModal(false)}
        size='xl'
        aria-labelledby='contained-modal-title-vcenter'
        centered
        backdrop='static'>
        <Modal.Header closeButton className='border-bottom-0'></Modal.Header>
        <Modal.Body>
          <div className='myaccount-modal-body'>
            <form>
              <h5 className='mb-4 modal-heading' style={{ lineHeight: '35px' }}>
                <b>Check your email inbox</b>
              </h5>
              <div className='form-row'>
                <div className='form-group col-md-12'>
                  <div className='col-md-12 '>
                    <p className='model-label-text'>
                      We need to verify your email address. We’ve sent you an
                      email to{' '}
                      <span
                        style={{
                          fontWeight: '900',
                        }}>
                        {data.email}
                      </span>{' '}
                      containing a 6-digit code which expires in 15 minutes.
                      Please enter it below..{' '}
                    </p>
                  </div>

                  <div className='col-md-12'>
                    <div
                      className=''
                      id='accEmailCheck'
                      style={{ marginTop: '15px' }}>
                      <input
                        onInput={(e) => check123(e)}
                        type='number'
                        className='form-control'
                        maxLength='6'
                        placeholder='000000'
                        ref={codeInput}></input>
                    </div>
                  </div>

                  <div className='col-md-12'>
                    <div
                      className='next-button'
                      style={{ marginTop: '24px' }}
                      onClick={(e) => onClickContinue(e)}>
                      <h4>
                        CONTINUE{' '}
                        {loader ? (
                          <span style={{ marginLeft: '5px' }}>
                            <Icofont icon='spinner' size='1' spin='true' />
                          </span>
                        ) : null}
                      </h4>
                    </div>
                  </div>

                  <div className='col-md-12'>
                    <div
                      className='position-relative'
                      style={{ marginTop: '25px' }}>
                      <p className='modal-label-text'>
                        Didnt recieve the email? Try checking your junk for spam
                        folders.{' '}
                      </p>
                      <p
                        className='model-label-text'
                        style={{
                          fontWeight: '900',
                          marginTop: '19px',
                          cursor: 'pointer',
                        }}
                        onClick={(e) => resendCode(e)}>
                        Resend
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </Modal.Body>
      </Modal>
      <ResendModal
        showResendModal={showResendModal}
        setResendModal={setResendModal}
      />
    </Fragment>
  );
}

EmailCheckModal.propTypes = {
  verifyCode: PropTypes.func.isRequired,
  sendEmailVerificationCode: PropTypes.func.isRequired,
};

export default connect(null, { verifyCode, sendEmailVerificationCode })(
  EmailCheckModal
);
