import React from 'react';
import { Link } from 'react-router-dom';
import '../../assets/css/postLogin.css';
import loop_logo from '../../assets/images/loop_logo.png';
import { connect } from 'react-redux';
import { logoutClient } from '../../actions/auth';
import '../../assets/css/navbar.css';
import { Navbar, Nav } from 'react-bootstrap';
import { LinkContainer } from 'react-router-bootstrap';

function TopBarAfterLogin({ logoutClient }) {
  const logoutUser = (e) => {
    e.preventDefault();
    logoutClient();
  };

  return (
    <div className='swiss-navbar'>
      <Navbar
        collapseOnSelect
        expand='xl'
        // fixed='top'
        variant='light'
        className='navbarFixed bg-black shadow-lg'>
        <LinkContainer to='/home'>
          <Navbar.Brand>
            <img src={loop_logo} alt='logo' className='img-fluid' width='100' />
          </Navbar.Brand>
        </LinkContainer>
        <Navbar.Toggle aria-controls='responsive-navbar-nav' />

        <Nav className='ml-auto' onClick={(e) => logoutUser(e)}>
          <a
            className='nav-link'
            style={{ color: '#ffffff' }}
            onClick={(e) => logoutUser(e)}>
            Log Out
          </a>
        </Nav>
      </Navbar>
    </div>
    // <div className='container-fluid swiss-navbar' id='header'>
    //   <div className='d-flex align-items-center justify-content-between'>
    //     <Link to='/home'>
    //       <img src={loop_logo} alt='LOOP' />
    //     </Link>
    //     <p className='logout' onClick={(e) => logoutUser(e)}>
    //       Logout
    //     </p>
    //   </div>
    // </div>
  );
}

TopBarAfterLogin.propTypes = {};

export default connect(null, { logoutClient })(TopBarAfterLogin);
