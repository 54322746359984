import React, { useState } from 'react';
import PropTypes from 'prop-types';
// import InputField from '../Elements/InputField';
import { connect } from 'react-redux';
import { createUser } from '../../actions/auth';
import Icofont from 'react-icofont';
import IconWarning from '../../assets/images/icon-warning.png';

function PasswordDetail({
  data,
  setData,
  changeStep,
  loader,
  setLoader,
  createUser,
}) {
  const [typeChange, setTypeChange] = useState('password');
  const [toggleIcon, setToggleIcon] = useState(true);
  const [error, setError] = useState(false);

  const togglePassword = (e, d) => {
    e.preventDefault();

    setTypeChange(d);
    setToggleIcon(!toggleIcon);
  };

  const onClickContinuePassword = async (e) => {
    e.preventDefault();

    setLoader(true);

    // console.log('hello1231323sadsads');

    const create = await createUser(data);

    if (create === true) {
      setLoader(false);
      changeStep(e, 4);
    } else {
      setLoader(false);
    }
  };

  const checkPassword = (e) => {
    // var error;
    if (
      /^(?=.*[!@#$%^&*])/.test(e.target.value) &&
      e.target.value.length > 6 &&
      /^(?=.*[A-Z])/.test(e.target.value)
    ) {
      //   console.log('in heree');

      setError(false);
    } else {
      setError(true);
    }

    setData({ ...data, password: e.target.value });
  };

  return (
    <div className='row animated zoomIn'>
      <div className='col-md-12'>
        <div className='text-white' style={{ marginTop: '66px' }}>
          <h4 className='main-steps'>STEP 3 OF 4</h4>
        </div>
      </div>
      <div className='col-md-12'>
        <div className='text-white' style={{ marginTop: '12px' }}>
          <h4 className='main-heading'>Create a password</h4>
        </div>
      </div>
      <form
        onSubmit={(e) => {
          data.password === '' || error
            ? e.preventDefault()
            : onClickContinuePassword(e);
        }}
        style={{ width: '100%' }}>
        <div className='col-md-12'>
          <div className='text-white' style={{ marginTop: '28px' }}>
            <div className='position-relative w-100' style={{ height: '39px' }}>
              {error ? (
                <div
                  className='password-requirement'
                  style={{
                    position: 'absolute',
                    top: '50%',
                    transform: 'translateY(-50%)',
                    right: '-100%',
                    color: '#ffacac',
                  }}>
                  <p style={{ margin: '0' }}>
                    <span>
                      <img src={IconWarning} alt='icon-warning' />
                    </span>{' '}
                    Password does not meet requirements
                  </p>
                </div>
              ) : null}

              <input
                type={typeChange}
                className='email-input'
                style={
                  error
                    ? {
                        width: '100%',
                        height: '39px',
                        // boxShadow: '0 2px 4px 0 #353535',
                        border: 'solid 1px #ffacac',
                        backgroundColor: '#ffffff',
                        padding: '9px 15px 8px 15px',
                        borderRadius: '3px',
                        // color: 'white',
                        fontSize: '15px',
                        fontWeight: '300',
                        fontFamily: 'AvenirLight',
                      }
                    : {
                        width: '100%',
                        height: '39px',
                        // boxShadow: '0 2px 4px 0 #353535',
                        border: 'solid 1px #353535',
                        backgroundColor: '#fffff',
                        padding: '9px 15px 8px 15px',
                        borderRadius: '3px',
                        // color: 'white',
                        fontSize: '15px',
                        fontWeight: '300',
                        fontFamily: 'AvenirLight',
                      }
                }
                placeholder='Password'
                value={data.password}
                onChange={
                  (e) => checkPassword(e)
                  // setData({ ...data, password: e.target.value })
                }
              />
              {toggleIcon ? (
                <svg
                  xmlns='http://www.w3.org/2000/svg'
                  width='16'
                  height='14'
                  viewBox='0 0 16 14'
                  onClick={(e) => togglePassword(e, 'text')}
                  style={{
                    position: 'absolute',
                    top: '50%',
                    transform: 'translateY(-50%)',
                    right: '14px',
                    cursor: 'pointer',
                  }}>
                  <g fill='none' fillRule='evenodd'>
                    <g fill='#FFF'>
                      <g>
                        <path
                          d='M7.65 1.224c-3.306 0-6.18 1.76-7.53 4.35l-.12.24.12.234c1.35 2.593 4.223 4.356 7.53 4.356 3.307 0 6.18-1.763 7.53-4.356l.12-.234-.12-.24c-1.35-2.59-4.224-4.35-7.53-4.35zM6.19 2.377c-1.322.573-2.25 1.898-2.25 3.437 0 1.538.928 2.864 2.25 3.437-2.2-.406-4.027-1.707-5.026-3.437 1-1.73 2.826-3.032 5.026-3.437zm2.919 0c2.2.405 4.027 1.706 5.026 3.437-1 1.73-2.826 3.031-5.026 3.437 1.322-.573 2.25-1.899 2.25-3.437 0-1.539-.928-2.865-2.25-3.437zm-1.459.717c1.495 0 2.697 1.212 2.697 2.72 0 1.508-1.202 2.72-2.697 2.72s-2.697-1.212-2.697-2.72c0-1.508 1.202-2.72 2.697-2.72z'
                          transform='translate(-874 -289) translate(874 289.937)'
                        />
                        <path
                          stroke='#FFF'
                          strokeWidth='.5'
                          d='M6.432 -2.25H7.932V14.75H6.432z'
                          transform='translate(-874 -289) translate(874 289.937) rotate(45 7.182 6.25)'
                        />
                      </g>
                    </g>
                  </g>
                </svg>
              ) : (
                <svg
                  xmlns='http://www.w3.org/2000/svg'
                  width='16'
                  height='14'
                  viewBox='0 0 16 14'
                  onClick={(e) => togglePassword(e, 'password')}
                  style={{
                    position: 'absolute',
                    top: '50%',
                    transform: 'translateY(-50%)',
                    right: '14px',
                    cursor: 'pointer',
                  }}>
                  <g fill='none' fillRule='evenodd'>
                    <g fill='#FFF'>
                      <g>
                        <path
                          d='M7.65 1.224c-3.306 0-6.18 1.76-7.53 4.35l-.12.24.12.234c1.35 2.593 4.223 4.356 7.53 4.356 3.307 0 6.18-1.763 7.53-4.356l.12-.234-.12-.24c-1.35-2.59-4.224-4.35-7.53-4.35zM6.19 2.377c-1.322.573-2.25 1.898-2.25 3.437 0 1.538.928 2.864 2.25 3.437-2.2-.406-4.027-1.707-5.026-3.437 1-1.73 2.826-3.032 5.026-3.437zm2.919 0c2.2.405 4.027 1.706 5.026 3.437-1 1.73-2.826 3.031-5.026 3.437 1.322-.573 2.25-1.899 2.25-3.437 0-1.539-.928-2.865-2.25-3.437zm-1.459.717c1.495 0 2.697 1.212 2.697 2.72 0 1.508-1.202 2.72-2.697 2.72s-2.697-1.212-2.697-2.72c0-1.508 1.202-2.72 2.697-2.72z'
                          transform='translate(-874 -289) translate(874 289.937)'
                        />
                      </g>
                    </g>
                  </g>
                </svg>
              )}
            </div>
            {/* <InputField type='password' placeholder='Password' withIcon={true} /> */}
          </div>
        </div>
        <div className='col-md-12'>
          <div
            className='text-white position-relative'
            style={{ marginTop: '33px' }}>
            <p className='main-heading-paragraph' htmlFor='customCheck'>
              Use a minimum of 6 characters (case sensitive) with atleast one
              number or special character.{' '}
            </p>
          </div>
        </div>
        <div className='col-md-12'>
          <button
            // onClick={(e) => changeStep(e, 2)}
            style={
              data.password === '' || error
                ? {
                    pointerEvents: 'none',
                    backgroundColor: '#1d3246',
                    border: 'none',
                  }
                : { border: 'none' }
            }
            type='submit'
            className='next-button'>
            CONTINUE{' '}
            {loader ? (
              <span style={{ marginLeft: '5px' }}>
                <Icofont icon='spinner' size='1' spin='true' />
              </span>
            ) : null}
          </button>
          {/* <div
            className='next-button'
            style={{ marginTop: '45px' }}
            onClick={(e) => onClickContinuePassword(e)}
          >
            <h4>CONTINUE </h4>
          </div> */}
        </div>
      </form>
    </div>
  );
}

PasswordDetail.propTypes = {
  createUser: PropTypes.func.isRequired,
};

export default connect(null, { createUser })(PasswordDetail);
