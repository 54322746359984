import React, { useEffect, useState, Fragment, useRef } from 'react';
import PropTypes from 'prop-types';
import { Modal } from 'react-bootstrap';
import {
  CardElement,
  Elements,
  useStripe,
  useElements,
  CardNumberElement,
  CardCvcElement,
  CardExpiryElement,
} from '@stripe/react-stripe-js';
import CardUpdateInfo from './CardUpdateInfo';
import { Accordion, Card } from 'react-bootstrap';
import { connect } from 'react-redux';
import { getPaymentMethods } from '../../actions/auth';
import MasterCard from '../../assets/images/masterCard.svg';
import Visa from '../../assets/images/card.jpg';
import Icofont from 'react-icofont';

function ManagePaymentModal({
  showManagePaymentModal,
  setManagePaymentModal,
  setAddNewPaymentModal,

  auth: { userDetail, payment_methods },
  getPaymentMethods,
}) {

  const monthRegex = /^\d\d$/; // regex to match "MM"

  useEffect(() => {
    if (showManagePaymentModal && userDetail !== null) {
      getPaymentMethods(userDetail.email);
    }
  }, [showManagePaymentModal]);


  const save = (e) => {
    e.preventDefault();
  };

  return (
    <Modal
      show={showManagePaymentModal}
      onHide={() => setManagePaymentModal(false)}
      size='xl'
      aria-labelledby='contained-modal-title-vcenter'
      centered
      backdrop='static'
    >
      <Modal.Header closeButton className='border-bottom-0'></Modal.Header>

      <Modal.Body className='myaccount-modal-body'>
        <Fragment>
          <form onSubmit={(e) => save(e)}>
            <h5 className='mb-2'>
              <b>Manage payment info</b>
            </h5>
            <p className='mb-4'>Edit your card details below.</p>
            {payment_methods !== null ? (
              <Fragment>
                {payment_methods.map((item, index) => (
                  
                  <Accordion
                    as={'div'}
                    key={index}
                    style={{ marginTop: '10px' }}
                  >

                    <Accordion.Toggle as={'div'} eventKey='0'>
                      
                      <div className='payment-box'>
                        <div className='expiry-info'>
                          {item.brand === 'visa' ? (
                            <img
                              className='align-baseline'
                              src={Visa}
                              alt='visa'
                              width='30'
                            />
                          ) : item.brand === 'mastercard' ? (
                            <img
                              className='align-baseline'
                              src={MasterCard}
                              alt='master-card'
                              width='30'
                            />
                          ) : null}
                          <div className='ml-3'>
                            <p className='font-weight-bold'>
                              Visa ending in {item.last4}
                            </p>
                            <p>
                              Expires {item.exp_month}/
                              {item.exp_year.toString().slice(-2)} | {item.name}
                            </p>
                          </div>
                        </div>
                        <div className='arrow-down'></div>
                      </div>
                    </Accordion.Toggle>
                    <Accordion.Collapse eventKey='0'>
                      <Card.Body className='card p-0 border-0 bg-transparent'>
                        <CardUpdateInfo item={item} email={userDetail.email} />
                      </Card.Body>
                    </Accordion.Collapse>
                  </Accordion>
                ))}
              </Fragment>
            ) : (
              <Icofont icon='spinner' size='3' spin='true' />
            )}

          </form>
          <div className='text-center my-3'>
            <a
              href='#'
              className='gold text-uppercase'
              onClick={(e) => {
                e.preventDefault();
                setAddNewPaymentModal(true);
                setManagePaymentModal(false);
              }}
            >
              Add a new payment method
            </a>
          </div>
        </Fragment>
        
      </Modal.Body>
    </Modal>
  );
}

function CheckoutForm() {
  const [isPaymentLoading, setPaymentLoading] = useState(false);
  const stripe = useStripe();
  const elements = useElements();
  const payMoney = async (e) => {
    e.preventDefault();
    if (!stripe || !elements) {
      return;
    }
    setPaymentLoading(true);
    const clientSecret = 'dfsdfdf'; 
    const paymentResult = await stripe.confirmCardPayment(clientSecret, {
      payment_method: {
        card: elements.getElement(CardElement),
        billing_details: {
          name: 'Faruq Yusuff',
        },
      },
    });
    setPaymentLoading(false);
    if (paymentResult.error) {
      alert(paymentResult.error.message);
    } else {
      if (paymentResult.paymentIntent.status === 'succeeded') {
        alert('Success!');
      }
    }
  };
  return (
    <div
      style={{
        padding: '0rem',
      }}
    >
      <div
        style={{
          maxWidth: '100%',
          margin: '0 auto',
        }}
      >
        <form
          style={{
            display: 'block',
            width: '100%',
          }}
          onSubmit={payMoney}
        >
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            <p className='card-label'>NAME ON CARD </p>
            <div
              className='position-relative w-100'
              style={{ height: '39px', marginBottom: '8px' }}
            >
              <input
                type='email'
                className='email-input'
                style={{
                  width: '100%',
                  height: '39px',
                  //   border: "solid 1px #353535",
                  backgroundColor: '#e2e2e2',
                  padding: '9px 15px 8px 15px',
                  borderRadius: '3px',
                  color: 'black',
                  fontSize: '15px',
                  fontWeight: '300',
                  fontFamily: 'AvenirLight',
                }}
              />
            </div>

            <p className='card-label'>CARD NUMBER </p>
            <CardNumberElement
              className='card-number-light'
              options={{
                style: {
                  base: {
                    color: 'black',
                  },
                },
              }}
            />
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                width: '100%',
                justifyContent: 'space-between',
                marginTop: '8px',
              }}
            >
              <div style={{ width: '45%', marginBottom: '8px' }}>
                <p className='card-label'>EXPIRATION DATE </p>
                <CardExpiryElement
                  className='card-number-light'
                  options={{
                    style: {
                      base: {
                        color: 'black',
                        marginBottom: '5px',
                      },
                    },
                  }}
                />
              </div>

              <div style={{ width: '45%' }}>
                <p className='card-label'>SECURITY CODE </p>
                <CardCvcElement
                  className='card-number-light'
                  options={{
                    style: {
                      base: {
                        color: 'black',
                        marginBottom: '5px',
                      },
                    },
                  }}
                />
              </div>
            </div>

            <p className='card-label'>POSTAL CODE </p>
            {/* <CardNumberElement
                className="card-number"
                options={{
                  style: {
                    base: {
                      color: "gray",
                    },
                  },
                }}
              /> */}
            <div className='position-relative w-100' style={{ height: '39px' }}>
              <input
                type='email'
                className='email-input'
                style={{
                  width: '100%',
                  height: '39px',
                  // boxShadow: '0 2px 4px 0 #353535',
                  //   border: "solid 1px #353535",
                  backgroundColor: '#e2e2e2',
                  padding: '9px 15px 8px 15px',
                  borderRadius: '3px',
                  color: 'black',
                  fontSize: '15px',
                  fontWeight: '300',
                  fontFamily: 'AvenirLight',
                }}
              />
            </div>

            {/* <CardNumberElement
                options={{
                  style: { base: { backgroundColor: "red", width: "100px" } },
                }}
              /> */}
            <div
              className='next-button-pay'
              //   onClick={payMoney}
            >
              SAVE
            </div>
          </div>
        </form>

        <div className='col-md-12'>
          <p
            style={{
              fontFamily: 'AvenirHeavy',
              fontSize: '16px',
              lineHeight: '22px',
              color: '#c39100',
              textAlign: 'center',
            }}
          >
            ADD A NEW PAYMENT METHOD
          </p>
        </div>
      </div>
    </div>
  );
}

ManagePaymentModal.propTypes = {
  auth: PropTypes.object.isRequired,
  getPaymentMethods: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps, { getPaymentMethods })(
  ManagePaymentModal
);
