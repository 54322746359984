import React, { useState, useEffect } from 'react';
import {
  Navbar,
  // Form,
  // FormControl,
  Nav,
  NavDropdown,
  Button,
  Dropdown,
} from 'react-bootstrap';
import loop_logo from '../../assets/images/loop_logo.png';
// import Bell from '../../assets/images/bell.png';
// import Search from '../../assets/images/search.png';
import { Link } from 'react-router-dom';
import { logoutClient, getStreamingClassesList } from '../../actions/auth';
import { connect } from 'react-redux';
import { LinkContainer } from 'react-router-bootstrap';
import { useHistory } from 'react-router-dom';

import { streamModal } from '../../actions/modal';

function NavbarHome({
  logoutClient,
  positionFixed,
  auth,
  modal: { streamingModalState },
  streamModal,
  getStreamingClassesList,
}) {
  // const [searched, setSearched] = useState(false);
  const [navBarStyle, setnavBarStyle] = useState(true);

  const history = useHistory();

  const listenScrollEvent = (e) => {
    if (window.scrollY > 50) {
      setnavBarStyle(false);
      //   console.log('scroll');
    } else {
      setnavBarStyle(true);
      //   console.log('top');
    }
  };
  useEffect(() => {
    window.addEventListener('scroll', listenScrollEvent);
  }, []);

  useEffect(() => {
    getStreamingClassesList();
  }, [getStreamingClassesList]);

  const linkToMyAccount = () => {
    history.push('/my-account');
  };
  return (
    <div className='swiss-navbar'>
      <Navbar
        collapseOnSelect
        expand='xl'
        // fixed='top'
        variant='dark'
        className={
          positionFixed && navBarStyle
            ? 'navbarFixed bg-navbar shadow-lg'
            : 'bg-black'
        }
        style={
          positionFixed
            ? { position: 'fixed', top: 0, right: 0, left: 0, zIndex: 1030 }
            : { position: 'relative', top: 0, right: 0, left: 0, zIndex: 1030 }
        }>
        <LinkContainer to='/home'>
          <Navbar.Brand>
            <img src={loop_logo} alt='logo' className='img-fluid' width='100' />
          </Navbar.Brand>
        </LinkContainer>
        <Navbar.Toggle aria-controls='responsive-navbar-nav' />
        <Navbar.Collapse id='responsive-navbar-nav'>
          <Nav className='mr-auto'>
            <Link className='nav-link' to='/home'>
              Home
            </Link>
            <Link className='nav-link' to='/favourites'>
              Favourites
            </Link>
          </Nav>
          {/* {auth?.userDetail ? (
            <Nav>
              {auth?.userDetail?.email === 'sahiladeem@getloop.us' ? (
                <Button
                  variant='outline-danger mx-3'
                  onClick={() => streamModal(true)}>
                  Stream Live
                </Button>
              ) : (
                <Dropdown className='mx-3'>
                  <Dropdown.Toggle
                    variant='danger'
                    id='dropdown-basic'
                    className='animate__animated animate__pulse animate__infinite'>
                    • Live Streaming
                  </Dropdown.Toggle>

                  <Dropdown.Menu>
                    {auth.classesStreamingList
                      ? auth?.classesStreamingList?.data.map((item, index) => (
                          <Dropdown.Item
                            key={index}
                            onClick={() => {
                              streamModal(true, item.class);
                            }}>
                            {item.name}
                          </Dropdown.Item>
                        ))
                      : null}
                  </Dropdown.Menu>
                </Dropdown>
                // <Button
                //   variant='danger mx-3'
                //   className='animate__animated animate__pulse animate__infinite'
                //   onClick={() => streamModal(true)}>
                //   Watch Live Stream
                // </Button>
              )}
            </Nav>
          ) : null} */}
          <Nav>
            {/* {history.location.pathname === '/home' ? (
              searched ? (
                <Form className='d-flex mr-5  '>
                  <FormControl
                    type='search'
                    placeholder='Search'
                    className='mr-2'
                    aria-label='Search'
                    onBlur={() => setSearched(!searched)}
                  />
                </Form>
              ) : (
                <Nav.Link
                  className='mr-5'
                  onClick={() => setSearched(!searched)}>
                  <img src={Search} width='25' />
                </Nav.Link>
              )
            ) : null} */}

            {/* <Nav.Link className='mr-5'>
              <img src={Bell} width='25' />
            </Nav.Link> */}
            <NavDropdown
              title={
                auth?.userDetail
                  ? `${auth?.userDetail?.first_name} ${auth?.userDetail?.last_name}`
                  : null
              }
              id='collasible-nav-dropdown'
              className='dropdown-menu-left'>
              {/* <Link to='/my-account'> */}
              <NavDropdown.Item
                onClick={() => {
                  linkToMyAccount();
                }}>
                My-Account
              </NavDropdown.Item>
              {/* </Link> */}
              <NavDropdown.Divider />
              <NavDropdown.Item onClick={() => logoutClient()}>
                Sign Out
              </NavDropdown.Item>
            </NavDropdown>
          </Nav>
        </Navbar.Collapse>
      </Navbar>
    </div>
  );
}
const mapStateToProps = (state) => ({
  auth: state.auth,
  modal: state.modal,
});
export default connect(mapStateToProps, {
  logoutClient,
  streamModal,
  getStreamingClassesList,
})(NavbarHome);
