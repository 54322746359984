import React from 'react';
import { Modal } from 'react-bootstrap';

function ResendModal({ showResendModal, setResendModal }) {
  return (
    <Modal
      show={showResendModal}
      onHide={() => setResendModal(false)}
      size='md'
      aria-labelledby='contained-modal-title-vcenter'
      centered
      contentClassName='resend-modal-content'
      backdrop='static'
    >
     
      <Modal.Body className='resend-modal-body'>
        <h4 className='main-heading' style={{ marginTop: '42px' }}>
          New email sent
        </h4>
        <p
          className='main-heading-paragraph'
          style={{ marginTop: '22px', height: '74px' }}
        >
          Make sure to check your spam folder. Still cant find it? Contact us so
          we can help.
        </p>
        <div className='buttons'>
          <button className='contact-btn'>CONTACT</button>
          <button className='ok-btn' onClick={(e) => setResendModal(false)}>
            OK
          </button>
        </div>
      </Modal.Body>

    </Modal>
  );
}

ResendModal.propTypes = {};

export default ResendModal;
