import { SUPPORT_SUCCESS, SUPPORT_FAILED } from "../actions/types";

const initialState = {
  support_msg: null,
};

export default function (state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case SUPPORT_SUCCESS:
      return {
        ...state,
        support_msg: payload,
      };
    case SUPPORT_FAILED:
      return {
        ...state,
        support_msg: payload,
      };
    default:
      return state;
  }
}
