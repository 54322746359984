import {
  GET_COURSES,
  ERROR_COURSES,
  CLEAR_COURSES,
  GET_COURSE_DETAIL,
  CLEAR_COURSE_DETAIL,
  SET_SINGLE_COURSE_DETAIL,
  GET_RECENTLY_ADDED_COURSES,
  GET_FAVOURITE_COURSES,
  CLEAR_FAVOURITES,
  SET_VIDEO_FAVOURITE,
  REMOVE_VIDEO_FAVOURITE,
  SET_CONTENT_FAVOURITE,
  REMOVE_CONTENT_FAVOURITE,
} from '../actions/types';

const initialState = {
  courses: null,
  courseDetail: null,
  courseDiscription: null,
  recentlyAddedCourses: null,
  favouriteCourses: null,
};

export default function (state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case GET_COURSES:
      return {
        ...state,
        courses: payload,
      };
    case GET_COURSE_DETAIL:
      return {
        ...state,
        courseDetail: payload,
      };
    case GET_RECENTLY_ADDED_COURSES:
      return {
        ...state,
        recentlyAddedCourses: payload,
      };
    case SET_SINGLE_COURSE_DETAIL:
      return {
        ...state,
        courseDiscription: payload,
      };
    case GET_FAVOURITE_COURSES:
      return {
        ...state,
        favouriteCourses: payload,
      };
    case SET_VIDEO_FAVOURITE:
      return {
        ...state,
        ...(state.courseDetail.data[payload.outerIndex].videos[
          payload.innerIndex
        ].favourite = true),
      };
    case REMOVE_VIDEO_FAVOURITE:
      return {
        ...state,
        ...(state.courseDetail.data[payload.outerIndex].videos[
          payload.innerIndex
        ].favourite = false),
      };
    case SET_CONTENT_FAVOURITE:
      return {
        ...state,
        ...(state.courseDetail.data[payload.outerIndex].content[
          payload.innerIndex
        ].favourite = true),
      };
    case REMOVE_CONTENT_FAVOURITE:
      return {
        ...state,
        ...(state.courseDetail.data[payload.outerIndex].content[
          payload.innerIndex
        ].favourite = false),
      };
    case ERROR_COURSES:
    case CLEAR_COURSES:
      return {
        ...state,
        courses: null,
        courseDetail: null,
        courseDiscription: null,
        recentlyAddedCourses: null,
      };
    case CLEAR_COURSE_DETAIL:
      return {
        ...state,
        courseDetail: null,
      };
    case CLEAR_FAVOURITES:
      return {
        ...state,
        favouriteCourses: null,
      };
    default:
      return state;
  }
}
