import React from "react";
import { Row, Col, Button, Modal, Tabs, Tab } from "react-bootstrap";
import { LinkContainer } from "react-router-bootstrap";
import { withRouter } from "react-router";

function SingleCourseModal({
  history,
  showCourseModal,
  setShowCourseModal,
  courseData,
}) {
  const goToSignup = (e) => {
    e.preventDefault();

    localStorage.setItem("yearly_selection", "yes");

    history.push("register");
  };
  return (
    <Modal
      show={showCourseModal}
      onHide={() => setShowCourseModal(false)}
      centered
      size="lg"
      className="single-course-modal"
      backdrop="static"
    >
      <Modal.Header className="border-bottom-0" closeButton></Modal.Header>
      <Modal.Body>
        <Row>
          <Col xl={4} lg={4} md={12} className="text-center text-lg-left">
            <img
              src={courseData ? courseData.web_image : null}
              className="img-fluid w-100"
            />
          </Col>
          <Col xl={8} lg={8} md={12} className="mt-4 mt-lg-0">
            <h2>{courseData ? courseData.name : null}</h2>
            <h6>{courseData ? courseData.description : null}</h6>
            <Row className="mt-5">
              <Col
                xl={5}
                lg={4}
                md={6}
                className="d-flex flex-column mr-0 mr-lg-3"
              >
                <p>$9.99 | Month</p>
                <LinkContainer to="/register">
                  <Button>Sign up now</Button>
                </LinkContainer>
              </Col>
              <Col xl={6} lg={5} md={6} className="d-flex flex-column">
                <p className="mt-4 mt-md-0">$99.00 | Year</p>
                <Button onClick={(e) => goToSignup(e)}>
                  Save on 12 months
                </Button>
              </Col>
            </Row>
            <div className="mt-3">
              <small>
                *Cancel at anytime with a 7 day money back guarantee
              </small>
            </div>
          </Col>
        </Row>
        <Row className="my-6 d-none">
          <Col xl={12}>
            <Tabs defaultActiveKey="teacher" id="courseModalTabs">
              <Tab eventKey="teacher" title="Teacher">
                <ul>
                  <li>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                  </li>
                </ul>
              </Tab>
              <Tab eventKey="curiculum" title="Curiculum">
                <ul>
                  <li>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                  </li>
                </ul>
              </Tab>
            </Tabs>
          </Col>
        </Row>
      </Modal.Body>
    </Modal>
  );
}

export default withRouter(SingleCourseModal);
