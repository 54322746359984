import { STREAMING_MODAL } from '../actions/types';

const initialState = {
  streamingModalState: false,
  streamingModalURL: '',
};

export default function (state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case STREAMING_MODAL:
      return {
        ...state,
        streamingModalState: payload.state,
        streamingModalURL: payload.url,
      };
    default:
      return state;
  }
}
