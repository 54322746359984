import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Modal } from 'react-bootstrap';
import { loadStripe } from '@stripe/stripe-js';
import {
  Elements,
  useStripe,
  useElements,
  CardNumberElement,
  CardCvcElement,
  CardExpiryElement,
} from '@stripe/react-stripe-js';
import Noty from 'noty';
import { addPaymentMethod } from '../../actions/auth';
import { connect } from 'react-redux';
import { STRIPE_PUBLISH_KEY } from '../../actions/types';

function AddNewPaymentMethod({
  showAddNewPaymentModal,
  setAddNewPaymentModal,
  setManagePaymentModal,
  addPaymentMethod,
  auth: { userDetail },
  cardData,
  setCardData,
}) {
  useEffect(() => {
    if (showAddNewPaymentModal) {
    } else {
      setCardData({
        ...cardData,
        cardName: '',
        cardPostal: '',
      });
    }
  }, [showAddNewPaymentModal]);

  const stripe = loadStripe(STRIPE_PUBLISH_KEY);

  return (
    <Modal
      show={showAddNewPaymentModal}
      onHide={() => {
        setAddNewPaymentModal(false);
        setManagePaymentModal(true);
      }}
      size='xl'
      aria-labelledby='contained-modal-title-vcenter'
      centered
      backdrop='static'>
      <Modal.Header closeButton className='border-bottom-0'></Modal.Header>

      <Modal.Body style={{ height: '700px' }}>
        <div className='container-fluid'>
          <div className='row'>
            <div className='col-md-4'></div>
            <div className='col-md-4'>
              <div className='row '>
                <div className='col-md-12'>
                  <div className='text-dark' style={{ marginTop: '12px' }}>
                    <h4 className='main-heading text-dark'>
                      Add New Payment Method
                    </h4>
                  </div>
                </div>

                <div className='col-md-12'>
                  <div className='text-white' style={{ marginTop: '28px' }}>
                    <div
                      className='position-relative w-100'
                      style={{ height: '39px' }}>
                      <Elements stripe={stripe}>
                        <CheckoutForm
                          addPaymentMethod={addPaymentMethod}
                          setAddNewPaymentModal={setAddNewPaymentModal}
                          setManagePaymentModal={setManagePaymentModal}
                          userDetail={userDetail}
                          cardData={cardData}
                          setCardData={setCardData}
                        />
                      </Elements>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className='col-md-4'></div>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
}

function CheckoutForm({
  addPaymentMethod,
  setAddNewPaymentModal,
  setManagePaymentModal,
  userDetail,
  cardData,
  setCardData,
}) {
  const [isPaymentLoading, setPaymentLoading] = useState(false);
  const stripe = useStripe();
  const elements = useElements();

  const payMoney = async (e) => {
    e.preventDefault();
    if (!stripe || !elements) {
      return;
    }
    setPaymentLoading(true);
    const { error, paymentMethod } = await stripe.createPaymentMethod({
      type: 'card',
      card: elements.getElement(
        CardNumberElement,
        CardExpiryElement,
        CardCvcElement
      ),
      billing_details: {
        name: cardData.cardName,
        email: userDetail !== null ? userDetail.email : '',
        address: {
          postal_code: cardData.cardPostal,
        },
      },
    });

    if (error) {
      console.log('[error]', error);

      new Noty({
        type: 'error',
        layout: 'bottomCenter',
        text: error.message,
        timeout: 300000,
      }).show();
      setPaymentLoading(false);
    } else {
      let sendData = new FormData();

      sendData.append('email', userDetail !== null ? userDetail.email : '');
      sendData.append('id', paymentMethod.id);

      const res = await addPaymentMethod(sendData);

      if (res) {
        setAddNewPaymentModal(false);
        setManagePaymentModal(true);
        setPaymentLoading(false);
      } else {
        setPaymentLoading(false);
      }
    }
  };

  return (
    <div
      style={{
        padding: '0rem',
      }}>
      <div
        style={{
          maxWidth: '100%',
          margin: '0 auto',
        }}>
        <form
          style={{
            display: 'block',
            width: '100%',
          }}
          onSubmit={payMoney}>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}>
            <p className='card-label text-dark'>NAME ON CARD </p>
            <div
              className='position-relative w-100'
              style={{ height: '39px', marginBottom: '8px' }}>
              <input
                type='text'
                className='email-input'
                style={{
                  width: '100%',
                  height: '39px',
                  border: 'solid 1px #e2e2e2',
                  backgroundColor: '#e2e2e2',
                  padding: '9px 15px 8px 15px',
                  borderRadius: '3px',
                  color: 'black',
                  fontSize: '16px',
                  fontWeight: '300',
                  fontFamily: 'AvenirLight',
                }}
                value={cardData.cardName}
                onChange={(e) =>
                  setCardData({ ...cardData, cardName: e.target.value })
                }
              />
            </div>

            <p className='card-label text-dark'>CARD NUMBER </p>
            <CardNumberElement
              className='card-number card-number-payment'
              options={{
                style: {
                  base: {
                    border: '1px solid #e2e2e2',
                    backgroundColor: '#e2e2e2',
                    color: 'black',
                  },
                },
                showIcon: true,
              }}
            />
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                width: '100%',
                justifyContent: 'space-between',
                marginTop: '8px',
              }}>
              <div style={{ width: '45%', marginBottom: '8px' }}>
                <p className='card-label text-dark'>EXPIRATION DATE </p>
                <CardExpiryElement
                  className='card-number card-number-payment'
                  options={{
                    style: {
                      base: {
                        border: '1px solid #e2e2e2',
                        backgroundColor: '#e2e2e2',
                        color: 'black',
                        marginBottom: '5px',
                      },
                    },
                  }}
                />
              </div>

              <div style={{ width: '45%' }}>
                <p className='card-label text-dark'>SECURITY CODE </p>
                <CardCvcElement
                  className='card-number card-number-payment'
                  options={{
                    style: {
                      base: {
                        border: '1px solid #e2e2e2',
                        backgroundColor: '#e2e2e2',
                        color: 'black',
                        marginBottom: '5px',
                      },
                    },
                  }}
                />
              </div>
            </div>

            <p className='card-label text-dark'>POSTAL CODE </p>

            <div className='position-relative w-100' style={{ height: '39px' }}>
              <input
                type='text'
                className='email-input'
                style={{
                  width: '100%',
                  height: '39px',
                  border: '1px solid #e2e2e2',
                  backgroundColor: '#e2e2e2',
                  color: 'black',
                  padding: '9px 15px 8px 15px',
                  borderRadius: '3px',
                  fontSize: '16px',
                  fontWeight: '300',
                  fontFamily: 'AvenirLight',
                }}
                value={cardData.cardPostal}
                onChange={(e) =>
                  setCardData({ ...cardData, cardPostal: e.target.value })
                }
              />
            </div>

            <div className='next-button-pay' onClick={payMoney}>
              {isPaymentLoading ? 'LOADING...' : 'SAVE'}
            </div>
          </div>
        </form>
      </div>
    </div>
  );
}

AddNewPaymentMethod.propTypes = {
  addPaymentMethod: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps, { addPaymentMethod })(
  AddNewPaymentMethod
);
