import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';
import Visa from '../../assets/images/card.jpg';
import MasterCard from '../../assets/images/masterCard.svg';
import { connect } from 'react-redux';
import { setPaymentDefault, deletePaymentMethod } from '../../actions/auth';

function CardUpdateInfo({
  item,
  setPaymentDefault,
  email,
  deletePaymentMethod,
}) {

  const myRef = useRef();

  const [cardData, setCardData] = useState({
    cardName: item.name,
    cardExpiry: `${item.exp_month} / ${item.exp_year.toString().slice(-2)}`,
    cardPostal: item.postal_code,
    default: item.default,
  });

  const delete_payment_method = (e, id) => {
    e.preventDefault();
    deletePaymentMethod(id);
  };

  return (
    <div>
      <div
        className='d-flex flex-column flex-sm-row align-items-left align-items-sm-center justify-content-between mt-2'

      >
        <div
          className='custom-control custom-checkbox'
          style={{
            paddingLeft: '34px !important',
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <input
            type='checkbox'
            className='custom-control-input'
            id={'customCheck' + item.last4}
            name={item.id}
            ref={myRef}
            defaultChecked={cardData.default}
            style={cardData.default ? { pointerEvents: 'none' } : null}
            onChange={() => {
              let sendData = new FormData();

              sendData.append('p_id', myRef.current.name);
              sendData.append('email', email);

              setPaymentDefault(sendData);
            }}
          />
          <label
            className='custom-control-label label-use-default'
            htmlFor={'customCheck' + item.last4}
            style={
              cardData.default
                ? { pointerEvents: 'none', width: 'max-content' }
                : { width: 'max-content' }
            }
          
          >
            Use as default payment method
          </label>
        </div>
        {item.default ? null : (
          <a
            href='#'
            className='gold text-uppercase'
            onClick={(e) => delete_payment_method(e, item.id)}
          >
            Delete
          </a>
        )}
      </div>
      <div className='form-row mt-3'>
        <div className='form-group col-md-12'>
          <label htmlFor='card_name'>Name on card</label>
          <input
            type='text'
            className='form-control'
            id='card_name'
            value={cardData.cardName}
            onChange={(e) =>
              setCardData({ ...cardData, cardName: e.target.values })
            }
          />
        </div>
        <div className='form-group col-md-12'>
          <label htmlFor='card_number'>Card Number</label>
          <div className='input-group mb-3'>
            <input
              type='text'
              className='form-control'
              aria-label='cardNumber'
              aria-describedby='cardNumber'
              id='card_number'
              placeholder={'**** **** **** ' + item.last4}
              disabled
            />
            <div className='input-group-append'>
              <span className='input-group-text border-0' id='cardNumber'>
                {item.brand === 'visa' ? (
                  <img
                    className='align-baseline'
                    src={Visa}
                    alt='visa'
                    width='30'
                  />
                ) : item.brand === 'mastercard' ? (
                  <img
                    className='align-baseline'
                    src={MasterCard}
                    alt='master-card'
                    width='30'
                  />
                ) : null}
              </span>
            </div>
          </div>
        </div>
        <div className='form-group col-md-12'>
          <label htmlFor='exp_date'>Expiration date</label>
       
          <input
            type='text'
            className='form-control'
            id='exp_date'
            placeholder={cardData.cardExpiry}
            maxLength='7'
            
          />
        </div>
        
        <div className='form-group col-md-12'>
          <label htmlFor='post_code'>Postal Code</label>
          <input
            type='text'
            className='form-control'
            id='post_code'
            value={cardData.cardPostal}
            onChange={(e) =>
              setCardData({ ...cardData, cardPostal: e.target.value })
            }
          />
        </div>
        <button
          type='submit'
          className='btn btn-yellow w-100 mt-4'
          style={{ pointerEvents: 'none' }}
        >
          Save
        </button>
      </div>
    </div>
  );
}

CardUpdateInfo.propTypes = {
  setPaymentDefault: PropTypes.func.isRequired,
  deletePaymentMethod: PropTypes.func.isRequired,
};

export default connect(null, { setPaymentDefault, deletePaymentMethod })(
  CardUpdateInfo
);
