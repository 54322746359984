import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Modal } from 'react-bootstrap';
import { loadStripe } from '@stripe/stripe-js';
import {
  CardElement,
  Elements,
  useStripe,
  useElements,
  CardNumberElement,
  CardCvcElement,
  CardExpiryElement,
} from '@stripe/react-stripe-js';
import Noty from 'noty';
import { subscribedUser } from '../../actions/auth';
import { connect } from 'react-redux';
import { STRIPE_PUBLISH_KEY } from '../../actions/types';

function SubscriptionModal({
  showSubscriptionModal,
  setSubscriptionModal,
  setSuccessMessageModal,
  data,
  setData,
  subscribedUser,
  auth: { userDetail },
  cardData,
  setCardData,
}) {
  const [hello, sethello] = useState('monthly');

  useEffect(() => {
    if (showSubscriptionModal) {
      setData({
        ...data,
        email: userDetail !== null ? userDetail.email : '',
      });
    }
  }, [showSubscriptionModal]);

  const packageChange = (e) => {
    // console.log(e.target.value);
    sethello(e.target.value);
  };

  const stripe = loadStripe(STRIPE_PUBLISH_KEY);

  //   const stripe = loadStripe("pk_test_34545t645645");

  //   const successMessage = (e) => {
  //     e.preventDefault();
  //     console.log("method me aya");
  //     setData({
  //       ...data,
  //       successTitle: "You have successfully changed your subscription plan.",
  //       successMessage:
  //         "Yearly SWISS Subscription  $99.00/Year  Your next billing date is February 17, 2021.",
  //     });
  //     setManagePaymentModal(false);
  //     setSuccessMessageModal(true);
  //   };

  return (
    <Modal
      show={showSubscriptionModal}
      onHide={() => setSubscriptionModal(false)}
      size='xl'
      aria-labelledby='contained-modal-title-vcenter'
      centered
      // contentClassName="resend-modal-content"
      backdrop='static'>
      <Modal.Header closeButton className='border-bottom-0'></Modal.Header>

      <Modal.Body style={{ height: '700px' }}>
        <div className='container-fluid'>
          <div className='row'>
            <div className='col-md-4'></div>
            <div className='col-md-4'>
              <div className='row '>
                <div className='col-md-12'>
                  <div className='text-dark' style={{ marginTop: '12px' }}>
                    <h4 className='main-heading text-dark'>
                      Start Watching Today
                    </h4>
                  </div>
                </div>

                <div className='col-md-12'>
                  <div
                    className='text-dark position-relative'
                    style={{ marginTop: '22px' }}>
                    <p className='main-heading-paragraph text-dark'>
                      The details below will be used as billing information for
                      your LOOP subscription.{' '}
                    </p>
                  </div>
                </div>

                <div className='col-md-12' style={{ marginTop: '35px' }}>
                  <div className='row'>
                    <div
                      className='col-md-6'
                      // style={{ paddingRight: '0' }}
                    >
                      <div
                        className='custom-control custom-checkbox custom-control-checkbox-text'
                        style={
                          hello === 'monthly'
                            ? { border: '0.5px solid rgb(235 235 235 / 43%)' }
                            : null
                        }>
                        <input
                          type='radio'
                          className='custom-control-input custom-control-input-round'
                          id='customCheck'
                          //   checked={true}
                          //   checked={field.input.value}
                          name='example1'
                          value='monthly'
                          //   ref={myRef}
                          defaultChecked
                          onChange={(e) => packageChange(e)}
                        />
                        <label
                          className='custom-control-label custom-control-label-round'
                          htmlFor='customCheck'>
                          <span>
                            Monthly <br /> USD 9.99
                          </span>
                        </label>
                      </div>
                    </div>

                    <div
                      className='col-md-6'
                      // style={{ paddingRight: '0', paddingLeft: '0' }}
                    >
                      <div
                        className='custom-control custom-checkbox custom-control-checkbox-text'
                        style={
                          hello === 'yearly'
                            ? { border: '0.5px solid rgb(235 235 235 / 43%)' }
                            : null
                        }>
                        <input
                          type='radio'
                          className='custom-control-input custom-control-input-round'
                          id='customCheck1'
                          name='example1'
                          value='yearly'
                          onChange={(e) => packageChange(e)}
                          //   ref={myRef}
                        />
                        <label
                          className='custom-control-label custom-control-label-round'
                          htmlFor='customCheck1'>
                          <span>
                            Yearly <br /> USD 99.00
                          </span>
                        </label>
                      </div>
                    </div>
                  </div>
                </div>

                <div className='col-md-12'>
                  <div className='text-white' style={{ marginTop: '28px' }}>
                    <div
                      className='position-relative w-100'
                      style={{ height: '39px' }}>
                      <Elements stripe={stripe}>
                        <CheckoutForm
                          data={data}
                          hello={hello}
                          subscribedUser={subscribedUser}
                          setSubscriptionModal={setSubscriptionModal}
                          setSuccessMessageModal={setSuccessMessageModal}
                          setData={setData}
                          cardData={cardData}
                          setCardData={setCardData}
                        />
                      </Elements>
                      {/* <input
              type="email"
              className="email-input"
              style={{
                width: "100%",
                height: "39px",
                border: "solid 1px #353535",
                backgroundColor: "#353535",
                padding: "9px 15px 8px 15px",
                borderRadius: "3px",
                color: "white",
                fontSize: "15px",
                fontWeight: "300",
                fontFamily: "AvenirLight",
              }}
              value={data.email}
              placeholder="Email Address"
              onChange={(e) =>
                setData({ ...data, [e.target.type]: e.target.value })
              }
              required="required"
            /> */}
                    </div>
                    {/* <InputField
                type='email'
                placeholder='Email Address'
                withIcon={false}
              /> */}
                  </div>
                </div>
                <div className='col-md-12'>
                  {/* <div
          className="next-button"
            onClick={(e) => changeStep(e, 1.1)}
            style={
              /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(
                data.email
              ) === false
                ? { pointerEvents: "none", backgroundColor: "#f1b300b0" }
                : null
            }
        >
          <h4>SUBSCRIBE</h4>
        </div> */}
                </div>
              </div>
            </div>
            <div className='col-md-4'></div>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
}

function CheckoutForm({
  data,
  hello,
  subscribedUser,
  setSubscriptionModal,
  setSuccessMessageModal,
  setData,
  cardData,
  setCardData,
}) {
  const [isPaymentLoading, setPaymentLoading] = useState(false);
  const stripe = useStripe();
  const elements = useElements();

  const payMoney = async (e) => {
    e.preventDefault();
    if (!stripe || !elements) {
      return;
    }
    setPaymentLoading(true);
    // const clientSecret =
    //   'sk_test_51IJbzrCxX5t2AMannkJqS4payhkMytj0OYI4ljFJxdrBFYGLzNMdKvXNdueJJq8wGm8H1Nzv3RVf287euZEMAMrK008WGbjNkM'; //getClientSecret();
    const { error, paymentMethod } = await stripe.createPaymentMethod({
      type: 'card',
      card: elements.getElement(
        CardNumberElement,
        CardExpiryElement,
        CardCvcElement
      ),
      billing_details: {
        name: cardData.cardName,
        email: data.email,
        address: {
          postal_code: cardData.cardPostal,
        },
      },
    });
    // setPaymentLoading(false);

    if (error) {
      console.log('[error]', error);

      new Noty({
        type: 'error',
        layout: 'bottomCenter',
        text: error.message,
        timeout: 300000,
      }).show();
      setPaymentLoading(false);
    } else {
      //   console.log('[PaymentMethod]', paymentMethod);
      //   console.log('mydata: ', {
      //     email: data.email,
      //     id: paymentMethod.id,
      //     plan: hello,
      //   });

      let sendData = new FormData();

      sendData.append('email', data.email);
      sendData.append('id', paymentMethod.id);
      sendData.append('plan', hello);
      const res = await subscribedUser(sendData, 'logedin');

      if (res) {
        setData({
          ...data,
          successTitle: 'Your account has been successfully subscribed!',
          successMessage: 'Thank You',
          // successTitle: '',
          // successMessage: '',
          email: '',
          cardName: '',
        });
        setSubscriptionModal(false);
        setSuccessMessageModal(true);
        setPaymentLoading(false);
        // console.log(true);
      } else {
        setPaymentLoading(false);
      }

      //   await callAPI(sendData);
    }
    // if (paymentResult.error) {
    //   alert(paymentResult.error.message);
    // } else {
    //   if (paymentResult.paymentIntent.status === 'succeeded') {
    //     alert('Success!');
    //   }
    // }
  };

  //   const callAPI = async (sendData) => {
  //     e.preventDefault();

  //     const res = await subscribeUser(sendData);

  //     if (res) {
  //       setPaymentLoading(false);
  //       console.log(true);
  //     }
  //   };

  return (
    <div
      style={{
        padding: '0rem',
      }}>
      <div
        style={{
          maxWidth: '100%',
          margin: '0 auto',
        }}>
        <form
          style={{
            display: 'block',
            width: '100%',
          }}
          onSubmit={payMoney}>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}>
            <p className='card-label text-dark'>NAME ON CARD </p>
            <div
              className='position-relative w-100'
              style={{ height: '39px', marginBottom: '8px' }}>
              <input
                type='text'
                className='email-input'
                style={{
                  width: '100%',
                  height: '39px',
                  border: 'solid 1px rgb(235 235 235 / 43%)',
                  backgroundColor: '#ffffff',
                  padding: '9px 15px 8px 15px',
                  borderRadius: '3px',
                  color: '#1d3246',
                  fontSize: '16px',
                  fontWeight: '300',
                  fontFamily: 'AvenirLight',
                }}
                value={cardData.cardName}
                onChange={(e) =>
                  setCardData({ ...cardData, cardName: e.target.value })
                }
              />
            </div>

            {/* <CardElement /> */}

            <p className='card-label text-dark'>CARD NUMBER </p>
            <CardNumberElement
              className='card-number'
              //   showIcon={true}
              options={{
                style: {
                  base: {
                    color: '#1d3246',
                  },
                },
                showIcon: true,
              }}
            />
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                width: '100%',
                justifyContent: 'space-between',
                marginTop: '8px',
              }}>
              <div style={{ width: '45%', marginBottom: '8px' }}>
                <p className='card-label text-dark'>EXPIRATION DATE </p>
                <CardExpiryElement
                  className='card-number'
                  //   onChange={(e) => console.log(e)}
                  options={{
                    style: {
                      base: {
                        color: '#1d3246',
                        marginBottom: '5px',
                      },
                    },
                  }}
                />
              </div>

              <div style={{ width: '45%' }}>
                <p className='card-label text-dark'>SECURITY CODE </p>
                <CardCvcElement
                  className='card-number'
                  options={{
                    style: {
                      base: {
                        color: '#1d3246',
                        marginBottom: '5px',
                      },
                    },
                  }}
                />
              </div>
            </div>

            <p className='card-label text-dark'>POSTAL CODE </p>
            {/* <CardNumberElement
                className="card-number"
                options={{
                  style: {
                    base: {
                      color: "gray",
                    },
                  },
                }}
              /> */}
            <div className='position-relative w-100' style={{ height: '39px' }}>
              <input
                type='text'
                className='email-input'
                style={{
                  width: '100%',
                  height: '39px',
                  // boxShadow: '0 2px 4px 0 #353535',
                  border: 'solid 1px rgb(235 235 235 / 43%)',
                  backgroundColor: '#ffffff',
                  padding: '9px 15px 8px 15px',
                  borderRadius: '3px',
                  color: '#1d3246',
                  fontSize: '16px',
                  fontWeight: '300',
                  fontFamily: 'AvenirLight',
                }}
                value={cardData.cardPostal}
                onChange={(e) =>
                  setCardData({ ...cardData, cardPostal: e.target.value })
                }
              />
            </div>

            {/* <CardNumberElement
                options={{
                  style: { base: { backgroundColor: "red", width: "100px" } },
                }}
              /> */}
            <div
              className='next-button-pay'
              //   disabled={isPaymentLoading}
              onClick={payMoney}>
              {isPaymentLoading ? 'LOADING...' : 'SUBSCRIBE'}
            </div>
          </div>
        </form>
      </div>
    </div>
  );
}

SubscriptionModal.propTypes = {
  subscribedUser: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps, { subscribedUser })(SubscriptionModal);
