import { combineReducers } from 'redux';
// import toggling from './toggling';
import auth from './auth';
import courseReducer from './courseReducer';
import supportReducer from './supportReducer';
import modalReducer from './modal';
// import courses from './courses';

export default combineReducers({
  auth,
  course: courseReducer,
  support: supportReducer,
  modal: modalReducer,
});
