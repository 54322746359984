import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import '../../assets/css/topbar.css';

function TopBar(props) {
  let location = useLocation();
  return (
    <div className='container-fluid'>
      <div className='row'>
        <div className='col-md-12'>
          <div className='log-in text-right'>
            {location.pathname === '/' ? (
              <Link to='/register'>Register</Link>
            ) : (
              <Link to='/'>Login</Link>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

TopBar.propTypes = {};

export default TopBar;
