import React, { useEffect } from 'react';
import { Row, Col, Button, Modal, Tabs, Tab } from 'react-bootstrap';
import { LinkContainer } from 'react-router-bootstrap';
import { withRouter } from 'react-router';
import Icofont from 'react-icofont';

import { connect } from 'react-redux';

import { streamModal } from '../../actions/modal';

function StreamingModal({
  modal: { streamingModalState, streamingModalURL },
  auth: { userDetail },
  streamModal,
  streamURL,
}) {
  const closeModal = () => {
    streamModal(false);
  };
  useEffect(() => {
    if (streamingModalState) {
      document
        .getElementById('customStreamingModaliFrame')
        .contentWindow.location.reload(true);
    }
  }, [streamingModalState]);
  return (
    <Modal
      id='customVideoContentModal'
      size='xl'
      aria-labelledby='contained-modal-title-vcenter'
      centered
      backdrop='static'
      show={streamingModalState}
      style={{ height: '100%' }}
      onHide={closeModal}
      // className={
      //   true
      //     ? 'animate__animated animate__zoomIn'
      //     : 'animate__animated animate__zoomOut'
      // }
    >
      {userDetail?.email === 'sahiladeem@getloop.us' ? (
        <Modal.Body className='p-0'>
          <div
            className='d-flex position-absolute float-right flex-column close'
            style={{ zIndex: '999' }}>
            {/* <Icofont
            icon='close-circled'
            // size='1'
            onClick={() => streamModal(streamingModalState)}
            style={{ color: 'white', cursor: 'pointer', fontSize: '2rem' }}
            className='textShadow'
          /> */}
            <Button onClick={() => closeModal()} className='btn btn-danger'>
              End Session
            </Button>
          </div>
          <div className='d-flex justify-content-center align-items-center'>
            <iframe
              className='w-100'
              style={{ height: '50rem' }}
              src='https://stream.getloop.us/broadcast.html'
              title='Broadcast Stream'
              allow='microphone;camera'
              // allow-same-origin='allow-same-origin'
              // allowusermedia='allowusermedia'
            ></iframe>
          </div>
        </Modal.Body>
      ) : (
        <Modal.Body className='p-0'>
          <div
            className='d-flex position-absolute float-right flex-column close'
            style={{ zIndex: '999' }}>
            {/* <Icofont
            icon='close-circled'
            // size='1'
            onClick={() => streamModal(streamingModalState)}
            style={{ color: 'white', cursor: 'pointer', fontSize: '2rem' }}
            className='textShadow'
          /> */}
            <Button onClick={() => closeModal()} className='btn btn-light'>
              Leave Session
            </Button>
          </div>
          <div className='d-flex justify-content-center align-items-center'>
            <iframe
              className='w-100'
              style={{ height: '50rem' }}
              src={streamingModalURL}
              title='Watch Stream'
              id='customStreamingModaliFrame'></iframe>
          </div>
        </Modal.Body>
      )}
    </Modal>
  );
}

const mapStateToProps = (state) => ({
  auth: state.auth,
  modal: state.modal,
});

export default connect(mapStateToProps, {
  streamModal,
})(withRouter(StreamingModal));
