import React, { useEffect, Fragment, useState } from 'react';
import Navbar from './components/layouts/Navbar';
import TopBar from './components/layouts/TopBar';
import TopBarAfterLogin from './components/layouts/TopBarAfterLogin';
import Footer from './components/layouts/Footer';
import setAuthToken from './utils/setAuthToken';
import { HashRouter as Router, Switch, Route } from 'react-router-dom';
import PrivateRoute from './components/routing/PrivateRoute';
import { loadUser } from './actions/auth';
import CreateAccount from './components/pre-login/CreateAccount';
import ErrorPage from './components/layouts/ErrorPage';
import Login from './components/pre-login/Login';
import MyAccount from './components/post-login/MyAccount';
import CreateNewPassword from './components/pre-login/CreateNewPassword';
import ForgotPassword from './components/pre-login/ForgotPassword';
import Home from './components/post-login/Home';
import NavbarHome from './components/layouts/NavbarHome';
import CourseDetail from './components/post-login/CourseDetail';
import Favourites from './components/post-login/Favourites';
import MobileView from './components/layouts/MobileView';
import StreamingModal from './components/modals/StreamingModal';

// Redux

import { Provider } from 'react-redux';
import store from './store';

if (localStorage.token) {
  setAuthToken(localStorage.token);
  // console.log('testing jenkins 5');
}
function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height,
  };
}
function useWindowDimensions() {
  const [windowDimensions, setWindowDimensions] = useState(
    getWindowDimensions()
  );

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return windowDimensions;
}

function App() {
  // const { height, width } = useWindowDimensions();
  useEffect(() => {
    store.dispatch(loadUser());
  }, []);

  // useEffect(() => {
  //   console.log(width);
  // }, [width]);

  const LoginContainer = () => (
    // width > 768 ? (
    <Fragment>
      <div className='preLogin-background'>
        <TopBar />
        <Navbar />
        <Route exact path='/register' component={CreateAccount}></Route>
        <Route exact path='/' component={Login}></Route>
        <Route exact path='/forgot-password' component={ForgotPassword}></Route>
        <Route
          exact
          path='/create-new-password/:id'
          component={CreateNewPassword}></Route>
      </div>
    </Fragment>
  );
  // ) : (
  //   <MobileView />
  // );

  const DefaultContainer = () => (
    // width > 768 ? (
    <div className='swiss'>
      <TopBarAfterLogin />
      <PrivateRoute
        exact
        path='/my-account'
        component={MyAccount}></PrivateRoute>
      <Footer />

      {/* <PrivateRoute exact path="/detail/:id" component={DetailCourse} /> */}
    </div>
  );
  // ) : (
  //   <MobileView />
  // );

  const ErrorPageContainer = () => (
    <Fragment>
      <TopBar />
      <Navbar />
      <Route component={ErrorPage}></Route>
    </Fragment>
  );

  const HomeContainer = () => (
    // width > 768 ? (
    <div className='swiss'>
      <NavbarHome positionFixed={true} />
      <PrivateRoute exact path='/home' component={Home}></PrivateRoute>
      <Footer />
      <StreamingModal />
    </div>
  );
  // ) : (
  //   <MobileView />
  // );
  const CourseDetailContainer = () => (
    // width > 768 ? (
    <div className='swiss'>
      <NavbarHome positionFixed={false} />
      <PrivateRoute
        exact
        path='/course-detail/:id'
        component={CourseDetail}></PrivateRoute>
      <Footer />
      <StreamingModal />
    </div>
  );
  // ) : (
  //   <MobileView />
  // );
  const FavouritesContainer = () => (
    // width > 768 ? (
    <div className='swiss'>
      <NavbarHome positionFixed={false} />
      <PrivateRoute
        exact
        path='/favourites'
        component={Favourites}></PrivateRoute>
      <Footer />
      <StreamingModal />
    </div>
  );
  // ) : (
  //   <MobileView />
  // );

  return (
    <Provider store={store}>
      <Router>
        <Fragment>
          <Switch>
            <Route exact path='/register' component={LoginContainer}></Route>
            <Route exact path='/' component={LoginContainer}></Route>
            <Route
              exact
              path='/forgot-password'
              component={LoginContainer}></Route>
            <Route
              exact
              path='/create-new-password/:id'
              component={LoginContainer}></Route>
            <Route
              exact
              path='/my-account'
              component={DefaultContainer}></Route>
            <Route exact path='/home' component={HomeContainer}></Route>
            <Route
              exact
              path='/course-detail/:id'
              component={CourseDetailContainer}></Route>
            <Route
              exact
              path='/favourites'
              component={FavouritesContainer}></Route>
            <Route component={ErrorPageContainer}></Route>
          </Switch>
        </Fragment>
      </Router>
    </Provider>
  );
}

export default App;
