import {
  LOGIN_API_ENDPOINT,
  USER_LOADED,
  AUTH_ERROR,
  LOGIN_SUCCESS,
  LOG_OUT,
  UPDATE_PROFILE,
  SUBSCRIBE_USER,
  GET_BILLING_HISTORY,
  GET_PAYMENT_METHODS,
  DELETE_PAYMENT_METHOD,
  CLASSES_STREAMING_LIST,
} from './types';
import axios from 'axios';
import setAuthToken from '../utils/setAuthToken';
import Noty from 'noty';

// Load User

export const loadUser = () => async (dispatch) => {
  if (localStorage.token) {
    setAuthToken(localStorage.token);
  }

  try {
    const res = await axios.get(`${LOGIN_API_ENDPOINT}/getUser/`);
    // console.log('USER--->>>', res);
    dispatch({
      type: USER_LOADED,
      payload: res.data,
    });
  } catch (error) {
    console.log('user loaded error: ', error);

    if (error.response.status === 401) {
      dispatch({
        type: AUTH_ERROR,
      });
    }
  }
};

// Send Email Verification Code

export const sendEmailVerificationCode =
  (email, first_name, last_name) => async (dispatch) => {
    try {
      const res = await axios.get(
        `${LOGIN_API_ENDPOINT}/email_verify/?email=${email}&first_name=${first_name}&last_name=${last_name}`
      );

      // console.log('email verify res', res);

      return true;
    } catch (err) {
      console.log(err);

      new Noty({
        type: 'error',
        layout: 'bottomCenter',
        text: err.response.data.description,
        timeout: 2000,
      }).show();
    }
  };

// Verify Code

export const verifyCode = (data, check) => async (dispatch) => {
  try {
    const res = await axios.post(`${LOGIN_API_ENDPOINT}/email_verify/`, data);

    // console.log('email verify res', res);

    if (check === 'update') {
      let updateEmail = new FormData();

      updateEmail.append('id', localStorage.getItem('id'));
      updateEmail.append('email', data.get('email'));

      await dispatch(profileUpdate(updateEmail));
    }

    return true;
  } catch (err) {
    console.log(err);

    new Noty({
      type: 'error',
      layout: 'bottomCenter',
      text: err.response.data.description,
      timeout: 2000,
    }).show();
  }
};

// Create User

export const createUser = (data) => async (dispatch) => {
  try {
    const res = await axios.post(`${LOGIN_API_ENDPOINT}/signup/`, data);

    // console.log('signup res', res);
    await dispatch(loginUser(data));
    return true;
  } catch (err) {
    console.log(err);
    new Noty({
      type: 'error',
      layout: 'bottomCenter',
      text: err.response.data.description,
      timeout: 2000,
    }).show();
  }
};

// Login User

export const loginUser = (data) => async (dispatch) => {
  try {
    const res = await axios.post(`${LOGIN_API_ENDPOINT}/login/`, data);

    // console.log('login res', res);

    dispatch({
      type: LOGIN_SUCCESS,
      payload: res.data,
    });

    await dispatch(loadUser());

    return true;
  } catch (err) {
    console.log(err);
    new Noty({
      type: 'error',
      layout: 'bottomCenter',
      text: err.response.data.description,
      timeout: 2000,
    }).show();
  }
};

// Logout User

export const logoutClient = () => async (dispatch) => {
  const config = {
    headers: {
      'Content-Type': 'application/json',
    },
  };

  const body = {
    id: localStorage.getItem('id'),
  };

  try {
    const res = await axios.post(`${LOGIN_API_ENDPOINT}/logout/`, body, config);

    dispatch({
      type: LOG_OUT,
    });

    // console.log('Logout Response', res.data);
  } catch (err) {
    console.log('Logout Error: ', err);

    dispatch({
      type: AUTH_ERROR,
    });
  }
};

// Profile Update info

export const profileUpdate = (data) => async (dispatch) => {
  try {
    const res = await axios.patch(
      `${LOGIN_API_ENDPOINT}/profile/update_info/`,
      data
    );

    // console.log('profile update', res);

    dispatch({
      type: UPDATE_PROFILE,
      payload: res.data.description,
    });

    return true;
  } catch (err) {
    console.log(err);
    new Noty({
      type: 'error',
      layout: 'bottomCenter',
      text: err.response.data.description,
      timeout: 2000,
    }).show();
  }
};

// Update Password

export const profileUpdatePassword = (data) => async (dispatch) => {
  try {
    const res = await axios.patch(
      `${LOGIN_API_ENDPOINT}/profile/update_pass/`,
      data
    );

    // console.log('profile update Password', res);

    return true;
  } catch (err) {
    console.log(err);
    new Noty({
      type: 'error',
      layout: 'bottomCenter',
      text: err.response.data.description,
      timeout: 2000,
    }).show();
  }
};

// Subscribe User

export const subscribedUser = (data, check) => async (dispatch) => {
  try {
    const res = await axios.post(`${LOGIN_API_ENDPOINT}/subscription/`, data);

    // console.log('Subsciption Data', res);

    if (check === 'signup') {
      await dispatch(loadUser());
      return true;
    } else {
      await dispatch(loadUser());

      await dispatch({
        type: SUBSCRIBE_USER,
        payload: true,
      });

      return true;
    }
  } catch (err) {
    console.log(err);
    new Noty({
      type: 'error',
      layout: 'bottomCenter',
      text: err.response.data.description,
      timeout: 2000,
    }).show();
  }
};

// Cancel Subscription

export const cancelSubscription = (data) => async (dispatch) => {
  try {
    const res = await axios.delete(
      `${LOGIN_API_ENDPOINT}/subscription/${localStorage.getItem(
        'id'
      )}/?email=${data}`
    );

    // console.log('Cancel Subsciption Data', res);

    await dispatch({
      type: SUBSCRIBE_USER,
      payload: false,
    });

    return true;
  } catch (err) {
    console.log(err);
    new Noty({
      type: 'error',
      layout: 'bottomCenter',
      text: err.response.data.description,
      timeout: 2000,
    }).show();
  }
};

// Get Billing History

export const billingHistory = (data) => async (dispatch) => {
  try {
    const res = await axios.get(
      `${LOGIN_API_ENDPOINT}/subscription/billing_invoice/?email=${data}`
    );

    // console.log('Billing History Data', res.data);

    await dispatch({
      type: GET_BILLING_HISTORY,
      payload: res.data,
    });

    return true;
  } catch (err) {
    console.log(err);
    new Noty({
      type: 'error',
      layout: 'bottomCenter',
      text: err.response.data.description,
      timeout: 2000,
    }).show();
  }
};

// Get Payment Methods

export const getPaymentMethods = (email) => async (dispatch) => {
  try {
    dispatch({
      type: GET_PAYMENT_METHODS,
      payload: null,
    });

    const res = await axios.get(
      `${LOGIN_API_ENDPOINT}/subscription/payment_methods/?email=${email}`
    );

    const unique = res.data.payment_methods;

    const filteredArr = unique.reduce((acc, current) => {
      const x = acc.find((item) => item.last4 === current.last4);
      if (!x) {
        return acc.concat([current]);
      } else {
        return acc;
      }
    }, []);

    // console.log('Payment Method Data', res.data);

    await dispatch({
      type: GET_PAYMENT_METHODS,
      payload: filteredArr,
    });

    return true;
  } catch (err) {
    console.log(err);
    new Noty({
      type: 'error',
      layout: 'bottomCenter',
      text: err.response.data.description,
      timeout: 2000,
    }).show();
  }
};

// Update Default

export const setPaymentDefault = (data) => async (dispatch) => {
  try {
    dispatch({
      type: GET_PAYMENT_METHODS,
      payload: null,
    });

    const res = await axios.patch(
      `${LOGIN_API_ENDPOINT}/subscription/update_default/`,
      data
    );

    // console.log('Payment Default Data', res.data);

    dispatch(getPaymentMethods(data.get('email')));

    return true;
  } catch (err) {
    console.log(err);
    new Noty({
      type: 'error',
      layout: 'bottomCenter',
      text: err.response.data.description,
      timeout: 2000,
    }).show();
  }
};

// Delete Payment Method

export const deletePaymentMethod = (data) => async (dispatch) => {
  try {
    const res = await axios.delete(
      `${LOGIN_API_ENDPOINT}/subscription/detach_payment/?payment_id=${data}`
    );

    // console.log('Delete Payment Method Data', res);

    await dispatch({
      type: DELETE_PAYMENT_METHOD,
      payload: data,
    });

    return true;
  } catch (err) {
    console.log(err);
    new Noty({
      type: 'error',
      layout: 'bottomCenter',
      text: err.response.data.description,
      timeout: 2000,
    }).show();
  }
};

// Add New Payment Method

export const addPaymentMethod = (data) => async (dispatch) => {
  try {
    const res = await axios.post(
      `${LOGIN_API_ENDPOINT}/subscription/attach_payment/`,
      data
    );

    const unique = res.data.payment_methods;

    const filteredArr = unique.reduce((acc, current) => {
      const x = acc.find((item) => item.last4 === current.last4);
      if (!x) {
        return acc.concat([current]);
      } else {
        return acc;
      }
    }, []);

    // console.log('Create Payment Method Data', res.data);

    await dispatch({
      type: GET_PAYMENT_METHODS,
      payload: filteredArr,
    });

    return true;
  } catch (err) {
    console.log(err);
    new Noty({
      type: 'error',
      layout: 'bottomCenter',
      text: err.response.data.description,
      timeout: 2000,
    }).show();
  }
};

// Set Create Account Data

// export const setAccountData = (data) => async (dispatch) => {
//   try {
//     dispatch({
//       type: SET_ACCOUNT_DATA,
//       payload: data,
//     });
//   } catch (error) {
//     console.log(error);
//   }
// };

//Forget password

export const forgetPassword = (email, history) => async (dispatch) => {
  try {
    let data = new FormData();

    data.append('email', email);

    const res = await axios.post(`${LOGIN_API_ENDPOINT}/forgotPass/`, data);

    new Noty({
      type: 'error',
      layout: 'bottomCenter',
      text: 'Email Sent Successfully',
      timeout: 2000,
    }).show();

    history.push('/');
    return true;
  } catch (err) {
    console.log(err);
    new Noty({
      type: 'error',
      layout: 'bottomCenter',
      text: err.response.data.description,
      timeout: 2000,
    }).show();
  }
};

//Updating password

export const updatePassword = (password, id) => async (dispatch) => {
  try {
    const res = await axios.put(
      `${LOGIN_API_ENDPOINT}/forgotPass/${id}/?newPass=${password}`
    );

    new Noty({
      type: 'error',
      layout: 'bottomCenter',
      text: 'Your Password is Updated Successfully',
      timeout: 2000,
    }).show();

    return true;
  } catch (err) {
    console.log(err);
    new Noty({
      type: 'error',
      layout: 'bottomCenter',
      text: err.response.data.description,
      timeout: 2000,
    }).show();
  }
};

export const getStreamingClassesList = () => async (dispatch) => {
  try {
    const res = await axios.get(`${LOGIN_API_ENDPOINT}/get/class/`);
    // console.log(res, 'favouriteCourses');
    if (res.status === 200) {
      dispatch({
        type: CLASSES_STREAMING_LIST,
        payload: res.data,
      });
    }
  } catch (err) {
    console.log(err);
    new Noty({
      type: 'error',
      layout: 'bottomCenter',
      text: err.response.data.description,
      timeout: 2000,
    }).show();
  }
};
