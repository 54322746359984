import React, { Fragment, useState, useEffect, useLayoutEffect } from 'react';
import PropTypes from 'prop-types';
import {
  Image,
  Row,
  Col,
  ListGroup,
  Badge,
  Card,
  Popover,
  OverlayTrigger,
  Spinner,
  Container,
  Tabs,
  Tab,
  Modal,
  Button,
} from 'react-bootstrap';
import 'animate.css';
import {
  getCourseDetail,
  clearCourseDetail,
  setVideoFavourite,
  setVideoUnFavourite,
  setContentFavourite,
  setContentUnFavourite,
} from '../../actions/courseActions';
import paidContent from '../../assets/images/paidContent.jpg';
import Content from '../../assets/images/Content.jpg';
import Assignment from '../../assets/images/Assignment.jpg';
import Quiz from '../../assets/images/Quiz.jpg';
import Wallet from '../../assets/images/Wallet.png';
import contentPPT from '../../assets/images/contentPPT.jpg';
import contentPDF from '../../assets/images/contentPDF.jpg';
import Icofont from 'react-icofont';
import { Link } from 'react-router-dom';

import { connect } from 'react-redux';

function CourseDetail({
  getCourseDetail,
  clearCourseDetail,
  setVideoFavourite,
  setVideoUnFavourite,
  setContentFavourite,
  setContentUnFavourite,
  match,
  course: { courseDetail },
  auth,
}) {
  const [lessonData, setLessonData] = useState(null);
  const [show, setShow] = useState(false);
  const [modalShow, setModalShow] = useState(false);
  const [videomodalShow, setVideoModalShow] = useState(false);
  const [paidModalShow, setPaidModalShow] = useState(false);
  const [videoLinkHover, setVideoLinkHover] = useState('');
  const [videoLink, setVideoLink] = useState(null);
  const [contentData, setContentData] = useState(null);
  const [modalVideoData, setmodalVideoData] = useState(null);
  const [paidModalTitle, setPaidModalTitle] = useState('');

  const ScrollToTop = () => {
    useLayoutEffect(() => {
      window.scrollTo(0, 0);
    }, []);

    return null;
  };
  ScrollToTop();

  const handleClose = () => {
    setShow(false);
    let videoCurrentTime = document.getElementById('videoPlayer').currentTime;
    if (videomodalShow) {
      setTimeout(() => {
        document.getElementById('modalContentVideoPlayer').currentTime =
          videoCurrentTime;
      }, 100);
    }
  };
  const handleShow = (data, videoCurrentTime) => {
    setVideoLink(data);
    setShow(true);
    // console.log('currentTime===> ', videoCurrentTime);
    if (videoCurrentTime) {
      // let videoData = { ...data, videoCurrentTime };
      setTimeout(() => {
        document.getElementById('videoPlayer').currentTime = videoCurrentTime;
      }, 100);
    }
    // } else {
    //   setVideoLink(data);
    //   setShow(true);
    // }
  };

  const handleModalClose = () => {
    setModalShow(false);
    setContentData(null);
  };
  const handleModalShow = (data) => {
    setModalShow(true);
    setTimeout(() => {
      document.getElementById('quizAssignmentModal').innerHTML = data?.pdffile;
    }, 1000);
  };

  const handleVideoModalClose = () => {
    setVideoModalShow(false);
    setmodalVideoData(null);
  };
  const handleVideoModalShow = (currentLessondata, lessonName, Content) => {
    let modalData = { ...currentLessondata, lessonName, Content };
    setmodalVideoData(modalData);
    setVideoModalShow(true);
  };

  const handlePaidModalClose = () => setPaidModalShow(false);
  const handlePaidModalShow = (title) => {
    setPaidModalTitle(title);
    setPaidModalShow(true);
  };

  // const getSingleCourseDetail = async () => {
  //   let res = await getCourseDetail(match.params.id);
  // };

  const contentModal = (data) => {
    // console.log('Content Data', data);
    setContentData(data);
    handleModalShow(data);
  };
  useEffect(() => {
    getCourseDetail(match.params.id);
    return () => {
      clearCourseDetail();
    };
  }, []);

  // useEffect(() => {
  //   document.addEventListener('contextmenu', (e) => {
  //     e.preventDefault();
  //   });

  //   return () => {};
  // }, []);

  useEffect(() => {
    // if (courseDetail) {
    //   let firstCourseDetail = courseDetail.data[0];
    //   firstCourseDetail.outerIndex = 0;
    //   setLessonData(firstCourseDetail);
    // }

    if (courseDetail) {
      courseDetail.data.map((item) => {
        item.videos.map((video) => {
          if (video.id === 13) {
            // console.log('Modal Open Data: ', video);
            // break;
          }
        });
      });
    }
  }, [courseDetail]);

  const singleLessonData = (data, index) => {
    let newData = { ...data, outerIndex: index };
    // console.log('New Data -->', newData);
    setLessonData(newData);
  };

  const popover = (
    <Popover id='popover-basic'>
      <Popover.Title as='h3'>Paid Course</Popover.Title>
      <Popover.Content>
        You have to <strong>subscribe</strong> to view the lessons content.
      </Popover.Content>
    </Popover>
  );
  return (
    <Fragment>
      <Fragment>
        <section
          className='courseDetailBanner pt-5 pb-5 mt-0 align-items-center d-flex position-relative'
          style={
            courseDetail !== null
              ? {
                  // backgroundImage: `url(${courseDetail.banner_image})`,
                  backgroundImage: `url(${courseDetail.banner_image.replace(
                    'getloopstream',
                    'getloopstream'
                  )})`,
                  boxShadow: '0 .5rem 1rem rgba(0,0,0,.15)',
                  backgroundPosition: 'center',
                }
              : null
          }>
          <div
            className='overlay'
            style={{
              width: '100%',
              height: '100%',
              position: 'absolute',
              top: 0,
              background: '#000000a8',
            }}></div>
          <div className='container'>
            {
              courseDetail !== null ? (
                <div className='row align-items-center d-flex justify-content-between'>
                  <div className='col-12 col-md-6 pb-5  '>
                    <h1 className='textShadow text-white font-weight-bold mb-3 mt-5 display-4'>
                      {courseDetail.course_name}
                    </h1>
                  </div>
                  <div className='col-12 col-md-6 pb-5'>
                    <p className='textShadow lead text-white'>
                      {courseDetail.description}
                    </p>
                  </div>
                </div>
              ) : null
              // <Fragment>
              //   <div className='d-flex justify-content-center my-5'>
              //     <Spinner
              //       as='span'
              //       animation='border'
              //       size='lg'
              //       role='status'
              //       aria-hidden='true'
              //     />
              //   </div>
              // </Fragment>
            }
          </div>
        </section>
        <section>
          <div className='container-fluid py-2 my-4'>
            <Row>
              <Col
                xl={12}
                style={{
                  minHeight: '55vh',
                }}>
                {courseDetail !== null ? (
                  <Fragment>
                    <Container>
                      {/* <h3 className='font-weight-bolder text-uppercase'>
                          <u>{lessonData.lessonName}</u>
                        </h3> */}
                      <Row>
                        {courseDetail?.data.map((lessonData, outerIndex) =>
                          lessonData.videos.map((currentLesson, innerIndex) => (
                            <Col xl={4} className='my-1' key={innerIndex}>
                              <Card
                                // bg='dark'
                                text='white'
                                style={{ width: '100%', height: '100%' }}
                                className='customContentCard'>
                                <Card.Header
                                  className='d-flex justify-content-center'
                                  style={{
                                    height: '250px',
                                    cursor: 'pointer',
                                    backgroundColor: 'black',
                                  }}
                                  onClick={() => {
                                    if (currentLesson.cloudflare_uid) {
                                      // console.log(currentLesson.cloudflare_uid);
                                      handleShow(currentLesson);
                                    }
                                  }}>
                                  {currentLesson.cloudflare_uid ? (
                                    <video
                                      controlsList='nodownload'
                                      onContextMenu={(e) => e.preventDefault()}
                                      loop
                                      width='100%'
                                      height='100%'
                                      onMouseEnter={(event) => {
                                        event.target.play();
                                      }}
                                      onMouseLeave={(event) => {
                                        event.target.pause();
                                        event.target.currentTime = 0;
                                      }}
                                      style={{
                                        border: 'none',
                                        borderRadius: '5px',
                                      }}
                                      // src={
                                      //   currentLesson.cloudflare_preview
                                      // }
                                      src={currentLesson.cloudflare_preview.replace(
                                        'getloopstream',
                                        'getloopstream'
                                      )}></video>
                                  ) : (
                                    <Image
                                      src={paidContent}
                                      className='img-fluid objectCover'
                                      onClick={() =>
                                        handlePaidModalShow(
                                          currentLesson.video_title
                                        )
                                      }
                                    />
                                  )}
                                </Card.Header>
                                <Card.Body className='p-3'>
                                  <Card.Title className='m-0 d-flex justify-content-between h-100'>
                                    <div className='align-items-center card_title'>
                                      <a
                                        className='m-0'
                                        onClick={() => {
                                          if (currentLesson.cloudflare_uid) {
                                            // console.log(
                                            //   currentLesson.cloudflare_uid
                                            // );
                                            handleVideoModalShow(
                                              currentLesson,
                                              lessonData.lessonName,
                                              lessonData.content
                                            );
                                          }
                                        }}
                                        style={{ cursor: 'pointer' }}>
                                        {currentLesson.video_title}
                                      </a>
                                    </div>
                                    <div>
                                      {currentLesson.cloudflare_uid ? (
                                        currentLesson.favourite ? (
                                          <Icofont
                                            icon='heart'
                                            size='1'
                                            className='heartIconYellow'
                                            onClick={() =>
                                              setVideoUnFavourite(
                                                currentLesson,
                                                lessonData.lessonId,
                                                outerIndex,
                                                innerIndex
                                              )
                                            }
                                            style={{ cursor: 'pointer' }}
                                          />
                                        ) : (
                                          <Icofont
                                            icon='heart'
                                            size='1'
                                            className='heartIcon'
                                            onClick={() =>
                                              setVideoFavourite(
                                                currentLesson,
                                                lessonData.lessonId,
                                                outerIndex,
                                                innerIndex
                                              )
                                            }
                                            style={{ cursor: 'pointer' }}
                                          />
                                        )
                                      ) : null}
                                      <Icofont
                                        icon='stylish-down'
                                        size='1'
                                        className='heartIcon'
                                        onClick={() =>
                                          handleVideoModalShow(
                                            currentLesson,
                                            lessonData.lessonName,
                                            lessonData.content
                                          )
                                        }
                                        style={{ cursor: 'pointer' }}
                                      />
                                    </div>
                                  </Card.Title>
                                </Card.Body>
                              </Card>
                            </Col>
                          ))
                        )}
                      </Row>
                    </Container>
                  </Fragment>
                ) : (
                  <Fragment>
                    <div className='d-flex justify-content-center my-5 d-none'>
                      <Spinner
                        as='span'
                        animation='border'
                        size='lg'
                        role='status'
                        aria-hidden='true'
                      />
                    </div>
                  </Fragment>
                )}
              </Col>
            </Row>
          </div>
        </section>
      </Fragment>
      <Modal
        show={show}
        size='lg'
        id='customVideoModal'
        onHide={handleClose}
        animation={true}
        dialogClassName='modal-100w'
        className={
          show
            ? 'animate__animated animate__zoomIn'
            : 'animate__animated animate__zoomOut'
        }>
        <Modal.Body
          className='p-0'
          style={{
            border: 'none',
            // objectFit: 'cover',
            width: '100%',
            height: '100%',
            overflow: 'hidden',
            position: 'absolute',
          }}>
          <div
            className='d-flex position-absolute float-right flex-column close'
            style={{ zIndex: '999' }}>
            <Icofont
              icon='close-circled'
              size='2'
              onClick={handleClose}
              style={{ color: 'white', cursor: 'pointer' }}
              className='textShadow'
            />
            {/* <Icofont icon='heart' size='2' className='heartIcon' /> */}
            {/* {lessonData?.paid === '0' ? (
              videoLink?.favourite ? (
                <Icofont
                  icon='heart'
                  size='2'
                  className='heartIconYellow'
                  onClick={() =>
                    setVideoUnFavourite(videoLink, lessonData.lessonId)
                  }
                />
              ) : (
                <Icofont
                  icon='heart'
                  size='2'
                  className='heartIcon'
                  onClick={() =>
                    setVideoFavourite(videoLink, lessonData.lessonId)
                  }
                />
              )
            ) : null} */}
          </div>
          {videoLink ? (
            <video
              controlsList='nodownload'
              onContextMenu={(e) => e.preventDefault()}
              // class=''
              id='videoPlayer'
              loop
              controls
              width='100%'
              height='100%'
              // muted
              autoPlay='autoplay'
              autobuffer
              style={{
                position: 'absolute',
                backgroundColor: '#000000',
                // border: 'none',
                // objectFit: 'cover',
                // // width: '100%',
                // // height: '100%',
                // overflow: 'hidden',
              }}>
              <source
                // src={videoLink?.cloudflare_preview}
                src={videoLink?.cloudflare_preview.replace(
                  'getloopstream',
                  'getloopstream'
                )}
                // type='video/mp4'
              />
            </video>
          ) : null}
          {/* <iframe
            src={`${videoLink?.cloudflare_preview}?autoPlay=true`}
            title={'test'}
            width='100%'
            height='100%'
            allow='accelerometer; gyroscope; autoplay; encrypted-media; picture-in-picture;'
            autoPlay={true}
            allowFullScreen=''
            style={{
              border: 'none',
            }}></iframe> */}
        </Modal.Body>
      </Modal>

      <Modal
        id='customVideoContentModal'
        size='lg'
        aria-labelledby='contained-modal-title-vcenter'
        centered
        show={videomodalShow}
        onHide={handleVideoModalClose}
        className={
          videomodalShow
            ? 'animate__animated animate__zoomIn'
            : 'animate__animated animate__zoomOut'
        }>
        {/* <Modal.Header closeButton>
          <Modal.Title id='contained-modal-title-center' className=''>
            <span className='d-flex align-items-center'>
              <b
                className='mr-1 pr-1'
                style={{ borderRight: '1px solid #c1c1c1' }}>
                {courseDetail?.course_name}
              </b>
              <i>{modalVideoData?.video_title}</i>
            </span>
          </Modal.Title>
        </Modal.Header> */}
        <Modal.Body className='p-0'>
          <div
            className='d-flex position-absolute float-right flex-column close'
            style={{ zIndex: '999' }}>
            <Icofont
              icon='close-circled'
              // size='1'
              onClick={handleVideoModalClose}
              style={{ color: 'white', cursor: 'pointer', fontSize: '2rem' }}
              className='textShadow'
            />
          </div>
          <div className=''>
            <div
              style={{
                border: 'none',
                // objectFit: 'cover',
                width: '100%',
                height: '100%',
                overflow: 'hidden',
                // position: 'absolute',
              }}>
              <video
                controlsList='nodownload'
                onContextMenu={(e) => e.preventDefault()}
                id='modalContentVideoPlayer'
                loop
                width='100%'
                height='100%'
                onMouseEnter={(event) => {
                  event.target.play();
                }}
                // onMouseLeave={(event) => {
                //   event.target.pause();
                //   event.target.currentTime = 0;
                // }}
                // preload='auto'
                onClick={(event) => {
                  if (modalVideoData?.cloudflare_uid) {
                    // console.log(modalVideoData?.cloudflare_uid);

                    handleShow(modalVideoData, event.target.currentTime);
                    event.target.pause();
                  }
                }}
                autoPlay='autoplay'
                style={{
                  border: 'none',
                  // position: 'absolute',
                  backgroundColor: '#000000',
                  // borderRadius: '5px',
                  cursor: 'pointer',
                  boxShadow: '0 .5rem 1rem rgba(0,0,0,.15)',
                }}
                // id='videoModalContent'
                // src={modalVideoData?.cloudflare_preview}
                src={modalVideoData?.cloudflare_preview.replace(
                  'getloopstream',
                  'getloopstream'
                )}></video>
            </div>
            {/* <hr /> */}
            <div
              id='VideoContentModal'
              className='p-3'
              style={{
                height: '30vh',
                minHeight: '250px',
                overflow: 'auto',
                // position: 'absolute',
              }}>
              <h5 className='font-weight-bold'>
                {modalVideoData?.video_title}
              </h5>
              <p>{modalVideoData?.video_description}</p>
              <hr />
              {/* <h5 className='font-weight-bold'>Quizzes</h5>
              <p>No Content Available</p>
              <hr />
              <h5 className='font-weight-bold'>Assignments</h5>
              <p>No Content Available</p>
              <hr /> */}
              <h5 className='font-weight-bold'>Content</h5>
              {modalVideoData?.Content.length !== 0 ? (
                modalVideoData?.Content.map((item, index) => (
                  <a href={item?.pdffile} key={index} target='_blank'>
                    {index + 1}. {item?.title}
                  </a>
                ))
              ) : (
                <p>No Content Available</p>
              )}
            </div>
            {/* <h6>{document.getElementById('videoModalContent')?.duration}</h6> */}
          </div>
        </Modal.Body>
      </Modal>

      <Modal
        id='customContentModal'
        size='lg'
        aria-labelledby='contained-modal-title-vcenter'
        centered
        show={modalShow}
        onHide={handleModalClose}
        className={
          modalShow
            ? 'animate__animated animate__zoomIn'
            : 'animate__animated animate__zoomOut'
        }>
        <Modal.Header closeButton>
          <Modal.Title id='contained-modal-title-vcenter'>
            {contentData?.title}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ height: '70vh' }}>
          <div className=''>
            <div
              id='quizAssignmentModal'
              style={{ overflow: 'auto', height: '70vh' }}
              className='d-flex flex-column align-items-center p-5 animate__animated animate__fadeIn'>
              {/* {contentData?.pdffile} */}
            </div>
          </div>
        </Modal.Body>
      </Modal>

      <Modal
        id='paidModal'
        size='lg'
        aria-labelledby='contained-modal-title-vcenter'
        centered
        show={paidModalShow}
        onHide={handlePaidModalClose}
        className={
          paidModalShow
            ? 'animate__animated animate__zoomIn'
            : 'animate__animated animate__zoomOut'
        }>
        <Modal.Header closeButton>
          <Modal.Title id='contained-modal-title-vcenter'>
            <h2 className='text-uppercase font-weight-bold'>
              {paidModalTitle}
            </h2>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body closeButton>
          <div className=''>
            <div className='d-flex flex-column align-items-center p-5'>
              <Image src={Wallet} className='paidModalImage' />
              <h1 className='text-justify mb-3'>
                The Content You Are Trying To Access Is For <b>Subscribers</b>{' '}
                Only.
              </h1>
              <h4 className='text-white'>
                Click here to enroll & access all of our content at Loop
              </h4>
              <Link to='/my-account'>
                <Button className='mt-3' variant='light'>
                  SUBSCRIBE NOW
                </Button>
              </Link>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </Fragment>
  );
}

CourseDetail.propTypes = {
  getCourseDetail: PropTypes.func.isRequired,
  clearCourseDetail: PropTypes.func.isRequired,
  setVideoFavourite: PropTypes.func.isRequired,
  setVideoUnFavourite: PropTypes.func.isRequired,
  setContentFavourite: PropTypes.func.isRequired,
  setContentUnFavourite: PropTypes.func.isRequired,
  course: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  course: state.course,
  auth: state.auth,
});
export default connect(mapStateToProps, {
  getCourseDetail,
  clearCourseDetail,
  setVideoFavourite,
  setVideoUnFavourite,
  setContentFavourite,
  setContentUnFavourite,
})(CourseDetail);
