import React from 'react';
import loop_logo from '../../assets/images/loop_logo.png';
import '../../assets/css/navbar.css';
import { WEBSITE_URL } from '../../actions/types';

function Navbar(props) {
  return (
    <div className='container'>
      <div className='row'>
        <div className='col-md-12'>
          <div className='logo text-center'>
            <a href={WEBSITE_URL}>
              <img src={loop_logo} alt='Loop' className='img-fluid' />
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}

Navbar.propTypes = {};

export default Navbar;
