import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Modal } from 'react-bootstrap';
import { connect } from 'react-redux';
import { profileUpdatePassword, logoutClient } from '../../actions/auth';
import Icofont from 'react-icofont';
import IconWarning from '../../assets/images/icon-warning.png';
import Noty from 'noty';

function PasswordChangeModal({
  showPasswordChangeModal,
  setPasswordChangeModal,
  data,
  setData,
  setSuccessMessageModal,
  profileUpdatePassword,
  logoutClient,
}) {
  const [loader, setLoader] = useState(false);
  const [error, setError] = useState(false);

  const successMessage = async (e) => {
    e.preventDefault();

    setLoader(true);

    if (data.newPassword === data.confirmNewPassword) {
      let profileDataPassword = new FormData();

      profileDataPassword.append('id', localStorage.getItem('id'));
      profileDataPassword.append('old_password', data.currentPassword);
      profileDataPassword.append('new_password', data.newPassword);

      let res = await profileUpdatePassword(profileDataPassword);

      if (res === true) {
        setData({
          ...data,
          successTitle: 'Your password has been successfully changed',
          successMessage:
            'You can now use your new password to sign in to your LOOP account.',
          email: '',
        });

        setLoader(false);

        setPasswordChangeModal(false);
        setSuccessMessageModal(true);

        setTimeout(() => {
          logoutClient();
        }, 3000);
      } else {
        setLoader(false);
      }
    } else {
      setLoader(false);
      new Noty({
        type: 'error',
        layout: 'bottomCenter',
        text: "New password and confirm password does'nt match",
        timeout: 2000,
      }).show();
    }
  };

  const checkPassword = (e) => {
    // var error;
    if (
      /^(?=.*[!@#$%^&*])/.test(e.target.value) &&
      e.target.value.length > 6 &&
      /^(?=.*[A-Z])/.test(e.target.value)
    ) {
      //   console.log('in heree');

      setError(false);
    } else {
      setError(true);
    }

    setData({ ...data, newPassword: e.target.value });
  };

  return (
    <Modal
      show={showPasswordChangeModal}
      onHide={() => setPasswordChangeModal(false)}
      size='xl'
      aria-labelledby='contained-modal-title-vcenter'
      centered
      backdrop='static'>
      <Modal.Header closeButton className='border-bottom-0'></Modal.Header>
      <Modal.Body>
        <div className='myaccount-modal-body'>
          <form
            onSubmit={(e) => {
              data.newPassword === '' || error
                ? e.preventDefault()
                : successMessage(e);
            }}>
            <h5 className='mb-4 modal-heading'>
              <b>Change password</b>
            </h5>
            <div className='form-row'>
              <div className='form-group col-md-12'>
                <label htmlFor='c_password'>CURRENT PASSWORD </label>
                <input
                  type='password'
                  className='form-control'
                  id='c_password'
                  value={data.currentPassword}
                  onChange={(e) =>
                    setData({ ...data, currentPassword: e.target.value })
                  }></input>
              </div>
              <div className='form-group col-md-12'>
                <label htmlFor='n_password'>NEW PASSWORD</label>
                <input
                  type='password'
                  className='form-control'
                  id='n_password'
                  style={error ? { border: 'solid 1px #ffacac' } : null}
                  value={data.newPassword}
                  onChange={(e) => checkPassword(e)}></input>
                {error ? (
                  <div
                    className='password-requirement'
                    style={{
                      position: 'absolute',
                      top: '80%',
                      transform: 'translateY(-80%)',
                      right: '-100%',
                      color: '#ffacac',
                    }}>
                    <p style={{ margin: '0', fontSize: '16px' }}>
                      <span>
                        <img src={IconWarning} alt='icon-warning' />
                      </span>{' '}
                      Password does not meet requirements
                    </p>
                  </div>
                ) : null}
              </div>
              <div className='col-md-12'>
                <div
                  className='position-relative'
                  style={{ marginTop: '25px' }}>
                  <p className='modal-label-text'>
                    Use a minimum of 6 characters (case sensitive) with atleast
                    one number or special character.{' '}
                  </p>
                </div>
              </div>

              <div className='form-group col-md-12'>
                <label htmlFor='c_n_password'>CONFIRM NEW PASSWORD</label>
                <input
                  type='password'
                  className='form-control'
                  id='c_n_password'
                  value={data.confirmNewPassword}
                  onChange={(e) =>
                    setData({
                      ...data,
                      confirmNewPassword: e.target.value,
                    })
                  }></input>
              </div>
            </div>

            <button
              type='submit'
              className='btn btn-yellow w-100 mt-4'
              style={
                data.newPassword === '' || error
                  ? {
                      pointerEvents: 'none',
                      backgroundColor: '#1d3246',
                      border: 'none',
                    }
                  : { border: 'none' }
              }>
              Save{' '}
              {loader ? (
                <span style={{ marginLeft: '5px' }}>
                  <Icofont icon='spinner' size='1' spin='true' />
                </span>
              ) : null}
            </button>
          </form>
        </div>
      </Modal.Body>
    </Modal>
  );
}

PasswordChangeModal.propTypes = {
  profileUpdatePassword: PropTypes.func.isRequired,
  logoutClient: PropTypes.func.isRequired,
};

export default connect(null, { profileUpdatePassword, logoutClient })(
  PasswordChangeModal
);
