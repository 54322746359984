import React, { useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { loadStripe } from '@stripe/stripe-js';
import {
  CardElement,
  Elements,
  useStripe,
  useElements,
  CardNumberElement,
  CardCvcElement,
  CardExpiryElement,
} from '@stripe/react-stripe-js';
import Noty from 'noty';
import { subscribedUser } from '../../actions/auth';
import { connect } from 'react-redux';
import { Redirect, withRouter } from 'react-router-dom';
import { STRIPE_PUBLISH_KEY } from '../../actions/types';

function CardDetail({
  changeStep,
  data,
  setData,
  setModal,
  subscribedUser,
  history,
  location,
}) {
  useEffect(() => {
    setModal(true);

    return () => {
      localStorage.removeItem('yearly_selection');
    };
  }, []);

  const myRef = useRef();

  const search = location.search; // could be '?foo=bar'
  const params = new URLSearchParams(search);
  // const foo = params.get('foo');

  //   const [hello, sethello] = useState(
  //     localStorage.getItem('yearly_selection') ? 'yearly' : 'monthly'
  //   );

  const [hello, sethello] = useState(
    params.has('package') ? params.get('package') : 'monthly'
  );

  const packageChange = (e) => {
    // console.log(e.target.value);
    sethello(e.target.value);
  };

  const stripe = loadStripe(STRIPE_PUBLISH_KEY);

  //   const stripe = loadStripe(
  //     'sk_test_51IJbzrCxX5t2AMannkJqS4payhkMytj0OYI4ljFJxdrBFYGLzNMdKvXNdueJJq8wGm8H1Nzv3RVf287euZEMAMrK008WGbjNkM'
  //   );
  //   // const stripe = useStripe();
  //   const elements = useElements();

  //   const handleSubmit = async (event) => {
  //     event.preventDefault();
  //     const { error, paymentMethod } = await stripe.createPaymentMethod({
  //       type: "card",
  //       card: elements.getElement(CardElement),
  //     });
  //   };

  return (
    <div className='row animated zoomIn'>
      <div className='col-md-12'>
        <div className='text-white' style={{ marginTop: '66px' }}>
          <h4 className='main-steps'>STEP 4 OF 4</h4>
        </div>
      </div>
      <div className='col-md-12'>
        <div className='text-white' style={{ marginTop: '12px' }}>
          <h4 className='main-heading'>Start Learning Today</h4>
        </div>
      </div>

      <div className='col-md-12'>
        <div
          className='text-white position-relative'
          style={{ marginTop: '22px' }}
        >
          <p className='main-heading-paragraph'>
            The details below will be used as billing information for your LOOP
            subscription.{' '}
          </p>
        </div>
      </div>

      <div className='col-md-12 row' style={{ marginTop: '35px' }}>
        <div className='col-md-6' style={{ paddingRight: '0' }}>
          <div
            className='custom-control custom-checkbox custom-control-checkbox-text'
            style={
              hello === 'monthly'
                ? { border: '0.5px solid rgb(235 235 235 / 43%)' }
                : null
            }
          >
            <input
              type='radio'
              className='custom-control-input custom-control-input-round'
              id='customCheck'
              checked={hello === 'monthly' ? true : false}
              //   checked={field.input.value}
              name='example1'
              value='monthly'
              //   ref={myRef}
              //   defaultChecked
              onChange={(e) => packageChange(e)}
            />
            <label
              className='custom-control-label custom-control-label-round'
              htmlFor='customCheck'
            >
              <span>
                Monthly <br /> USD 9.99
              </span>
            </label>
          </div>
        </div>

        <div className='col-md-6' style={{ paddingRight: '0' }}>
          <div
            className='custom-control custom-checkbox custom-control-checkbox-text'
            style={
              hello === 'yearly'
                ? { border: '0.5px solid rgb(235 235 235 / 43%)' }
                : null
            }
          >
            <input
              type='radio'
              className='custom-control-input custom-control-input-round'
              id='customCheck1'
              name='example1'
              checked={hello === 'yearly' ? true : false}
              value='yearly'
              onChange={(e) => packageChange(e)}
              //   ref={myRef}
            />
            <label
              className='custom-control-label custom-control-label-round'
              htmlFor='customCheck1'
            >
              <span>
                Yearly <br /> USD 99.00
              </span>
            </label>
          </div>
        </div>
      </div>

      <div className='col-md-12'>
        <div className='text-white' style={{ marginTop: '28px' }}>
          <div className='position-relative w-100' style={{ height: '39px' }}>
            <Elements stripe={stripe}>
              <CheckoutForm
                data={data}
                hello={hello}
                setData={setData}
                subscribedUser={subscribedUser}
                history={history}
              />
            </Elements>
            {/* <input
              type="email"
              className="email-input"
              style={{
                width: "100%",
                height: "39px",
                border: "solid 1px #353535",
                backgroundColor: "#353535",
                padding: "9px 15px 8px 15px",
                borderRadius: "3px",
                color: "white",
                fontSize: "15px",
                fontWeight: "300",
                fontFamily: "AvenirLight",
              }}
              value={data.email}
              placeholder="Email Address"
              onChange={(e) =>
                setData({ ...data, [e.target.type]: e.target.value })
              }
              required="required"
            /> */}
          </div>
          {/* <InputField
                type='email'
                placeholder='Email Address'
                withIcon={false}
              /> */}
        </div>
      </div>
      <div className='col-md-12'>
        {/* <div
          className="next-button"
            onClick={(e) => changeStep(e, 1.1)}
            style={
              /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(
                data.email
              ) === false
                ? { pointerEvents: "none", backgroundColor: "#f1b300b0" }
                : null
            }
        >
          <h4>SUBSCRIBE</h4>
        </div> */}
      </div>
    </div>
  );
}

function CheckoutForm({ setData, data, hello, subscribedUser, history }) {
  const [cardData, setCardData] = useState({
    cardName: '',
    cardExpiry: '',
    cardPostal: '',
    default: false,
  });

  const [isPaymentLoading, setPaymentLoading] = useState(false);
  const stripe = useStripe();
  const elements = useElements();

  const payMoney = async (e) => {
    e.preventDefault();
    if (!stripe || !elements) {
      return;
    }
    setPaymentLoading(true);
    // const clientSecret =
    //   'sk_test_51IJbzrCxX5t2AMannkJqS4payhkMytj0OYI4ljFJxdrBFYGLzNMdKvXNdueJJq8wGm8H1Nzv3RVf287euZEMAMrK008WGbjNkM'; //getClientSecret();
    const { error, paymentMethod } = await stripe.createPaymentMethod({
      type: 'card',
      card: elements.getElement(
        CardNumberElement,
        CardExpiryElement,
        CardCvcElement
      ),
      billing_details: {
        name: cardData.cardName,
        email: data.email,
        address: {
          postal_code: cardData.cardPostal,
        },
      },
    });
    // setPaymentLoading(false);

    if (error) {
      console.log('[error]', error);

      new Noty({
        type: 'error',
        layout: 'bottomCenter',
        text: error.message,
        timeout: 300000,
      }).show();
      setPaymentLoading(false);
    } else {
      //   console.log('[PaymentMethod]', paymentMethod);
      //   console.log('mydata: ', {
      //     email: data.email,
      //     id: paymentMethod.id,
      //     plan: hello,
      //   });

      let sendData = new FormData();

      sendData.append('email', data.email);
      sendData.append('id', paymentMethod.id);
      sendData.append('plan', hello);
      const res = await subscribedUser(sendData, 'signup');

      if (res) {
        setData({
          first_name: '',
          last_name: '',
          email: '',
          password: '',
          role: '',
        });
        setCardData({
          cardName: '',
          cardExpiry: '',
          cardPostal: '',
          default: false,
        });
        setPaymentLoading(false);
        history.push('/my-account');
        // <Redirect to='/login' />;
      } else {
        setPaymentLoading(false);
      }
    }
    // if (paymentResult.error) {
    //   alert(paymentResult.error.message);
    // } else {
    //   if (paymentResult.paymentIntent.status === 'succeeded') {
    //     alert('Success!');
    //   }
    // }
  };
  return (
    <div
      style={{
        padding: '0rem',
      }}
    >
      <div
        style={{
          maxWidth: '100%',
          margin: '0 auto',
        }}
      >
        <form
          style={{
            display: 'block',
            width: '100%',
          }}
          onSubmit={payMoney}
        >
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            <p className='card-label'>NAME ON CARD </p>
            <div
              className='position-relative w-100'
              style={{ height: '39px', marginBottom: '8px' }}
            >
              <input
                type='text'
                className='email-input'
                style={{
                  width: '100%',
                  height: '39px',
                  border: 'solid 1px #353535',
                  backgroundColor: '#353535',
                  padding: '9px 15px 8px 15px',
                  borderRadius: '3px',
                  color: 'white',
                  fontSize: '16px',
                  fontWeight: '300',
                  fontFamily: 'AvenirLight',
                }}
                value={cardData.cardName}
                onChange={(e) =>
                  setCardData({ ...cardData, cardName: e.target.value })
                }
              />
            </div>

            {/* <CardElement /> */}

            <p className='card-label'>CARD NUMBER </p>
            <CardNumberElement
              className='card-number'
              //   showIcon={true}
              options={{
                style: {
                  base: {
                    color: 'white',
                  },
                },
                showIcon: true,
              }}
            />
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                width: '100%',
                justifyContent: 'space-between',
                marginTop: '8px',
              }}
            >
              <div style={{ width: '45%', marginBottom: '8px' }}>
                <p className='card-label'>EXPIRATION DATE </p>
                <CardExpiryElement
                  className='card-number'
                  options={{
                    style: {
                      base: {
                        color: 'white',
                        marginBottom: '5px',
                      },
                    },
                  }}
                />
              </div>

              <div style={{ width: '45%' }}>
                <p className='card-label'>SECURITY CODE </p>
                <CardCvcElement
                  className='card-number'
                  options={{
                    style: {
                      base: {
                        color: 'white',
                        marginBottom: '5px',
                      },
                    },
                  }}
                />
              </div>
            </div>

            <p className='card-label'>POSTAL CODE </p>
            {/* <CardNumberElement
              className="card-number"
              options={{
                style: {
                  base: {
                    color: "gray",
                  },
                },
              }}
            /> */}
            <div className='position-relative w-100' style={{ height: '39px' }}>
              <input
                type='text'
                className='email-input'
                style={{
                  width: '100%',
                  height: '39px',
                  // boxShadow: '0 2px 4px 0 #353535',
                  border: 'solid 1px #353535',
                  backgroundColor: '#353535',
                  padding: '9px 15px 8px 15px',
                  borderRadius: '3px',
                  color: 'white',
                  fontSize: '16px',
                  fontWeight: '300',
                  fontFamily: 'AvenirLight',
                }}
                value={cardData.cardPostal}
                onChange={(e) =>
                  setCardData({ ...cardData, cardPostal: e.target.value })
                }
              />
            </div>

            {/* <CardNumberElement
              options={{
                style: { base: { backgroundColor: "red", width: "100px" } },
              }}
            /> */}
            <div
              className='next-button-pay'
              //   disabled={isPaymentLoading}
              onClick={payMoney}
            >
              {isPaymentLoading ? 'LOADING...' : 'SUBSCRIBE'}
            </div>
          </div>
        </form>
      </div>
    </div>
  );
}

// const CARD_OPTIONS = {
//   iconStyle: 'solid',
//   style: {
//     base: {
//       iconColor: '#c4f0ff',
//       color: '#fff',
//       fontWeight: 500,
//       fontFamily: 'Roboto, Open Sans, Segoe UI, sans-serif',
//       fontSize: '16px',
//       fontSmoothing: 'antialiased',
//       ':-webkit-autofill': {
//         color: '#fce883',
//       },
//       '::placeholder': {
//         color: '#87bbfd',
//       },
//     },
//     invalid: {
//       iconColor: '#ffc7ee',
//       color: '#ffc7ee',
//     },
//   },
// };

// const CardField = ({ onChange }) => (
//   <div className='FormRow'>
//     <CardElement options={CARD_OPTIONS} onChange={onChange} />
//   </div>
// );

// const Field = ({
//   label,
//   id,
//   type,
//   placeholder,
//   required,
//   autoComplete,
//   value,
//   onChange,
// }) => (
//   <div className='FormRow'>
//     <label htmlFor={id} className='FormRowLabel'>
//       {label}
//     </label>
//     <input
//       className='FormRowInput'
//       id={id}
//       type={type}
//       placeholder={placeholder}
//       required={required}
//       autoComplete={autoComplete}
//       value={value}
//       onChange={onChange}
//     />
//   </div>
// );

// const SubmitButton = ({ processing, error, children, disabled }) => (
//   <button
//     className={`SubmitButton ${error ? 'SubmitButton--error' : ''}`}
//     type='submit'
//     disabled={processing || disabled}
//   >
//     {processing ? 'Processing...' : children}
//   </button>
// );

// const ErrorMessage = ({ children }) => (
//   <div className='ErrorMessage' role='alert'>
//     <svg width='16' height='16' viewBox='0 0 17 17'>
//       <path
//         fill='#FFF'
//         d='M8.5,17 C3.80557963,17 0,13.1944204 0,8.5 C0,3.80557963 3.80557963,0 8.5,0 C13.1944204,0 17,3.80557963 17,8.5 C17,13.1944204 13.1944204,17 8.5,17 Z'
//       />
//       <path
//         fill='#6772e5'
//         d='M8.5,7.29791847 L6.12604076,4.92395924 C5.79409512,4.59201359 5.25590488,4.59201359 4.92395924,4.92395924 C4.59201359,5.25590488 4.59201359,5.79409512 4.92395924,6.12604076 L7.29791847,8.5 L4.92395924,10.8739592 C4.59201359,11.2059049 4.59201359,11.7440951 4.92395924,12.0760408 C5.25590488,12.4079864 5.79409512,12.4079864 6.12604076,12.0760408 L8.5,9.70208153 L10.8739592,12.0760408 C11.2059049,12.4079864 11.7440951,12.4079864 12.0760408,12.0760408 C12.4079864,11.7440951 12.4079864,11.2059049 12.0760408,10.8739592 L9.70208153,8.5 L12.0760408,6.12604076 C12.4079864,5.79409512 12.4079864,5.25590488 12.0760408,4.92395924 C11.7440951,4.59201359 11.2059049,4.59201359 10.8739592,4.92395924 L8.5,7.29791847 L8.5,7.29791847 Z'
//       />
//     </svg>
//     {children}
//   </div>
// );

// const ResetButton = ({ onClick }) => (
//   <button type='button' className='ResetButton' onClick={onClick}>
//     <svg width='32px' height='32px' viewBox='0 0 32 32'>
//       <path
//         fill='#FFF'
//         d='M15,7.05492878 C10.5000495,7.55237307 7,11.3674463 7,16 C7,20.9705627 11.0294373,25 16,25 C20.9705627,25 25,20.9705627 25,16 C25,15.3627484 24.4834055,14.8461538 23.8461538,14.8461538 C23.2089022,14.8461538 22.6923077,15.3627484 22.6923077,16 C22.6923077,19.6960595 19.6960595,22.6923077 16,22.6923077 C12.3039405,22.6923077 9.30769231,19.6960595 9.30769231,16 C9.30769231,12.3039405 12.3039405,9.30769231 16,9.30769231 L16,12.0841673 C16,12.1800431 16.0275652,12.2738974 16.0794108,12.354546 C16.2287368,12.5868311 16.5380938,12.6540826 16.7703788,12.5047565 L22.3457501,8.92058924 L22.3457501,8.92058924 C22.4060014,8.88185624 22.4572275,8.83063012 22.4959605,8.7703788 C22.6452866,8.53809377 22.5780351,8.22873685 22.3457501,8.07941076 L22.3457501,8.07941076 L16.7703788,4.49524351 C16.6897301,4.44339794 16.5958758,4.41583275 16.5,4.41583275 C16.2238576,4.41583275 16,4.63969037 16,4.91583275 L16,7 L15,7 L15,7.05492878 Z M16,32 C7.163444,32 0,24.836556 0,16 C0,7.163444 7.163444,0 16,0 C24.836556,0 32,7.163444 32,16 C32,24.836556 24.836556,32 16,32 Z'
//       />
//     </svg>
//   </button>
// );

// const CheckoutForm = ({ data }) => {
//   const stripe = useStripe();
//   const elements = useElements();
//   const [error, setError] = useState(null);
//   const [cardComplete, setCardComplete] = useState(false);
//   const [processing, setProcessing] = useState(false);
//   const [paymentMethod, setPaymentMethod] = useState(null);
//   const [billingDetails, setBillingDetails] = useState({
//     email: '',
//     phone: '',
//     name: '',
//   });

//   const handleSubmit = async (event) => {
//     event.preventDefault();

//     if (!stripe || !elements) {
//       // Stripe.js has not loaded yet. Make sure to disable
//       // form submission until Stripe.js has loaded.
//       return;
//     }

//     if (error) {
//       elements.getElement('card').focus();
//       return;
//     }

//     if (cardComplete) {
//       setProcessing(true);
//     }

//     const payload = await stripe.createPaymentMethod({
//       type: 'card',
//       card: elements.getElement(CardElement),
//       //   billing_details: billingDetails,
//     });

//     setProcessing(false);

//     if (payload.error) {
//       setError(payload.error);
//     } else {
//       console.log(payload);
//       setPaymentMethod(payload.paymentMethod);
//     }
//   };

//   const reset = () => {
//     setError(null);
//     setProcessing(false);
//     setPaymentMethod(null);
//     setBillingDetails({
//       email: 'owais@codexnow.com',
//       //   phone: '',
//       //   name: '',
//     });
//   };

//   return paymentMethod ? (
//     <div className='Result'>
//       <div className='ResultTitle' role='alert'>
//         Payment successful
//       </div>
//       <div className='ResultMessage'>
//         Thanks for trying Stripe Elements. No money was charged, but we
//         generated a PaymentMethod: {paymentMethod.id}
//       </div>
//       <ResetButton onClick={reset} />
//     </div>
//   ) : (
//     <form className='Form' onSubmit={handleSubmit}>
//       <fieldset className='FormGroup'>
//         <Field
//           label='Name'
//           id='name'
//           type='text'
//           placeholder='Jane Doe'
//           required
//           autoComplete='name'
//           value={billingDetails.name}
//           onChange={(e) => {
//             setBillingDetails({ ...billingDetails, name: e.target.value });
//           }}
//         />
//         {/* <Field
//           label='Email'
//           id='email'
//           type='email'
//           placeholder='janedoe@gmail.com'
//           required
//           autoComplete='email'
//           value={billingDetails.email}
//           onChange={(e) => {
//             setBillingDetails({ ...billingDetails, email: e.target.value });
//           }}
//         /> */}
//         {/* <Field
//           label='Phone'
//           id='phone'
//           type='tel'
//           placeholder='(941) 555-0123'
//           required
//           autoComplete='tel'
//           value={billingDetails.phone}
//           onChange={(e) => {
//             setBillingDetails({ ...billingDetails, phone: e.target.value });
//           }}
//         /> */}
//       </fieldset>
//       <fieldset className='FormGroup'>
//         <CardField
//           onChange={(e) => {
//             setError(e.error);
//             setCardComplete(e.complete);
//           }}
//         />
//       </fieldset>
//       {error && <ErrorMessage>{error.message}</ErrorMessage>}
//       <SubmitButton processing={processing} error={error} disabled={!stripe}>
//         Pay $25
//       </SubmitButton>
//     </form>
//   );
// };

// const ELEMENTS_OPTIONS = {
//   fonts: [
//     {
//       cssSrc: 'https://fonts.googleapis.com/css?family=Roboto',
//     },
//   ],
// };

CardDetail.propTypes = {
  changeStep: PropTypes.func.isRequired,
  data: PropTypes.object.isRequired,
  setData: PropTypes.func.isRequired,
  subscribedUser: PropTypes.func.isRequired,
};

export default connect(null, { subscribedUser })(withRouter(CardDetail));
