// export const ADMIN_API_ENDPOINT = 'http://192.168.100.162:8002/adminFunctions';
// export const LOGIN_API_ENDPOINT =
//   'http://192.168.100.162:8001/loginAndRegister';
// export const COURSES_API_ENDPOINT = 'http://192.168.100.162:8003/courseModule';
// export const ADMIN_API_ENDPOINT = 'https://test.suhaibwebb.org/adminFunctions';
// export const LOGIN_API_ENDPOINT =
//   'https://test.suhaibwebb.org/loginAndRegister';
// export const LOGIN_API_ENDPOINT = 'http://35.160.233.37/loginAndRegister';
// export const COURSES_API_ENDPOINT = 'http://35.160.233.37/courseModule';
export const LOGIN_API_ENDPOINT = 'https://getloop.us/loginAndRegister';
export const COURSES_API_ENDPOINT = 'https://getloop.us/courseModule';
export const STRIPE_PUBLISH_KEY = 'pk_live_nyonA6hYZ7FiNeJ4MNncrmoV';
// export const STRIPE_PUBLISH_KEY = 'pk_test_dLDyATNqqhYgBV6MXBErAcV9';
// export const STRIPE_PUBLISH_KEY = 'pk_test_51IJbzrCxX5t2AManlsG2n2bp6XaEE5ivkQvqekx1uTDbdGSjnf2zLKjHLKDHGvIKsbGwmxaZaTHZoIXAQWr26tIm00GbpKVaDO';
// export const IMAGE_URL_ENDPOINT = 'https://test.suhaibwebb.org';
// export const ADMIN_API_ENDPOINT = 'https://test.suhaibwebb.org/adminFunctions';
// export const LOGIN_API_ENDPOINT =
//   'https://test.suhaibwebb.org/loginAndRegister';
// export const IMAGE_URL_ENDPOINT = 'https://test.suhaibwebb.org';
export const WEBSITE_URL = 'https://getloop.us';

export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const USER_LOADED = 'USER_LOADED';
export const AUTH_ERROR = 'AUTH_ERROR';
export const LOGIN_FAIL = 'LOGIN_FAIL';
export const LOG_OUT = 'LOG_OUT';

export const UPDATE_PROFILE = 'UPDATE_PROFILE';
export const UPDATE_PASSOWRD = 'UPDATE_PASSWORD';

export const SUBSCRIBE_USER = 'SUBSCIBE_USER';

export const GET_BILLING_HISTORY = 'GET_BILLING_HISTORY';
export const GET_PAYMENT_METHODS = 'GET_PAYMENT_METHONDS';
export const DELETE_PAYMENT_METHOD = 'DELETE_PAYMENT_METHOD';

export const GET_COURSES = 'GET_COURSES';
export const GET_COURSE_DETAIL = 'GET_COURSES_DETAIL';
export const SET_SINGLE_COURSE_DETAIL = 'SET_SINGLE_COURSE_DETAIL';
export const GET_RECENTLY_ADDED_COURSES = 'GET_RECENTLY_ADDED_COURSES';
export const GET_FAVOURITE_COURSES = 'GET_FAVOURITE_COURSES';
export const ERROR_COURSES = 'ERROR_COURSES';
export const CLEAR_COURSES = 'CLEAR_COURSES';
export const CLEAR_FAVOURITES = 'CLEAR_FAVOURITES';
export const SET_VIDEO_FAVOURITE = 'SET_VIDEO_FAVOURITE';
export const REMOVE_VIDEO_FAVOURITE = 'REMOVE_VIDEO_FAVOURITE';
export const SET_CONTENT_FAVOURITE = 'SET_CONTENT_FAVOURITE';
export const REMOVE_CONTENT_FAVOURITE = 'REMOVE_CONTENT_FAVOURITE';
export const CLEAR_COURSE_DETAIL = 'CLEAR_COURSE_DETAIL';
// export const SET_ACCOUNT_DATA = 'SET_ACCOUNT_DATA';

export const SUPPORT_SUCCESS = 'SUPPORT_SUCCESS';
export const SUPPORT_FAILED = 'SUPPORT_FAILED';

export const FIRSTCOLOR = 'blue';
export const SECONDCOLOR = 'white';
export const THIRDCOLOR = 'black';

export const STREAMING_MODAL = 'STREAMING_MODAL';
export const CLASSES_STREAMING_LIST = 'CLASSES_STREAMING_LIST';
