import {
  GET_COURSES,
  GET_COURSE_DETAIL,
  COURSES_API_ENDPOINT,
  ERROR_COURSES,
  CLEAR_COURSES,
  CLEAR_COURSE_DETAIL,
  SET_SINGLE_COURSE_DETAIL,
  GET_RECENTLY_ADDED_COURSES,
  GET_FAVOURITE_COURSES,
  CLEAR_FAVOURITES,
  SET_VIDEO_FAVOURITE,
  REMOVE_VIDEO_FAVOURITE,
  SET_CONTENT_FAVOURITE,
  REMOVE_CONTENT_FAVOURITE,
} from '../actions/types';
import axios from 'axios';
import Noty from 'noty';

export const loadCourses = () => async (dispatch) => {
  try {
    const res = await axios.get(`${COURSES_API_ENDPOINT}/category/`);
    // console.log(res, 'Courses');
    if (res.status === 200) {
      dispatch({
        type: GET_COURSES,
        payload: res.data,
      });
    }
  } catch (error) {
    dispatch({
      type: ERROR_COURSES,
    });
  }
};

export const getRecentlyAddedCourses = () => async (dispatch) => {
  try {
    const res = await axios.get(`${COURSES_API_ENDPOINT}/recently_added/`);
    // console.log(res, 'GET_RECENTLY_ADDED_COURSES');
    if (res.status === 200) {
      dispatch({
        type: GET_RECENTLY_ADDED_COURSES,
        payload: res.data,
      });
    }
  } catch (error) {
    dispatch({
      type: ERROR_COURSES,
    });
  }
};

export const getCourseDetail = (id) => async (dispatch) => {
  try {
    // console.log('---> Course Detail Action', id);

    const res = await axios.get(
      `${COURSES_API_ENDPOINT}/courses/?courseId=${id}`
    );
    // console.log(res.data, 'Courses');
    if (res.status === 200) {
      dispatch({
        type: GET_COURSE_DETAIL,
        payload: res.data,
      });
    }
    return true;
  } catch (error) {
    console.log(error.response.status);
    if (error.response.status === 401) {
      dispatch({
        type: ERROR_COURSES,
      });
    } else {
      let emptyObject = {
        data: [],
        course_name: error.response.data.description,
        image: '',
        web_image: '',
        banner_image: '',
        description: error.response.data.description,
      };
      dispatch({
        type: GET_COURSE_DETAIL,
        payload: emptyObject,
      });
    }
  }
};

export const getFavourites = () => async (dispatch) => {
  try {
    const res = await axios.get(`${COURSES_API_ENDPOINT}/lesson/favourites/`);
    // console.log(res, 'favouriteCourses');
    if (res.status === 200) {
      dispatch({
        type: GET_FAVOURITE_COURSES,
        payload: res.data,
      });
    }
  } catch (error) {
    dispatch({
      type: ERROR_COURSES,
    });
  }
};

export const setVideoFavourite =
  (videoData, lessonID, outerIndex, innerIndex) => async (dispatch) => {
    // console.log('Favourite videoData--> ', videoData);
    // console.log('Favourite lessonID--> ', lessonID);
    // console.log('Favourite outerIndex--> ', outerIndex);
    // console.log('Favourite innerIndex--> ', innerIndex);

    try {
      // console.log(videoData.id);
      // console.log(lessonID);
      var bodyFormData = new FormData();
      bodyFormData.append('lesson_id', lessonID);
      bodyFormData.append('video_id', videoData.id);
      const res = await axios.post(
        `${COURSES_API_ENDPOINT}/lesson/favourites/`,
        bodyFormData
      );
      // console.log('----Favourite', res.data);
      if (res.data) {
        // console.log('setVideoUnFavourite load favourites again');
        let payloadData = {
          outerIndex,
          innerIndex,
        };
        dispatch({
          type: SET_VIDEO_FAVOURITE,
          payload: payloadData,
        });
      }
      new Noty({
        type: 'error',
        layout: 'bottomCenter',
        text: `'${videoData.video_title}' Video is Added to Favourites`,
        timeout: 2000,
      }).show();
      return true;
    } catch (err) {
      console.log('----Favourite error', err);
      new Noty({
        type: 'error',
        layout: 'bottomCenter',
        text: err.response.data.description,
        timeout: 2000,
      }).show();
    }
  };

export const setVideoUnFavourite =
  (videoData, lessonID, outerIndex, innerIndex, fromFavouritesComponent) =>
  async (dispatch) => {
    try {
      // console.log(videoData.id);
      // console.log(lessonID);
      var bodyFormData = new FormData();
      bodyFormData.append('lesson_id', lessonID);
      bodyFormData.append('video_id', videoData.id);
      const res = await axios.patch(
        `${COURSES_API_ENDPOINT}/lesson/favourites/`,
        bodyFormData
      );
      // console.log('----UNFavourite', res.data);
      if (res.data) {
        // console.log('setVideoUnFavourite load favourites again');
        if (fromFavouritesComponent) {
          await dispatch(getFavourites());
        } else {
          let payloadData = {
            outerIndex,
            innerIndex,
          };
          dispatch({
            type: REMOVE_VIDEO_FAVOURITE,
            payload: payloadData,
          });
        }
      }
      new Noty({
        type: 'error',
        layout: 'bottomCenter',
        text: `'${videoData.video_title}' Video is Removed from Favourites`,
        timeout: 2000,
      }).show();
      return true;
    } catch (err) {
      console.log('----unFavourite error', err);
      new Noty({
        type: 'error',
        layout: 'bottomCenter',
        text: err.response.data.description,
        timeout: 2000,
      }).show();
    }
  };

export const setContentFavourite =
  (contentData, lessonID, outerIndex, innerIndex) => async (dispatch) => {
    try {
      // console.log(contentData._id);
      // console.log(lessonID);
      var bodyFormData = new FormData();
      bodyFormData.append('lesson_id', lessonID);
      bodyFormData.append('content_id', contentData._id);
      const res = await axios.post(
        `${COURSES_API_ENDPOINT}/lesson/favourites/`,
        bodyFormData
      );
      console.log(res.data, '----Favourite');
      if (res.data) {
        // console.log('setVideoUnFavourite load favourites again');
        let payloadData = {
          outerIndex,
          innerIndex,
        };
        dispatch({
          type: SET_CONTENT_FAVOURITE,
          payload: payloadData,
        });
      }
      new Noty({
        type: 'error',
        layout: 'bottomCenter',
        text: `'${contentData.title}' Content is Added to Favourites`,
        timeout: 2000,
      }).show();
      return true;
    } catch (err) {
      console.log(err, '----Favourite error');
      new Noty({
        type: 'error',
        layout: 'bottomCenter',
        text: err.response.data.description,
        timeout: 2000,
      }).show();
    }
  };

export const setContentUnFavourite =
  (contentData, lessonID, outerIndex, innerIndex, fromFavouritesComponent) =>
  async (dispatch) => {
    try {
      // console.log(contentData._id);
      // console.log(lessonID);
      var bodyFormData = new FormData();
      bodyFormData.append('lesson_id', lessonID);
      bodyFormData.append('content_id', contentData._id);
      const res = await axios.patch(
        `${COURSES_API_ENDPOINT}/lesson/favourites/`,
        bodyFormData
      );
      // console.log(res.data, '----Favourite');
      if (res.data) {
        // console.log('setVideoUnFavourite load favourites again');
        if (fromFavouritesComponent) {
          await dispatch(getFavourites());
        } else {
          let payloadData = {
            outerIndex,
            innerIndex,
          };
          dispatch({
            type: REMOVE_CONTENT_FAVOURITE,
            payload: payloadData,
          });
        }
      }
      new Noty({
        type: 'error',
        layout: 'bottomCenter',
        text: `'${contentData.title}' Content is Removed from Favourites`,
        timeout: 2000,
      }).show();
      return true;
    } catch (err) {
      console.log(err, '----Favourite error');
      new Noty({
        type: 'error',
        layout: 'bottomCenter',
        text: err.response.data.description,
        timeout: 2000,
      }).show();
    }
  };

export const clearCourses = () => (dispatch) => {
  dispatch({
    type: CLEAR_COURSES,
  });
};

export const clearCourseDetail = () => (dispatch) => {
  dispatch({
    type: CLEAR_COURSE_DETAIL,
  });
};

export const clearFavourites = () => (dispatch) => {
  // console.log('clear Favourites');
  dispatch({
    type: CLEAR_FAVOURITES,
  });
};
