import React from 'react';
import { Modal } from 'react-bootstrap';
import { Link } from 'react-router-dom';

function ResumePaymentModal({ showModal, setModal }) {
  return (
    <Modal
      show={showModal}
      onHide={() => setModal(false)}
      size='md'
      aria-labelledby='contained-modal-title-vcenter'
      centered
      contentClassName='resend-modal-content'
      backdrop='static'>
      <Modal.Body className='resend-modal-body text-dark'>
        <h4 className='main-heading text-dark' style={{ marginTop: '42px' }}>
          Finish Subscribing Later?
        </h4>
        <p
          className='main-heading-paragraph text-dark'
          style={{ marginTop: '22px', height: '74px' }}>
          Your account and login information will be saved.<br></br> Continue
          any time !
        </p>
        <div className='buttons'>
          <button className='contact-btn' onClick={() => setModal(false)}>
            RESUME
          </button>
          <Link to='/home' className='ok-btn'>
            FINISH LATER
          </Link>
        </div>
      </Modal.Body>
    </Modal>
  );
}

ResumePaymentModal.propTypes = {};

export default ResumePaymentModal;
