import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Modal } from 'react-bootstrap';
import { connect } from 'react-redux';
import { profileUpdate } from '../../actions/auth';
import Icofont from 'react-icofont';

function NameChangeModal({
  showNameChangeModal,
  setNameChangeModal,
  data,
  setData,
  setSuccessMessageModal,
  profileUpdate,
  auth: { userDetail },
}) {
  useEffect(() => {
    setData({
      ...data,
      first_name: userDetail === null ? '' : userDetail.first_name,
      last_name: userDetail === null ? '' : userDetail.last_name,
      email: '',
    });
  }, [showNameChangeModal]);

  const [loader, setLoader] = useState(false);

  const successMessage = async (e) => {
    e.preventDefault();

    setLoader(true);

    let profileData = new FormData();

    profileData.append('id', localStorage.getItem('id'));
    profileData.append('first_name', data.first_name);
    profileData.append('last_name', data.last_name);

    let res = await profileUpdate(profileData);

    if (res === true) {
      setData({
        ...data,
        successTitle: 'Your account name has been successfully changed',
        successMessage: '',
        email: '',
      });

      setLoader(false);

      setNameChangeModal(false);
      setSuccessMessageModal(true);
    } else {
      setLoader(false);
    }
  };

  return (
    <Modal
      show={showNameChangeModal}
      onHide={() => setNameChangeModal(false)}
      size='xl'
      aria-labelledby='contained-modal-title-vcenter'
      centered
      backdrop='static'
      animation={true}
    >
      <Modal.Header closeButton className='border-bottom-0'></Modal.Header>
      <Modal.Body>
        <div className='myaccount-modal-body'>
          <form onSubmit={(e) => successMessage(e)}>
            <h5 className='mb-4 modal-heading'>
              <b>Change account name</b>
            </h5>
            <div className='form-row'>
              <div className='form-group col-md-12'>
                <label htmlFor='f_name'>First Name</label>
                <input
                  type='text'
                  className='form-control'
                  id='f_name'
                  placeholder='First Name'
                  value={data.first_name}
                  onChange={(e) =>
                    setData({ ...data, first_name: e.target.value })
                  }
                  required='required'
                />
              </div>
              <div className='form-group col-md-12'>
                <label htmlFor='l_name'>Last Name</label>
                <input
                  type='text'
                  className='form-control'
                  placeholder='last Name'
                  id='l_name'
                  value={data.last_name}
                  onChange={(e) =>
                    setData({ ...data, last_name: e.target.value })
                  }
                  required='required'
                />
              </div>
            </div>
            <button
              type='submit'
              className='btn btn-yellow w-100 mt-4'
              onClick={(e) => successMessage(e)}
            >
              Save{' '}
              {loader ? (
                <span style={{ marginLeft: '5px' }}>
                  <Icofont icon='spinner' size='1' spin='true' />
                </span>
              ) : null}
            </button>
          </form>
        </div>
      </Modal.Body>
    </Modal>
  );
}

NameChangeModal.propTypes = {
  profileUpdate: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});
export default connect(mapStateToProps, { profileUpdate })(NameChangeModal);
