import React, { Fragment, useState, useEffect, useLayoutEffect } from 'react';
import PropTypes from 'prop-types';
import {
  Image,
  Row,
  Col,
  Card,
  Tabs,
  Tab,
  Modal,
  Spinner,
} from 'react-bootstrap';
import 'animate.css';
import {
  getFavourites,
  setVideoUnFavourite,
  setContentUnFavourite,
  clearFavourites,
} from '../../actions/courseActions';
import paidContent from '../../assets/images/paidContent.jpg';
import Content from '../../assets/images/Content.jpg';
import contentPPT from '../../assets/images/contentPPT.jpg';
import contentPDF from '../../assets/images/contentPDF.jpg';
import Icofont from 'react-icofont';

import { connect } from 'react-redux';

function Favourites({
  getFavourites,
  setVideoUnFavourite,
  setContentUnFavourite,
  clearFavourites,
  course: { favouriteCourses },
}) {
  const [show, setShow] = useState(false);
  const [videoLinkHover, setVideoLinkHover] = useState('');
  const [videomodalShow, setVideoModalShow] = useState(false);
  const [modalVideoData, setmodalVideoData] = useState(null);

  const [videoLink, setVideoLink] = useState('');

  const ScrollToTop = () => {
    useLayoutEffect(() => {
      window.scrollTo(0, 0);
    }, []);

    return null;
  };
  ScrollToTop();

  const handleClose = () => {
    setShow(false);
    let videoCurrentTime = document.getElementById('videoPlayer').currentTime;
    if (videomodalShow) {
      setTimeout(() => {
        document.getElementById('modalContentVideoPlayer').currentTime =
          videoCurrentTime;
      }, 100);
    }
  };
  const handleShow = (id, videoCurrentTime) => {
    // console.log(videoLink);
    setVideoLink(id);
    setShow(true);
    if (videoCurrentTime) {
      // let videoData = { ...data, videoCurrentTime };
      setTimeout(() => {
        document.getElementById('videoPlayer').currentTime = videoCurrentTime;
      }, 100);
    }
  };

  const handleVideoModalClose = () => {
    setVideoModalShow(false);
    setmodalVideoData(null);
  };
  const handleVideoModalShow = (currentLessondata, lessonName) => {
    let modalData = { ...currentLessondata, lessonName };
    setmodalVideoData(modalData);
    setVideoModalShow(true);
  };

  useEffect(() => {
    getFavourites();
    return () => {
      clearFavourites();
    };
  }, []);
  return (
    <Fragment>
      {/* <input type='text' id='hello' /> */}
      <div className='my-3'>
        {favouriteCourses ? (
          <div
            className='p-5 animate__animated animate__fadeIn'
            style={{ overflow_Y: 'auto', minHeight: '65vh' }}>
            {favouriteCourses?.videos.length !== 0 ? (
              <div style={{ overflow_Y: 'auto' }}>
                <Row>
                  {favouriteCourses?.videos.map((e, index) => (
                    <Col xl={3} className='my-1' key={index}>
                      <Card
                        // bg='light'
                        text='white'
                        style={{ width: '100%', height: '100%' }}
                        className='customContentCard'>
                        <Card.Header
                          className='d-flex justify-content-center'
                          style={{
                            height: '250px',
                            cursor: 'pointer',
                          }}
                          onClick={() => {
                            if (e.cloudflare_uid) {
                              // console.log(e.cloudflare_uid);
                              handleShow(e);
                            }
                          }}>
                          <video
                            controlsList='nodownload'
                            onContextMenu={(e) => e.preventDefault()}
                            loop
                            width='100%'
                            height='100%'
                            muted={true}
                            // autoPlay='autoplay'
                            onMouseEnter={(event) => {
                              event.target.play();
                              //   event.target.muted = false;
                              // if (event.target.muted == true) {
                              //   event.target.muted = false;
                              // } else {
                              //   event.target.muted = true;
                              // }
                              // var myEvent = event.target;
                              // var check = myEvent.play();
                              // if (check !== undefined) {
                              //   check.then(() => {
                              //     console.log('check: ', check);
                              //     myEvent.muted = false;
                              //   });
                              // }
                              // event.target.muted = false;
                            }}
                            onMouseLeave={(event) => {
                              // event.target.muted = true;
                              event.target.pause();
                              event.target.currentTime = 0;
                            }}
                            style={{
                              border: 'none',
                              borderRadius: '5px',
                            }}
                            src={e.cloudflare_preview.replace(
                              'getloopstream',
                              'getloopstream'
                            )}></video>
                        </Card.Header>
                        <Card.Body className='p-3'>
                          <Card.Title className='m-0 d-flex justify-content-between h-100'>
                            <div className='align-items-center card_title'>
                              <a
                                className='m-0'
                                onClick={() => {
                                  if (e.cloudflare_uid) {
                                    // console.log(e.cloudflare_uid);
                                    handleVideoModalShow(e, e.lessonName);
                                  }
                                }}
                                style={{ cursor: 'pointer' }}>
                                {e.video_title}
                              </a>
                            </div>
                            <div>
                              <Icofont
                                icon='heart'
                                size='1'
                                className='heartIconYellow'
                                onClick={() =>
                                  setVideoUnFavourite(e, 0, 0, 0, true)
                                }
                                style={{ cursor: 'pointer' }}
                              />
                              <Icofont
                                icon='stylish-down'
                                size='1'
                                className='heartIcon'
                                onClick={() =>
                                  handleVideoModalShow(e, e.lessonName)
                                }
                                style={{ cursor: 'pointer' }}
                              />
                            </div>
                          </Card.Title>
                        </Card.Body>
                      </Card>
                    </Col>
                  ))}
                </Row>
              </div>
            ) : (
              <div className='d-flex h-100 justify-content-center align-items-center'>
                <h4 className=''>No Video Available in Your Favourites!!</h4>
              </div>
            )}
          </div>
        ) : (
          <Fragment>
            <div
              className='d-flex justify-content-center my-5'
              style={{ minHeight: '60vh' }}>
              <Spinner
                as='span'
                animation='border'
                size='lg'
                role='status'
                aria-hidden='true'
              />
            </div>
          </Fragment>
        )}
      </div>

      <Modal
        show={show}
        size='lg'
        id='customVideoModal'
        onHide={handleClose}
        animation={true}
        dialogClassName='modal-100w'
        className={
          show
            ? 'animate__animated animate__zoomIn'
            : 'animate__animated animate__zoomOut'
        }>
        <Modal.Body
          className='p-0'
          style={{
            border: 'none',
            // objectFit: 'cover',
            width: '100%',
            height: '100%',
            overflow: 'hidden',
            position: 'absolute',
          }}>
          <div
            className='d-flex position-absolute float-right flex-column close'
            style={{ zIndex: '999' }}>
            <Icofont
              icon='close-circled'
              size='2'
              onClick={handleClose}
              style={{ color: 'white', cursor: 'pointer' }}
              className='textShadow'
            />
            {/* <Icofont icon='heart' size='2' className='heartIcon' /> */}
            {/* {lessonData?.paid === '0' ? (
              videoLink?.favourite ? (
                <Icofont
                  icon='heart'
                  size='2'
                  className='heartIconYellow'
                  onClick={() =>
                    setVideoUnFavourite(videoLink, lessonData.lessonId)
                  }
                />
              ) : (
                <Icofont
                  icon='heart'
                  size='2'
                  className='heartIcon'
                  onClick={() =>
                    setVideoFavourite(videoLink, lessonData.lessonId)
                  }
                />
              )
            ) : null} */}
          </div>
          {videoLink ? (
            <video
              controlsList='nodownload'
              onContextMenu={(e) => e.preventDefault()}
              // class=''
              id='videoPlayer'
              loop
              controls
              width='100%'
              height='100%'
              // muted
              autoPlay='autoplay'
              style={{
                position: 'absolute',
                backgroundColor: '#000000',
                // border: 'none',
                // objectFit: 'cover',
              }}>
              <source
                src={videoLink?.cloudflare_preview.replace(
                  'getloopstream',
                  'getloopstream'
                )}
                // type='video/mp4'
              />
            </video>
          ) : null}
          {/* <iframe
            src={`${videoLink?.cloudflare_preview}?autoPlay=true`}
            title={'test'}
            width='100%'
            height='100%'
            allow='accelerometer; gyroscope; autoplay; encrypted-media; picture-in-picture;'
            autoPlay={true}
            allowFullScreen=''
            style={{
              border: 'none',
            }}></iframe> */}
        </Modal.Body>
      </Modal>

      <Modal
        id='customVideoContentModal'
        size='lg'
        aria-labelledby='contained-modal-title-vcenter'
        centered
        show={videomodalShow}
        onHide={handleVideoModalClose}
        className={
          videomodalShow
            ? 'animate__animated animate__zoomIn'
            : 'animate__animated animate__zoomOut'
        }>
        {/* <Modal.Header closeButton>
          <Modal.Title id='contained-modal-title-center' className=''>
            <span className='d-flex align-items-center'>
              <b
                className='mr-1 pr-1'
                style={{ borderRight: '1px solid #c1c1c1' }}>
                {courseDetail?.course_name}
              </b>
              <i>{modalVideoData?.video_title}</i>
            </span>
          </Modal.Title>
        </Modal.Header> */}
        <Modal.Body className='p-0'>
          <div
            className='d-flex position-absolute float-right flex-column close'
            style={{ zIndex: '999' }}>
            <Icofont
              icon='close-circled'
              // size='1'
              onClick={handleVideoModalClose}
              style={{ color: 'white', cursor: 'pointer', fontSize: '2rem' }}
              className='textShadow'
            />
          </div>
          <div className=''>
            <video
              controlsList='nodownload'
              onContextMenu={(e) => e.preventDefault()}
              id='modalContentVideoPlayer'
              loop
              width='100%'
              height='100%'
              onMouseEnter={(event) => {
                event.target.play();
              }}
              // onMouseLeave={(event) => {
              //   event.target.pause();
              //   event.target.currentTime = 0;
              // }}
              // preload='auto'
              onClick={(event) => {
                if (modalVideoData?.cloudflare_uid) {
                  // console.log(modalVideoData?.cloudflare_uid);

                  handleShow(modalVideoData, event.target.currentTime);
                  event.target.pause();
                }
              }}
              autoPlay='autoplay'
              style={{
                border: 'none',
                // borderRadius: '5px',
                cursor: 'pointer',
                boxShadow: '0 .5rem 1rem rgba(0,0,0,.15)',
              }}
              // id='videoModalContent'
              src={modalVideoData?.cloudflare_preview.replace(
                'getloopstream',
                'getloopstream'
              )}></video>
            {/* <hr /> */}
            <div
              id='VideoContentModal'
              className='p-3'
              style={{ height: '30vh', minHeight: '250px', overflow: 'auto' }}>
              <h5 className='font-weight-bold'>
                {modalVideoData?.video_title}
              </h5>
              <p>{modalVideoData?.video_description}</p>
              <hr />
              {/* <h5 className='font-weight-bold'>Quizzes</h5>
              <p>No Content Available</p>
              <hr />
              <h5 className='font-weight-bold'>Assignments</h5>
              <p>No Content Available</p>
              <hr /> */}
              {/* <h5 className='font-weight-bold'>Content</h5>
              <p>No Content Available</p> */}
            </div>
            {/* <h6>{document.getElementById('videoModalContent')?.duration}</h6> */}
          </div>
        </Modal.Body>
      </Modal>
    </Fragment>
  );
}

Favourites.propTypes = {
  course: PropTypes.object.isRequired,
  getFavourites: PropTypes.func.isRequired,
  setVideoUnFavourite: PropTypes.func.isRequired,
  setContentUnFavourite: PropTypes.func.isRequired,
  clearFavourites: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  course: state.course,
});
export default connect(mapStateToProps, {
  getFavourites,
  clearFavourites,
  setVideoUnFavourite,
  setContentUnFavourite,
})(Favourites);
