import React, { useState, useEffect } from 'react';
import { Modal } from 'react-bootstrap';
import { connect } from 'react-redux';

function SubscriptionChangeModal({
  showSubscriptionChangeModal,
  setSubscriptionChangeModal,
  setSuccessMessageModal,
  data,
  setData,
  auth: { userDetail },
}) {
  const [hello, sethello] = useState('monthly');

  useEffect(() => {
    if (showSubscriptionChangeModal) {
      sethello(userDetail.subscribed === 'Loop Yearly' ? 'yearly' : 'monthly');
    }
  }, [showSubscriptionChangeModal]);

  const successMessage = (e) => {
    e.preventDefault();
    setData({
      ...data,
      successTitle: 'You have successfully changed your subscription plan.',
      successMessage:
        'Yearly Loop Subscription  $99.00/Year  Your next billing date is February 17, 2021.',
    });
    setSubscriptionChangeModal(false);
    setSuccessMessageModal(true);
  };

  const packageChange = (e) => {
    sethello(e.target.value);
  };

  return (
    <Modal
      show={showSubscriptionChangeModal}
      onHide={() => setSubscriptionChangeModal(false)}
      size='xl'
      aria-labelledby='contained-modal-title-vcenter'
      centered
      backdrop='static'
    >
      <Modal.Header closeButton className='border-bottom-0'></Modal.Header>
      <Modal.Body>
        <div className='myaccount-modal-body' id='newPlan'>
          <form>
            <h5 className='mb-4 modal-heading' style={{ lineHeight: '35px' }}>
              <b>Select a new subscription plan</b>
            </h5>
            <div className='new-plan'>
              <div className='plan-callout'>
                <label>Your current plan</label>
                <p
                  className='mb-0'
                  style={{ color: 'black', fontFamily: 'AvenirMedium' }}
                >
                  {userDetail !== null ? userDetail.plan : ''} Subscription{' '}
                  {userDetail !== null ? userDetail.plan_price : ''}
                  <br></br>Your next biling date is{' '}
                  {userDetail !== null ? userDetail.next_bill_date : ''}.
                </p>
              </div>
              <div className='row'>
                <div className='col-lg-6 pr-lg-1 mb-3 mb-lg-0'>
                  <div
                    className={
                      hello === 'monthly' ? 'sub-box active' : 'sub-box'
                    }
                  >
                    <div className='d-flex align-items-center justify-content-between'>
                      <div
                        className='custom-control custom-checkbox custom-control-checkbox-text'
                        style={{
                          paddingRight: '0px',
                          paddingLeft: '0px !important',
                          backgroundColor: '#f2f2f2',
                        }}
                      >
                        <input
                          type='radio'
                          className='custom-control-input custom-control-input-round'
                          id='customCheck'
                          name='example1'
                          value='monthly'
                          onChange={(e) => packageChange(e)}
                        />
                        <label
                          className='custom-control-label custom-control-label-plan'
                          htmlFor='customCheck'
                          style={{
                            fontFamily: 'AvenirHeavy',
                            color: 'black',
                            fontSize: '16px',
                            textTransform: 'none',
                          }}
                        >
                          Monthly <br></br> $ 9.99{' '}
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
                <div className='col-lg-6 pl-lg-1'>
                  <div
                    className={
                      hello === 'yearly' ? 'sub-box active' : 'sub-box'
                    }
                  >
                    <div className='d-flex align-items-center justify-content-between'>
                      <div
                        className='custom-control custom-checkbox custom-control-checkbox-text'
                        style={{
                          paddingRight: '0px',
                          paddingLeft: '0px !important',
                          backgroundColor: '#f2f2f2',
                        }}
                      >
                        <input
                          type='radio'
                          className='custom-control-input custom-control-input-round'
                          id='customCheck1'
                          name='example1'
                          value='yearly'
                          onChange={(e) => packageChange(e)}
                        />
                        <label
                          className='custom-control-label custom-control-label-plan'
                          htmlFor='customCheck1'
                          style={{
                            fontFamily: 'AvenirHeavy',
                            color: 'black',
                            fontSize: '16px',
                            textTransform: 'none',
                          }}
                        >
                          Yearly <br></br> $ 99.00{' '}
                        </label>
                      </div>
                    </div>
                    <div className='sub-notice'>
                      <p className='mb-0 py-1'>Two months free !</p>
                    </div>
                  </div>
                </div>
              </div>
              <button
                type='submit'
                className='btn btn-yellow w-100 mt-4'
                onClick={(e) => successMessage(e)}
              >
                SAVE
              </button>
            </div>
          </form>
        </div>
      </Modal.Body>
    </Modal>
  );
}

SubscriptionChangeModal.propTypes = {};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps, null)(SubscriptionChangeModal);
