import {
  LOGIN_SUCCESS,
  USER_LOADED,
  AUTH_ERROR,
  LOG_OUT,
  LOGIN_FAIL,
  SET_ACCOUNT_DATA,
  UPDATE_PROFILE,
  SUBSCRIBE_USER,
  GET_BILLING_HISTORY,
  GET_PAYMENT_METHODS,
  DELETE_PAYMENT_METHOD,
  CLASSES_STREAMING_LIST,
} from '../actions/types';

const initialState = {
  token: localStorage.getItem('token'),
  user_id: null,
  isAuthenticated: false,
  userDetail: null,
  billing_history: null,
  payment_methods: null,
  loading: true,
  subscribed: false,
  classesStreamingList: null,
};

export default function (state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case LOGIN_SUCCESS:
      localStorage.setItem('token', payload.access_token);
      localStorage.setItem('id', payload.id);
      return {
        ...state,
        token: payload.access_token,
        isAuthenticated: true,
        loading: false,
        user_id: payload.id,
        subscribed: payload.subscribed,
      };
    //   case REGISTER_FAIL:
    case AUTH_ERROR:
    case LOGIN_FAIL:
    case LOG_OUT:
      localStorage.removeItem('token');
      localStorage.removeItem('id');
      return {
        ...state,
        token: null,
        isAuthenticated: false,
        loading: true,
        user_id: null,
        userDetail: null,
      };
    case USER_LOADED:
      return {
        ...state,
        userDetail: payload,
        isAuthenticated: true,
        loading: false,
      };
    case UPDATE_PROFILE:
      return {
        ...state,
        userDetail: {
          ...state.userDetail,
          ...payload,
        },
      };
    case SUBSCRIBE_USER:
      return {
        ...state,
        userDetail: {
          ...state.userDetail,
          subscribed: payload,
        },
      };
    case GET_BILLING_HISTORY:
      return {
        ...state,
        billing_history: payload,
      };

    case GET_PAYMENT_METHODS:
      return {
        ...state,
        payment_methods: payload,
      };
    case DELETE_PAYMENT_METHOD:
      return {
        ...state,
        payment_methods: state.payment_methods.filter(
          (data) => data.id !== payload
        ),
      };
    case CLASSES_STREAMING_LIST:
      return {
        ...state,
        classesStreamingList: payload,
      };
    default:
      return state;
  }
}
