import React, { useState, Fragment } from 'react';
import PropTypes from 'prop-types';
import PasswordDetail from './PasswordDetail';
import EmailDetail from './EmailDetail';
import EmailCheck from './EmailCheck';
import UserNameDetail from './UserNameDetail';
import ResendModal from '../modals/ResendModal';
import ResumePaymentModal from '../modals/ResumePaymentModal';
import CardDetail from './CardDetail';
import { Redirect } from 'react-router-dom';
import { connect } from 'react-redux';

function CreateAccount({ auth }) {
  const [step, setsteps] = useState(
    localStorage.getItem('steps') ? localStorage.getItem('steps') : '1'
  );

  const [showResendModal, setResendModal] = useState(false);

  const [showModal, setModal] = useState(false);

  const [loader, setLoader] = useState(false);

  const [data, setData] = useState(
    localStorage.getItem('data')
      ? JSON.parse(localStorage.getItem('data'))
      : {
          first_name: '',
          last_name: '',
          email: '',
          password: '',
          role: '',
        }
  );

  const changeStep = (e, stepNumber) => {
    e.preventDefault();

    setsteps(stepNumber);
  };

  // Redirect If Looged in

  //   if (localStorage.token) {
  //     return <Redirect to='/my-account' />;
  //   }

  return (
    <Fragment>
      <div className='container'>
        <div className='row'>
          {/* <div className='content'> */}
          <div className='col-md-4'></div>
          <div className='col-lg-4 col-md-12'>
            {step === '1' ? (
              <UserNameDetail
                changeStep={changeStep}
                data={data}
                setData={setData}
                loader={loader}
                setLoader={setLoader}
              />
            ) : step === '2' ? (
              <EmailDetail
                changeStep={changeStep}
                data={data}
                setData={setData}
                loader={loader}
                setLoader={setLoader}
              />
            ) : step === '2.1' ? (
              <EmailCheck
                setResendModal={setResendModal}
                changeStep={changeStep}
                data={data}
                loader={loader}
                setLoader={setLoader}
              />
            ) : step === 3 ? (
              <PasswordDetail
                changeStep={changeStep}
                data={data}
                setData={setData}
                loader={loader}
                setLoader={setLoader}
              />
            ) : step === 4 && !auth.subscribed ? (
              <CardDetail
                changeStep={changeStep}
                data={data}
                setModal={setModal}
                setData={setData}
                loader={loader}
                setLoader={setLoader}
              />
            ) : auth.subscribed ? (
              <Redirect to='/my-account' />
            ) : null}
          </div>
          <div className='col-md-4'></div>

          {/* </div> */}
        </div>
      </div>
      <ResendModal
        data={data}
        showResendModal={showResendModal}
        setResendModal={setResendModal}
      />
      <ResumePaymentModal showModal={showModal} setModal={setModal} />
    </Fragment>
  );
}

CreateAccount.propTypes = {
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps, null)(CreateAccount);
