import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import '../../assets/css/emailCheck.css';
import { connect } from 'react-redux';
import { verifyCode, sendEmailVerificationCode } from '../../actions/auth';
import Icofont from 'react-icofont';

function EmailCheck({
  setResendModal,
  changeStep,
  data,
  loader,
  setLoader,
  verifyCode,
  sendEmailVerificationCode,
}) {
  const check123 = (e) => {
    if (e.target.value.length > e.target.maxLength) {
      e.target.value = e.target.value.slice(0, e.target.maxLength);
    }
  };

  const codeInput = useRef();

  const onClickContinue = async (e) => {
    e.preventDefault();

    setLoader(true);

    let sendData = new FormData();

    sendData.append('email', data.email);
    sendData.append('otp', codeInput.current.value);

    const verify = await verifyCode(sendData, 'Signup');

    if (verify === true) {
      setLoader(false);
      localStorage.removeItem('data');
      localStorage.removeItem('steps');
      changeStep(e, 3);
    } else {
      setLoader(false);
    }
  };

  const resendCode = async (e) => {
    e.preventDefault();

    // setLoader(true);

    const send = await sendEmailVerificationCode(data.email);

    if (send === true) {
      //   setLoader(false);
      // changeStep(e, 3);
      setResendModal(true);
    }
  };

  return (
    <div className='row animated zoomIn'>
      <div className='col-md-12'>
        <svg
          xmlns='http://www.w3.org/2000/svg'
          width='24'
          height='24'
          viewBox='0 0 26 26'
          style={{ cursor: 'pointer' }}
          onClick={(e) => {
            localStorage.setItem('data', JSON.stringify(data));
            localStorage.setItem('steps', '2');
            changeStep(e, '2');
          }}>
          <g fill='none' fillRule='evenodd'>
            <g>
              <g transform='translate(-521 -139) translate(522 140)'>
                <circle cx='12' cy='12' r='12' stroke='#FFF' strokeWidth='.7' />
                <g fill='#FFF' fillRule='nonzero'>
                  <path
                    d='M6.308 13.874c.08.084.183.126.287.126.103 0 .207-.042.286-.126.159-.168.159-.44 0-.608L.978 7 6.881.734c.159-.168.159-.44 0-.608-.158-.168-.414-.168-.573 0l-6.19 6.57c-.158.168-.158.44 0 .608l6.19 6.57z'
                    transform='translate(8 5)'
                  />
                </g>
              </g>
            </g>
          </g>
        </svg>
      </div>

      <div className='col-md-12'>
        <div className='' style={{ marginTop: '24px' }}>
          <h4 className='main-heading'>Check your email inbox</h4>
        </div>
      </div>

      <div className='col-md-12'>
        <div className=' position-relative' style={{ marginTop: '22px' }}>
          <p className='main-heading-paragraph'>
            We need to verify your email address. We’ve sent you an email to{' '}
            <span style={{ fontWeight: '900', fontFamily: 'AvenirHeavy' }}>
              {data.email}
            </span>{' '}
            containing a 6-digit code which expires in 15 minutes. Please enter
            it below..{' '}
          </p>
        </div>
      </div>

      <div className='col-md-12'>
        <div className='' id='accEmail' style={{ marginTop: '15px' }}>
          <input
            onInput={(e) => check123(e)}
            type='number'
            className='form-control'
            maxLength='6'
            placeholder='000000'
            ref={codeInput}></input>
        </div>
      </div>

      <div className='col-md-12'>
        <div
          className='next-button'
          style={{ marginTop: '24px' }}
          onClick={(e) => onClickContinue(e)}>
          <h4>
            CONTINUE
            {loader ? (
              <span style={{ marginLeft: '5px' }}>
                <Icofont icon='spinner' size='1' spin='true' />
              </span>
            ) : null}
          </h4>
        </div>
      </div>

      <div className='col-md-12'>
        <div className=' position-relative' style={{ marginTop: '25px' }}>
          <p className='main-heading-paragraph'>
            Didnt recieve the email? Try checking your junk for spam folders.{' '}
          </p>
          <p
            className='main-heading-paragraph'
            style={{
              fontWeight: '900',
              fontFamily: 'AvenirHeavy',
              marginTop: '19px',
              cursor: 'pointer',
            }}
            onClick={(e) => resendCode(e)}>
            Resend{' '}
            {/* {loader ? (
              <span style={{ marginLeft: '5px' }}>
                <Icofont icon='spinner' size='1' spin='true' />
              </span>
            ) : null} */}
          </p>
        </div>
      </div>
    </div>
  );
}

EmailCheck.propTypes = {
  verifyCode: PropTypes.func.isRequired,
  sendEmailVerificationCode: PropTypes.func.isRequired,
};

export default connect(null, { verifyCode, sendEmailVerificationCode })(
  EmailCheck
);
