import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Modal } from 'react-bootstrap';
import Card from '../../assets/images/card.jpg';
import { connect } from 'react-redux';
import { billingHistory } from '../../actions/auth';
import { Fragment } from 'react';

function BillingHistoryModal({
  showBillingHistoryModal,
  setBillingHistoryModal,
  data,
  setData,
  billingHistory,
  auth: { billing_history, userDetail },
}) {
  useEffect(() => {
    if (showBillingHistoryModal) {
      billingHistory(userDetail.email);
    }
  }, [showBillingHistoryModal]);

  const [state, setstate] = useState({
    cardNumber: '1234567891234567',
  });

  useEffect(() => {
    hideAccountNumber(state.cardNumber);
  }, []);

  const hideAccountNumber = async (number) => {
    var no = number;
    var len = number.length;
    var lstFour = no.substr(len - 4);
    var strs = '';

    var temp = 1;
    for (let k = 0; k < len - 4; k++) {
      strs += '*';
      if (temp == 4) {
        strs += ' ';
        temp = 0;
      }
      temp++;
    }
    await setstate({ cardNumber: '' });

    await setstate({ cardNumber: strs + lstFour });
  };

  return (
    <Modal
      show={showBillingHistoryModal}
      onHide={() => setBillingHistoryModal(false)}
      size='xl'
      aria-labelledby='contained-modal-title-vcenter'
      centered
      backdrop='static'
    >
      <Modal.Header closeButton className='border-bottom-0'></Modal.Header>
      <Modal.Body>
        <div className='row'>
          <div className='col-lg-8'>
            <h5 className='mb-4 modal-heading' style={{ lineHeight: '35px' }}>
              <b>Billing History</b>
            </h5>
            <p
              className='mb-0'
              style={{ color: 'black', fontFamily: 'AvenirMedium' }}
            >
              Membership fees are billed at the beginning of each period and may
              take a few days after the biling date to appear on your account.
              We only show up to 1 year of biling history.
            </p>
          </div>
        </div>
        <div className='row'>
          <div className='col-lg-6'>
            <div className='plan-callout'>
              <label>Your Plan</label>
              <p
                className='mb-0'
                style={{ color: 'black', fontFamily: 'AvenirMedium' }}
              >
                {userDetail !== null ? userDetail.plan : ''} Subscription{' '}
                {userDetail !== null ? userDetail.plan_price : ''}
                <br></br>Your next biling date is{' '}
                {userDetail !== null ? userDetail.next_bill_date : ''}.
              </p>
            </div>
          </div>
        </div>
        <div className='row'>
          <div className='col-lg-12'>
            <div
              className='table-responsive'
              style={{ maxHeight: '500px', overflowY: 'scroll' }}
            >
              <table className='table'>
                <thead>
                  <tr>
                    <th>Date</th>
                    <th>Description</th>
                    <th>Payment Method</th>
                    <th>Total</th>
                  </tr>
                </thead>
                <tbody>
                  {billing_history !== null ? (
                    <Fragment>
                      {billing_history.invoices.map((item, i) => (
                        <tr key={i}>
                          <td>{item.created}</td>
                          <td>{item.plan_name}</td>
                          <td>
                            <div
                              style={{
                                display: 'inline-flex',
                                paddingLeft: '0px',
                              }}
                            >
                              <img
                                className='align-baseline'
                                src={Card}
                                alt='visa'
                                width='40'
                                height='30'
                              />
                              <p style={{ marginTop: '10px' }}>
                                {'**** **** **** ' + userDetail.card_number}
                              </p>
                            </div>
                          </td>
                          <td>
                            {item.currency}
                            {item.plan_charge}
                          </td>
                        </tr>
                      ))}
                    </Fragment>
                  ) : null}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
}

BillingHistoryModal.propTypes = {
  auth: PropTypes.object.isRequired,
  billingHistory: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps, { billingHistory })(
  BillingHistoryModal
);
