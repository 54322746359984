import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Icofont from 'react-icofont';
import { loginUser } from '../../actions/auth';
import { connect } from 'react-redux';
import { withRouter, Redirect, Link } from 'react-router-dom';

function Login({ loginUser, history, window }) {
  const [loader, setLoader] = useState(false);

  const [loginData, setLoginData] = useState({
    email: '',
    password: '',
  });

  const login = async (e) => {
    e.preventDefault();

    // console.log(loginData);

    setLoader(true);

    const authorize = await loginUser(loginData);

    if (authorize === true) {
      setLoader(false);
      history.push('/home');
    } else {
      setLoader(false);
    }
  };

  // Redirect If Looged in

  if (localStorage.token) {
    return <Redirect to='/home' />;
  }

  return (
    <div className='container'>
      <div className='row'>
        {/* <div className='content'> */}
        <div className='col-md-4'></div>
        <div className='col-lg-4 col-md-12'>
          <div className='row animated zoomIn'>
            {/* <div className='col-md-12'>
        <div className='text-white' style={{ marginTop: '66px' }}>
          <h4 className='main-steps'>STEP 1 OF 4</h4>
        </div>
      </div> */}
            <div className='col-md-12'>
              <div className='text-white' style={{ marginTop: '12px' }}>
                <h4 className='main-heading'>Login</h4>
              </div>
            </div>
            {/* <form> */}
            {/* <div class="form-group">
    <input type="email" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Email Address" />
  </div>
  <div class="form-group">
    <label for="exampleInputPassword1">Password</label>
    <input type="password" class="form-control" id="exampleInputPassword1" placeholder="Password" />
  </div>
  <button type="submit" class="btn btn-primary">Submit</button>
</form> */}
            <form
              onSubmit={(e) => {
                loginData.email === '' && loginData.password === ''
                  ? e.preventDefault()
                  : login(e);
              }}
              style={{ width: '100%' }}>
              <div className='col-md-12'>
                <div className='text-white' style={{ marginTop: '28px' }}>
                  <div
                    className='position-relative w-100'
                    style={{ height: '39px' }}>
                    <input
                      type='email'
                      className='email-input'
                      style={{
                        width: '100%',
                        height: '39px',
                        // boxShadow: '0 2px 4px 0 #353535',
                        // border: 'solid 1px #353535',
                        // backgroundColor: '#353535',
                        padding: '9px 15px 8px 15px',
                        borderRadius: '3px',
                        // color: 'white',
                        fontSize: '15px',
                        fontWeight: '300',
                        fontFamily: 'AvenirLight',
                      }}
                      value={loginData.email}
                      placeholder='Email Address'
                      name='email'
                      onChange={(e) =>
                        setLoginData({
                          ...loginData,
                          [e.target.name]: e.target.value,
                        })
                      }
                      required='required'
                    />
                  </div>
                  {/* <InputField
              type='email'
              placeholder='Email Address'
              withIcon={false}
            /> */}
                </div>
              </div>
              <div className='col-md-12'>
                <div className='text-white' style={{ marginTop: '28px' }}>
                  <div
                    className='position-relative w-100'
                    style={{ height: '39px' }}>
                    <input
                      type='password'
                      className='email-input'
                      style={{
                        width: '100%',
                        height: '39px',
                        // boxShadow: '0 2px 4px 0 #353535',
                        // border: 'solid 1px #353535',
                        // backgroundColor: '#353535',
                        padding: '9px 15px 8px 15px',
                        borderRadius: '3px',
                        // color: 'white',
                        fontSize: '15px',
                        fontWeight: '300',
                        fontFamily: 'AvenirLight',
                      }}
                      value={loginData.password}
                      placeholder='Password'
                      name='password'
                      onChange={(e) =>
                        setLoginData({
                          ...loginData,
                          [e.target.name]: e.target.value,
                        })
                      }
                      required='required'
                    />
                  </div>
                  {/* <InputField
              type='email'
              placeholder='Email Address'
              withIcon={false}
            /> */}
                </div>
              </div>
              <div className='col-md-12'>
                <div className='text-white' style={{ marginTop: '28px' }}>
                  <div className='position-relative w-100'>
                    <Link className='forgot-password' to='/forgot-password'>
                      Forgot Password
                    </Link>
                  </div>
                </div>
              </div>
              <div className='col-md-12'>
                <button
                  //   onClick={(e) => login(e)}
                  style={
                    loginData.email === '' || loginData.password === ''
                      ? {
                          pointerEvents: 'none',
                          backgroundColor: '#1d3246',
                          border: 'none',
                          marginTop: '28px',
                        }
                      : { border: 'none', marginTop: '28px' }
                  }
                  type='submit'
                  className='next-button'>
                  Login{' '}
                  {loader ? (
                    <span style={{ marginLeft: '5px' }}>
                      <Icofont icon='spinner' size='1' spin='true' />
                    </span>
                  ) : null}
                </button>
                {/* <div
                  className='next-button'
                  onClick={(e) => login(e)}
                  style={
                    loginData.email === '' || loginData.password === ''
                      ? { pointerEvents: 'none', backgroundColor: '#f1b300b0' }
                      : null
                  }
                  value='Submit'
                >
                  <h4>
                    Login{' '}
                    {loader ? (
                      <span style={{ marginLeft: '5px' }}>
                        <Icofont icon='spinner' size='1' spin='true' />
                      </span>
                    ) : null}
                  </h4>
                </div> */}
              </div>
            </form>
          </div>
        </div>
        <div className='col-md-4'></div>

        {/* </div> */}
      </div>
    </div>
  );
}

Login.propTypes = {
  loginUser: PropTypes.func.isRequired,
};

export default connect(null, { loginUser })(withRouter(Login));
