import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Modal } from 'react-bootstrap';
import { connect } from 'react-redux';
import moment from 'moment';

function BillingChangeModal({
  showBillingChangeModal,
  setBillingChangeModal,
  data,
  setData,
  auth: { userDetail },
}) {
  const [billingDay, setBillingDay] = useState(1);

  const options = [];
  obj = {};

  for (var i = 1; i <= 30; i++) {
    var obj = {};

    obj['value'] = i;
    obj['label'] = i;
    options.push(obj);
  }

  const changeBillingDay = (e) => {
    e.preventDefault();

  };

  return (
    <Modal
      show={showBillingChangeModal}
      onHide={() => setBillingChangeModal(false)}
      size='xl'
      aria-labelledby='contained-modal-title-vcenter'
      centered
      backdrop='static'
    >
      <Modal.Header closeButton className='border-bottom-0'></Modal.Header>
      <Modal.Body>
        <div className='myaccount-modal-body'>
          <form>
            <h5 className='mb-4 modal-heading' style={{ lineHeight: '35px' }}>
              <b>Choose a new billing day</b>
            </h5>
            <div className='form-row'>
              <div className='form-group col-md-12'>
                <p className='modal-label-text'>
                  Right now your subscription is billed on the{' '}
                  <span
                    style={{
                      fontWeight: '900',
                    }}
                  >
                    {userDetail !== null
                      ? moment(userDetail.next_bill_date).format('Do')
                      : null}{' '}
                    of each month.
                  </span>{' '}
                  Please choose a day below that is more convenient for you.{' '}
                </p>

                <div className='form-group d-flex flex-column position-relative'>
                  <label style={{ textTransform: 'none' }}>
                    Your new billing day
                  </label>
                  <div className='bill-day-select'>
                    <select
                      id='new_bill_day'
                      className='custom select'
                      options={options}
                      onChange={(e) => setBillingDay(e.target.value)}
                    >
                      {options.map(({ value, label }, index) => (
                        <option key={index} value={value}>
                          {label}
                        </option>
                      ))}
                    </select>
                  </div>
                  <div className='each-month'>
                    <label className='text-uppercase'>of each month</label>
                  </div>
                </div>
              </div>
            </div>
            <button
              type='submit'
              className='btn btn-yellow w-100 mt-4'
              onClick={(e) => changeBillingDay(e)}
            >
              SAVE
            </button>
          </form>
        </div>
      </Modal.Body>
    </Modal>
  );
}

BillingChangeModal.propTypes = {
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps, null)(BillingChangeModal);
