import React, { useEffect, useState, Fragment } from 'react';
import PropTypes from 'prop-types';
import Card from '../../assets/images/card.jpg';
import NameChangeModal from '../modals/NameChangeModal';
import EmailChangeModal from '../modals/EmailChangeModal';
import EmailCheckModal from '../modals/EmailCheckModal';
import SuccessMessageModal from '../modals/SuccessMessageModal';
import PasswordChangeModal from '../modals/PasswordChangeModal';
import BillingChangeModal from '../modals/BillingChangeModal';
import SubscriptionChangeModal from '../modals/SubscriptionChangeModal';
import BillingHistoryModal from '../modals/BillingHistoryModal';
import ManagePaymentModal from '../modals/ManagePaymentModal';
import SubscriptionModal from '../modals/SubscriptionModal';
import AddNewPaymentModal from '../modals/AddNewPaymentMethod';
import { connect } from 'react-redux';
import { cancelSubscription } from '../../actions/auth';
import Moment from 'react-moment';

function MyAccount({ auth: { userDetail }, cancelSubscription }) {
  const ColoredLine = ({ color }) => (
    <hr
      style={{
        color: color,
        backgroundColor: color,
        height: 1,
      }}
    />
  );

  const [state, setstate] = useState({
    cardNumber: '1234567891234567',
  });

  const [showNameChangeModal, setNameChangeModal] = useState(false);

  const [showEmailChangeModal, setEmailChangeModal] = useState(false);

  const [showEmailCheckModal, setEmailCheckModal] = useState(false);

  const [showPasswordChangeModal, setPasswordChangeModal] = useState(false);

  const [showSuccessMessageModal, setSuccessMessageModal] = useState(false);

  const [showBillingChangeModal, setBillingChangeModal] = useState(false);

  const [showSubscriptionChangeModal, setSubscriptionChangeModal] =
    useState(false);

  const [showBillingHistoryModal, setBillingHistoryModal] = useState(false);

  const [showManagePaymentModal, setManagePaymentModal] = useState(false);

  const [showSubscriptionModal, setSubscriptionModal] = useState(false);

  const [showAddNewPaymentModal, setAddNewPaymentModal] = useState(false);

  const [data, setData] = useState({
    email: userDetail === null ? '' : userDetail.email,
    currentPassword: '',
    newPassword: '',
    first_name: userDetail === null ? '' : userDetail.first_name,
    last_name: userDetail === null ? '' : userDetail.last_name,
    cardName: '',
    successTitle: '',
    successMessage: '',
  });

  const [cardData, setCardData] = useState({
    cardName: '',
    cardExpiry: '',
    cardPostal: '',
    default: false,
  });

  useEffect(() => {
    // console.log('hello hello~~~~~');

    hideAccountNumber(
      userDetail !== null
        ? '123456789123' + userDetail.card_number
        : '1234567891234567'
    );
  }, [userDetail !== null ? userDetail.card_number : null]);

  const hideAccountNumber = async (number) => {
    // console.log(number, 'uyahaaaaan');
    var no = number;
    var len = number.length;
    var lstFour = no.substr(len - 4);
    var strs = '';
    // console.log(lstFour);

    var temp = 1;
    for (let k = 0; k < len - 4; k++) {
      strs += '*';
      if (temp == 4) {
        strs += ' ';
        temp = 0;
      }
      temp++;
    }
    // console.log(strs + lstFour);
    setstate({ cardNumber: '' });

    setstate({ cardNumber: strs + lstFour });
  };

  const cancel = async (e) => {
    e.preventDefault();

    cancelSubscription(userDetail.email);
  };

  return (
    <Fragment>
      <div className='main-content'>
        <div className='content-heading d-flex align-items-center'>
          <h1>My Account</h1>
        </div>
        <div className='content-info'>
          <div className='container-fluid'>
            <div className='row'>
              <div className='col-lg-12'>
                <h6>Account Information</h6>
              </div>
            </div>
            <div className='row'>
              <div className='col-xl-4 col-lg-5 col-sm-6 '>
                <p className='account-label'>
                  {' '}
                  {userDetail !== null
                    ? userDetail.first_name + ' ' + userDetail.last_name
                    : null}{' '}
                </p>
              </div>
              <div className='col-xl-4 col-lg-5 col-sm-6 d-flex align-items-center'>
                <a
                  href='#'
                  onClick={(e) => {
                    e.preventDefault();
                    setNameChangeModal(true);
                  }}>
                  Change account name
                </a>
              </div>
            </div>
            <div className='row'>
              <div className='col-xl-4 col-lg-5 col-sm-6'>
                <p>{userDetail !== null ? userDetail.email : null}</p>
              </div>
              <div className='col-xl-4 col-lg-5 col-sm-6 d-flex align-items-center'>
                <a
                  href='#'
                  onClick={(e) => {
                    e.preventDefault();
                    setEmailChangeModal(true);
                  }}>
                  Change account email
                </a>
              </div>
            </div>
            <div className='row'>
              <div className='col-xl-4 col-lg-5 col-sm-6'>
                <p>
                  Password: <span>********</span>
                </p>
              </div>
              <div className='col-xl-4 col-lg-5 col-sm-6 d-flex align-items-center'>
                <a
                  href='#'
                  onClick={(e) => {
                    e.preventDefault();
                    setPasswordChangeModal(true);
                  }}>
                  Change password
                </a>
              </div>
            </div>

            <ColoredLine color='light-gray' />

            {userDetail !== null ? (
              <Fragment>
                {!userDetail.subscribed ? (
                  <div className='row'>
                    <div className='col-md-12'>
                      <button
                        className='next-button'
                        style={{ border: 'none' }}
                        onClick={(e) => {
                          e.preventDefault();
                          setSubscriptionModal(true);
                        }}>
                        Subscribe
                      </button>
                    </div>
                  </div>
                ) : (
                  <Fragment>
                    <div className='row'>
                      <div className='col-lg-12'>
                        <h6>Billing Details</h6>
                      </div>
                    </div>
                    <div className='row'>
                      <div className='col-xl-4 col-lg-5 col-sm-6'>
                        <p>
                          Your next billing date is {userDetail.next_bill_date}
                        </p>
                      </div>
                      <div className='col-xl-4 col-lg-5 col-sm-6 d-flex align-items-center'>
                        <a
                          href='#'
                          onClick={(e) => {
                            e.preventDefault();
                            setBillingChangeModal(true);
                          }}>
                          Change billing day
                        </a>
                      </div>
                    </div>

                    <div className='row'>
                      <div
                        className='col-xl-4 col-lg-5 col-sm-6'
                        style={{ display: 'inline-flex' }}>
                        <img
                          className='align-baseline'
                          src={Card}
                          alt='visa'
                          width='40'
                          height='30'
                          style={{
                            marginLeft: 30,
                            marginTop: 6,
                          }}
                        />
                        <p style={{ paddingLeft: '10px' }}>
                          {'**** **** **** ' + userDetail.card_number}
                        </p>
                      </div>
                      <div className='col-xl-4 col-lg-5 col-sm-6 d-flex align-items-center'>
                        <a
                          href='#'
                          onClick={(e) => {
                            e.preventDefault();
                            setManagePaymentModal(true);
                          }}>
                          Manage payment info
                        </a>
                      </div>
                    </div>
                    <div className='row'>
                      <div className='col-xl-4 col-lg-5 offset-xl-4 offset-lg-5 offset-sm-6 d-flex align-items-center my-75'>
                        <a
                          href='#'
                          onClick={(e) => {
                            e.preventDefault();
                            setBillingHistoryModal(true);
                          }}>
                          View billing history
                        </a>
                      </div>
                    </div>

                    <ColoredLine color='light-gray' />

                    <div className='row'>
                      <div className='col-lg-12'>
                        <h6>Subscription Plan</h6>
                      </div>
                    </div>
                    <div className='row'>
                      <div className='col-xl-4 col-lg-5 col-sm-6'>
                        <p>
                          <b>{userDetail.plan} Subscription</b>
                        </p>
                      </div>
                      <div className='col-xl-4 col-lg-5 col-sm-6 d-flex align-items-center'>
                        <a
                          href='#'
                          data-toggle='modal'
                          data-target='#newPlan'
                          onClick={(e) => {
                            e.preventDefault();
                            setSubscriptionChangeModal(true);
                          }}>
                          Switch plan
                        </a>
                      </div>
                    </div>
                    <div className='row'>
                      <div className='col-xl-4 col-lg-5 offset-xl-4 offset-lg-5 offset-sm-6 d-flex align-items-center my-75'>
                        <button
                          onClick={(e) => cancel(e)}
                          className='btn btn-outline-dark btn-sm text-uppercase'>
                          Cancel Subscription
                        </button>
                      </div>
                    </div>
                  </Fragment>
                )}
              </Fragment>
            ) : null}
          </div>
        </div>
      </div>
      <NameChangeModal
        showNameChangeModal={showNameChangeModal}
        setNameChangeModal={setNameChangeModal}
        setSuccessMessageModal={setSuccessMessageModal}
        data={data}
        setData={setData}
      />
      <EmailChangeModal
        showEmailChangeModal={showEmailChangeModal}
        setEmailChangeModal={setEmailChangeModal}
        setEmailCheckModal={setEmailCheckModal}
        setSuccessMessageModal={setSuccessMessageModal}
        data={data}
        setData={setData}
      />

      <EmailCheckModal
        showEmailCheckModal={showEmailCheckModal}
        setEmailCheckModal={setEmailCheckModal}
        setSuccessMessageModal={setSuccessMessageModal}
        data={data}
        setData={setData}
      />

      <SuccessMessageModal
        showSuccessMessageModal={showSuccessMessageModal}
        setSuccessMessageModal={setSuccessMessageModal}
        data={data}
        setData={setData}
      />

      <PasswordChangeModal
        showPasswordChangeModal={showPasswordChangeModal}
        setPasswordChangeModal={setPasswordChangeModal}
        setSuccessMessageModal={setSuccessMessageModal}
        data={data}
        setData={setData}
      />

      <BillingChangeModal
        showBillingChangeModal={showBillingChangeModal}
        setBillingChangeModal={setBillingChangeModal}
        data={data}
        setData={setData}
      />

      <SubscriptionChangeModal
        showSubscriptionChangeModal={showSubscriptionChangeModal}
        setSubscriptionChangeModal={setSubscriptionChangeModal}
        setSuccessMessageModal={setSuccessMessageModal}
        data={data}
        setData={setData}
      />

      <BillingHistoryModal
        showBillingHistoryModal={showBillingHistoryModal}
        setBillingHistoryModal={setBillingHistoryModal}
        data={data}
        setData={setData}
      />

      <ManagePaymentModal
        showManagePaymentModal={showManagePaymentModal}
        setManagePaymentModal={setManagePaymentModal}
        setAddNewPaymentModal={setAddNewPaymentModal}
        data={data}
        setData={setData}
        cardData={cardData}
        setCardData={setCardData}
      />

      <SubscriptionModal
        showSubscriptionModal={showSubscriptionModal}
        setSubscriptionModal={setSubscriptionModal}
        setSuccessMessageModal={setSuccessMessageModal}
        data={data}
        setData={setData}
        cardData={cardData}
        setCardData={setCardData}
      />

      <AddNewPaymentModal
        showAddNewPaymentModal={showAddNewPaymentModal}
        setAddNewPaymentModal={setAddNewPaymentModal}
        setManagePaymentModal={setManagePaymentModal}
        data={data}
        setData={setData}
        cardData={cardData}
        setCardData={setCardData}
      />

      {/* <footer id='footer'></footer> */}
    </Fragment>
  );
}

MyAccount.propTypes = {
  auth: PropTypes.object.isRequired,
  cancelSubscription: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps, { cancelSubscription })(MyAccount);
