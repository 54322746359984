import React, { useState } from 'react';
import PropTypes from 'prop-types';
// import InputField from '../Elements/InputField';
import Icofont from 'react-icofont';
import { connect } from 'react-redux';
import { forgetPassword } from '../../actions/auth';

function ForgotPassword({ forgetPassword, history }) {
  const [email, setEmail] = useState('');
  const [loader, setLoader] = useState(false);

  const onClickNext = async (e) => {
    e.preventDefault();

    const check = await forgetPassword(email, history);

    if (check) {
      setLoader(false);
    } else {
      setLoader(false);
    }
  };

  return (
    <div className='container'>
      <div className='row'>
        <div className='col-md-4'></div>
        <div className='col-lg-4 col-md-12'>
          <div className='row animated zoomIn'>
            <div className='col-md-12'>
              <div className='text-white' style={{ marginTop: '12px' }}>
                <h4 className='main-heading'>Forgot Password</h4>
              </div>
            </div>
            <form
              onSubmit={(e) => {
                /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(
                  email
                ) === false || loader
                  ? e.preventDefault()
                  : onClickNext(e);
              }}
              style={{ width: '100%' }}>
              <div className='col-md-12'>
                <div className='text-white' style={{ marginTop: '28px' }}>
                  <div
                    className='position-relative w-100'
                    style={{ height: '39px' }}>
                    <input
                      type='email'
                      className='email-input'
                      style={{
                        width: '100%',
                        height: '39px',
                        // boxShadow: '0 2px 4px 0 #353535',
                        border: 'solid 1px #c1c1c1',
                        backgroundColor: '#ffffff',
                        padding: '9px 15px 8px 15px',
                        borderRadius: '3px',
                        // color: 'white',
                        fontSize: '15px',
                        fontWeight: '300',
                        fontFamily: 'AvenirLight',
                      }}
                      value={email}
                      placeholder='Email Address'
                      onChange={(e) => setEmail(e.target.value)}
                      required='required'
                    />
                  </div>
                </div>
              </div>

              <div className='col-md-12'>
                <button
                  style={
                    /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(
                      email
                    ) === false || loader
                      ? {
                          pointerEvents: 'none',
                          backgroundColor: '#1d3246',
                          border: 'none',
                        }
                      : { border: 'none' }
                  }
                  type='submit'
                  className='next-button'>
                  Submit
                  {loader ? (
                    <span style={{ marginLeft: '5px' }}>
                      <Icofont icon='spinner' size='1' spin='true' />
                    </span>
                  ) : null}
                </button>
              </div>
            </form>
          </div>
        </div>
        <div className='col-md-4'></div>
      </div>
    </div>
  );
}

ForgotPassword.propTypes = {
  forgetPassword: PropTypes.func.isRequired,
};

export default connect(null, { forgetPassword })(ForgotPassword);
